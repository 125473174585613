import React from 'react'
import { Button, Container, Typography } from '@mui/material'

export const VerifyEmail: React.FC = () => {
  return (
    <Container sx={{ p: 3 }}>
      <Typography>
        Please verify this email, by following the link in your inbox.
        <br />
        <br />
        Since email is verified, click this button:
        <br />
        <br />
        <Button
          variant="contained"
          onClick={() => {
            window.location.reload()
          }}
        >
          Email verified
        </Button>
      </Typography>
    </Container>
  )
}

export default VerifyEmail
