import React, { Fragment, useContext } from 'react'

import { Set } from '../../../types'
import { DataContext, WorkoutsContext } from '../../../store'
import useTranslations from '../../../hooks/useTranslations'
import EditItem from './EditItem'
import SupersetItem from './SupersetItem'

import '../../../styles/module.scss'

interface Props {
  setLists: Array<Set[]>
  ids: string[]
  exerciseIds: string[]
}

const SupersetList: React.FC<Props> = ({ setLists, ids, exerciseIds }) => {
  const { findExerciseGroup } = useContext(DataContext)
  const { currentWorkout, isEditMode } = useContext(WorkoutsContext)
  const { t } = useTranslations()

  const exerciseGroup = findExerciseGroup(exerciseIds[0])
  const currentExercise = currentWorkout?.exercises.find(
    (ex) => ex.id === ids[0]
  )
  const exerciseStatus = currentExercise?.status ?? 0

  const titles: string[] = setLists.map((_, idx) => {
    const exerciseItem = exerciseGroup?.exercises.find(
      (ex) => ex.id === exerciseIds[idx]
    )

    return t(`exercise-title-${exerciseItem?.id}`) || exerciseItem?.title || ''
  })

  return (
    <div className="exercise-sets-wrapper">
      <div className="exercise-sets">
        <div className="exercise-set-header">
          <div className="exercise-set-number">#</div>
          <div className="exercise-set-titles">
            {setLists.map((setList, idx) => {
              const isDataByKey = (key: string) =>
                setList[0]?.data
                  ? Object.keys(setList[0].data).includes(key)
                  : exerciseGroup?.initialData &&
                    Object.keys(exerciseGroup.initialData).includes(key)

              return (
                <div className="exercise-set-titles" key={idx}>
                  {/*{idx > 0 && <div className="exercise-set-plus"></div>}*/}
                  {isDataByKey('reps') && (
                    <span className="exercise-set-titles-item">
                      {t('app-workout-data-reps')}
                    </span>
                  )}
                  {isDataByKey('weight') && (
                    <span
                      className="exercise-set-titles-item"
                      style={{ marginRight: 0 }}
                    >
                      {isDataByKey('weight') ? t('app-workout-data-kg') : ''}
                    </span>
                  )}
                  {isDataByKey('length') && (
                    <span className="exercise-set-titles-item">
                      {exerciseIds[idx].includes('core')
                        ? t('app-workout-data-sec')
                        : t('app-workout-data-min')}
                    </span>
                  )}
                  {isDataByKey('speed') && (
                    <span
                      className="exercise-set-titles-item"
                      style={{ marginRight: 0 }}
                    >
                      {isDataByKey('speed') ? t('app-workout-data-speed') : ''}
                    </span>
                  )}
                </div>
              )
            })}
          </div>
          <div className="exercise-status" />
        </div>

        {setLists[0].map((setItem, idx) =>
          isEditMode ? (
            <EditItem
              set={setItem}
              key={setItem.id}
              idx={idx}
              exerciseStatus={exerciseStatus}
              exerciseId={exerciseIds[0]}
            />
          ) : (
            <SupersetItem
              sets={[setItem, setLists[1][idx]]}
              key={setItem.id}
              idx={idx}
              exerciseStatus={exerciseStatus}
              exerciseIds={exerciseIds}
              titles={titles}
            />
          )
        )}
      </div>
    </div>
  )
}

export default SupersetList
