const routes = {
  home: '/',

  exercises: '/exercises',
  exerciseItem: (id: string) => `/exercises/${id}`,
  profile: '/profile',

  activeWorkout: '/workout',

  stats: '/stats',

  workouts: '/workouts',
  workoutsArchive: '/workouts/archive',
  workoutView: (uuid: string) => `/workouts/view/${uuid}`,
  workoutEdit: (workoutId: string) => `/workouts/edit/${workoutId}`,
  archiveView: (workoutId: string) => `/workouts/archive/view/${workoutId}`,
  archiveEdit: (workoutId: string) => `/workouts/archive/edit/${workoutId}`,

  clients: '/clients',
  clientView: (id: string) => `/clients/${id}`,

  clientStats: (clientId: string) => `/clients/${clientId}/stats`,

  clientWorkouts: (clientId: string) => `/clients/${clientId}/workouts`,
  clientWorkoutsArchive: (clientId: string) =>
    `/clients/${clientId}/workouts/archive`,
  clientWorkoutView: (clientId: string, workoutId: string) =>
    `/clients/${clientId}/workouts/view/${workoutId}`,
  clientWorkoutEdit: (clientId: string, workoutId: string) =>
    `/clients/${clientId}/workouts/edit/${workoutId}`,
  clientWorkoutArchiveView: (clientId: string, workoutId: string) =>
    `/clients/${clientId}/workouts/archive/view/${workoutId}`,
  clientWorkoutArchiveEdit: (clientId: string, workoutId: string) =>
    `/clients/${clientId}/workouts/archive/edit/${workoutId}`,
}

export default routes
