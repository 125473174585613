import React, { ChangeEvent, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useTranslations from '../../hooks/useTranslations'
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { Dictionary, EditTranslationData } from '../../types'
import { DICTIONARIES, LANGUAGES, UA_KEY } from '../../helpers/constants'

interface Props {
  data: EditTranslationData
  handleClose: () => void
  handleConfirm: (data: EditTranslationData) => void
  dictionaries?: string[]
}

const EditTranslationDialog: React.FC<Props> = ({
  data,
  handleClose,
  handleConfirm,
  dictionaries = DICTIONARIES,
}) => {
  const { t } = useTranslations()

  const [values, setValues] = useState<Dictionary>(data.values)
  const [dictionary, setDictionary] = useState<string>(data.dictionary)
  const [key, setKey] = useState<string>(data.key)

  const handleConfirmClick = () => {
    if (values) {
      handleConfirm({ ...data, dictionary, key, values })
    }
  }

  const handleSetValues =
    (key: string) =>
    (
      e: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValues((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }))
    }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {data.key ? 'Edit' : 'Add'} translation
        {/*{data.key ? 'in' : 'to'}{' '}*/}
        {/*<i>"{data.dictionary}"</i>*/}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <Select
            size="small"
            fullWidth={true}
            value={dictionary}
            onChange={(e) => {
              setDictionary(e.target.value)
            }}
            sx={{ textTransform: 'capitalize' }}
            disabled={!!data.key}
          >
            {dictionaries?.map((el) => (
              <MenuItem
                key={el}
                value={el}
                sx={{ textTransform: 'capitalize' }}
              >
                {el}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ mt: 2, mb: 4 }}>
          <TextField
            value={key}
            size="small"
            label="Key"
            placeholder={
              data.dictionary === 'exercises'
                ? 'exercise-title-...'
                : 'translation-key'
            }
            onChange={(e) => {
              setKey(e.target.value)
            }}
            fullWidth={true}
            disabled={!!data.key}
          />
        </Box>

        {LANGUAGES.map((lang) => {
          return (
            <Box key={lang} sx={{ my: 2 }}>
              <TextField
                value={values?.[lang] ?? ''}
                size="small"
                label={(lang === 'uk' ? UA_KEY : lang).toUpperCase()}
                onChange={handleSetValues(lang)}
                fullWidth={true}
              />
            </Box>
          )
        })}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit">
          {t('app-button-dialog-no')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          autoFocus={true}
          variant="contained"
          color="warning"
          disabled={values === data.values && !key}
        >
          {t('app-button-dialog-yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditTranslationDialog
