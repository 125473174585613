import React, { ChangeEvent, useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useTranslations from '../../hooks/useTranslations'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DataContext } from '../../store'
import { NewExerciseData } from '../../types'

interface Props {
  defaultValue?: string
  handleClose: () => void
  handleConfirm: (data: NewExerciseData) => void
}

const AddExerciseDialog: React.FC<Props> = ({
  defaultValue = '',
  handleClose,
  handleConfirm,
}) => {
  const { exercises } = useContext(DataContext)
  const { t } = useTranslations()

  const [values, setValues] = useState<NewExerciseData>({
    title: defaultValue,
    group: '',
  })

  const handleConfirmClick = () => {
    handleConfirm(values)
  }

  const handleSetValues =
    (key: string) =>
    (
      e: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setValues((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }))
    }

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('add-exercise-button')}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        <TextField
          value={values.title}
          size="small"
          label="Exercise title"
          onChange={handleSetValues('title')}
          fullWidth={true}
        />

        <FormControl sx={{ my: 2 }} disabled={!values.title} fullWidth={true}>
          <InputLabel size="small">Exercise group</InputLabel>
          <Select
            size="small"
            value={values.group}
            onChange={handleSetValues('group')}
            input={<OutlinedInput size="small" label="Exercise group" />}
          >
            {exercises?.groups.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {t(`group-title-${el.id}`)}
              </MenuItem>
            ))}
            {!exercises?.groups.some((el) => el.id === 'other') && (
              <MenuItem value={'other'}>{t(`group-title-other`)}</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          value={values.link || ''}
          size="small"
          label="Link to video"
          onChange={handleSetValues('link')}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit">
          {t('app-button-dialog-no')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          autoFocus={true}
          variant="contained"
          color="warning"
          disabled={!(values.title && values.group)}
        >
          {t('app-button-dialog-yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddExerciseDialog
