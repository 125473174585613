import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import CancelIcon from '@mui/icons-material/Cancel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'

import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import { Box, Button, Card, IconButton } from '@mui/material'
import cn from 'classnames'

import { DataContext, WorkoutsContext } from '../../../store'

import '../../../styles/module.scss'

import { Exercise, Status } from '../../../types'
import WarningDialog from '../../Dialogs/WarningDialog'
import Countdown from '../../Countdown'
import useTranslations from '../../../hooks/useTranslations'
import DoneIcon from '@mui/icons-material/Done'
import { findLastIndexWithProperty } from '../../../helpers'
import theme from '../../../theme'
import SupersetList from '../Sets/SupersetList'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import StatsButton from '../../Buttons/StatsButton'
import { findMaxResult } from '../../../helpers/stats'

interface Props {
  data: Exercise[]
  isOpen: boolean
  setIsOpen: (key: string) => void
  setStartNext?: (key: string) => void
  isStartNext?: boolean
}

const ExerciseSuperset: React.FC<Props> = ({
  data,
  isOpen,
  setIsOpen,
  setStartNext,
  isStartNext,
}) => {
  const { exercises, isLoading } = useContext(DataContext)
  const { updateExerciseProgress, currentWorkout, isEditMode, isViewMode } =
    useContext(WorkoutsContext)
  const { t } = useTranslations()
  const elementRef = useRef<null | HTMLDivElement>(null)

  const [isCancelWarning, setIsCancelWarning] = useState<boolean>(false)
  const [isStopWarning, setIsStopWarning] = useState<boolean>(false)

  const exercise1 = data[0]
  const exercise2 = data[1]

  const isInProgress = exercise1.status === Status.inProgress
  const isCompleted = exercise1.status === Status.completed
  const isWorkoutCompleted = currentWorkout?.status === Status.completed

  useEffect(() => {
    if (currentWorkout) {
      // const exIdx = currentWorkout?.exercises.findIndex((ex) => !ex.status) ?? 0
      const lastCompletedIdx =
        findLastIndexWithProperty(currentWorkout.exercises, 'status', 2) + 1

      if (
        currentWorkout.status === Status.inProgress &&
        currentWorkout.exercises[lastCompletedIdx]?.exercise_id &&
        !currentWorkout.exercises.some((ex) => ex.status === Status.inProgress)
      ) {
        setIsOpen(currentWorkout.exercises[lastCompletedIdx].id)
      }

      if (currentWorkout.status === Status.inProgress) {
        window.scrollTo()
      }
    }
  }, [currentWorkout])

  useEffect(() => {
    if (
      currentWorkout?.status === Status.inProgress &&
      isInProgress &&
      elementRef.current
    ) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [currentWorkout, isInProgress, elementRef])

  useEffect(() => {
    if (
      isStartNext &&
      setStartNext &&
      updateExerciseProgress &&
      exercise1.status === Status.new
    ) {
      setStartNext('')
      updateExerciseProgress([exercise1.id, exercise2.id], Status.inProgress)
    }
  }, [isStartNext])

  const groupTitles = [
    t(
      `group-title-${exercises?.groups.find((group) =>
        group.exercises.some((ex) => ex.id === exercise1.exercise_id)
      )?.group_name_id}`
    ),
    t(
      `group-title-${exercises?.groups.find((group) =>
        group.exercises.some((ex) => ex.id === exercise2.exercise_id)
      )?.group_name_id}`
    ),
  ]

  const exerciseTitle = () => {
    const titles: string[] = []
    const links: string[] = []

    data.forEach(({ exercise_id }) => {
      exercises?.groups.forEach((group) => {
        group.exercises.forEach(({ id, title, link }) => {
          if (exercise_id === id) {
            titles.push(t(`exercise-title-${id}`) || title)

            links.push(link || '')
          }
        })
      })
    })

    return (
      <>
        <div onClick={handleViewClick}>
          <small
            style={{
              marginTop: 0,
              marginBottom: 4,
              color: theme.palette.primary.main,
            }}
          >
            {exercise1.status === Status.completed &&
              currentWorkout?.status === Status.inProgress && (
                <DoneIcon
                  color="success"
                  sx={{ width: 20, height: 20, my: -0.5, mr: 0.5 }}
                />
              )}
            Superset:{' '}
            {groupTitles
              .filter((e, i, l) => i === 0 || (i > 0 && e !== l[i - 1]))
              // .map((group, idx) => (
              //   <div key={idx}>{group}</div>
              // ))
              .join('/')}
          </small>
        </div>

        <div
          style={{ flexBasis: '100%' }}
          onClick={() => {
            if (!isOpen) {
              handleViewClick()
            }
          }}
        >
          {isOpen || isInProgress || isWorkoutCompleted ? (
            <Box
              sx={{
                display: 'flex',
                my: 1,
                mr: isInProgress ? '4%' : 0,
                lineHeight: 1.2,
              }}
            >
              {titles.map((title, idx) => {
                return (
                  <Fragment key={idx}>
                    {idx > 0 && <div className="exercise-set-plus">+</div>}
                    <Box className="setList-superset-title">
                      {title}
                      <Box sx={{ display: 'inline-block' }}>
                        <StatsButton
                          id={data[idx].exercise_id}
                          exerciseData={{
                            date: String(currentWorkout?.date),
                            value: findMaxResult(
                              data[idx].sets,
                              !isWorkoutCompleted
                            ),
                          }}
                          sx={{ width: 20, height: 20 }}
                        />
                        {links[idx] &&
                          (isOpen || isInProgress) &&
                          !isEditMode && (
                            <a
                              href={links[idx]}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconButton size="small" sx={{ height: '16px' }}>
                                <SmartDisplayIcon
                                  sx={{ width: 20, height: 20 }}
                                  color="primary"
                                />
                              </IconButton>
                            </a>
                          )}
                      </Box>
                    </Box>
                  </Fragment>
                )
              })}
            </Box>
          ) : (
            // titles.map((title, idx) => (
            //   <Box onClick={handleViewClick} key={idx}>
            //     • {title}
            //   </Box>
            // ))
            titles.join(' + ')
          )}
        </div>
      </>
    )
  }

  const handleViewClick = () => {
    setIsOpen(isOpen ? '' : exercise1.id)
  }

  const handleExerciseStart = () => {
    if (updateExerciseProgress) {
      updateExerciseProgress([exercise1.id, exercise2.id], Status.inProgress)
    }
  }

  const handleExerciseStop = async (isNext?: boolean) => {
    if (updateExerciseProgress) {
      await updateExerciseProgress(
        [exercise1.id, exercise2.id],
        Status.completed
      )

      if (isNext && setStartNext) {
        setStartNext(exercise2.id)
      }

      setIsStopWarning(false)
    }
  }

  const handleExerciseCancel = () => {
    updateExerciseProgress &&
      updateExerciseProgress([exercise1.id, exercise2.id], Status.new)
    setIsCancelWarning(false)
  }

  const getExerciseComments = useMemo(
    () => () =>
      exercise1.sets.reduce((res: string[], set) => {
        if (set.results?.comment) {
          res.push(set.results.comment)
        }

        return res
      }, []),
    [exercise1]
  )

  return (
    <Card
      ref={elementRef}
      className={cn('exercise-item', {
        'exercise-item-in-progress': isInProgress,
        'exercise-item-completed': isCompleted,
        'exercise-item-completed-view': isWorkoutCompleted,
        'exercise-item-open': isOpen || isWorkoutCompleted,
        'exercise-item-superset': exercise1.superset,
      })}
    >
      <div className="exercise-title">{exerciseTitle()}</div>
      <div className="exercise-buttons">
        {exercise1.startTime && isInProgress && (
          <div className="exercise-countdown">
            <Countdown
              startTime={exercise1.startTime}
              endTime={exercise1.endTime}
            />
          </div>
        )}

        {exercise1.status !== Status.inProgress && !isWorkoutCompleted && (
          <IconButton
            className="exercise-view-button"
            onClick={handleViewClick}
            size="small"
            disabled={isLoading}
          >
            {isOpen ? (
              <ExpandLessIcon sx={{ width: 32, height: 32 }} color="primary" />
            ) : (
              <ExpandMoreIcon sx={{ width: 32, height: 32 }} color="primary" />
            )}
          </IconButton>
        )}
      </div>
      {(isOpen || isWorkoutCompleted || isInProgress) && (
        <div className="exercise-data">
          {exercise1.sets && (
            <SupersetList
              exerciseIds={[exercise1.exercise_id, exercise2.exercise_id]}
              ids={[exercise1.id, exercise2.id]}
              setLists={[exercise1.sets, exercise2.sets]}
            />
          )}

          {/*{!isEditMode && getExerciseComments().length > 0 && (*/}
          {/*  <div className="exercise-item-comments">*/}
          {/*    {'('}*/}
          {/*    {getExerciseComments().map((el, idx) => (*/}
          {/*      <React.Fragment key={idx}>*/}
          {/*        {idx > 0 && ', '}*/}
          {/*        {el}*/}
          {/*      </React.Fragment>*/}
          {/*    ))}*/}
          {/*    {')'}*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      )}

      {!isEditMode && exercise1.comments && (
        <div className="exercise-item-comments">
          <b>{t('app-trainer')}</b>: "{exercise1.comments}"
        </div>
      )}

      {!isEditMode &&
        (isInProgress || isOpen) &&
        getExerciseComments().length > 0 && (
          <div className="exercise-item-comments">
            <b>{t('app-workout-comment')}</b>: "
            {getExerciseComments().map((el, idx) => (
              <React.Fragment key={idx}>
                {idx > 0 && ', '}
                {el}
              </React.Fragment>
            ))}
            "
          </div>
        )}

      {!isEditMode &&
        (!isViewMode || (isViewMode && isCompleted)) &&
        (isInProgress || isOpen) && (
          <div className="exercise-bottom-buttons">
            {isOpen &&
              currentWorkout?.status === Status.inProgress &&
              (exercise1.status === Status.new || !exercise1.status) &&
              !currentWorkout?.exercises.some(
                ({ status }) => status === Status.inProgress
              ) && (
                <Button
                  size="small"
                  fullWidth={true}
                  color="warning"
                  variant="contained"
                  sx={{ margin: '0 auto' }}
                  onClick={handleExerciseStart}
                  startIcon={<PlayArrowIcon />}
                  disabled={isLoading}
                >
                  {t('app-button-start-exercise')}
                </Button>
              )}
            {(isInProgress || (isCompleted && isOpen)) && (
              <>
                {exercise1.sets.some((set) => set.results) ? (
                  <IconButton
                    color="secondary"
                    size="small"
                    disabled={isLoading}
                    onClick={() => {
                      setIsCancelWarning(true)
                    }}
                  >
                    <CancelIcon sx={{ width: 20, height: 20 }} />
                  </IconButton>
                ) : (
                  <Button
                    className="exercise-cancel-button"
                    onClick={() => {
                      setIsCancelWarning(true)
                    }}
                    startIcon={<CancelIcon />}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={isLoading}
                  >
                    {t('app-button-cancel-exercise')}
                  </Button>
                )}

                {isCancelWarning && (
                  <WarningDialog
                    handleClose={() => {
                      setIsCancelWarning(false)
                    }}
                    handleConfirm={handleExerciseCancel}
                    title={t('app-cancel-exercise-dialog-title')}
                    note={t('app-cancel-exercise-dialog-text')}
                    isCancel={true}
                  />
                )}
              </>
            )}
            {isInProgress && (
              <Box>
                {exercise1.sets.some((set) => set.results) && (
                  <Button
                    className="exercise-cancel-button"
                    onClick={() => {
                      !exercise1.sets.some((s) => !s.results)
                        ? handleExerciseStop()
                        : setIsStopWarning(true)
                    }}
                    startIcon={<SportsScoreIcon />}
                    variant="outlined"
                    color={
                      !exercise1.sets.some((s) => !s.results)
                        ? 'inherit'
                        : 'primary'
                    }
                    size="small"
                    disabled={isLoading}
                  >
                    {t('app-button-finish-exercise')}
                  </Button>
                )}

                {setStartNext &&
                  !exercise1.sets.some((set) => !set.results) && (
                    <Button
                      className="exercise-cancel-button"
                      onClick={() => {
                        !exercise1.sets.some((s) => !s.results)
                          ? handleExerciseStop(true)
                          : setIsStopWarning(true)
                      }}
                      startIcon={<SkipNextIcon />}
                      variant="outlined"
                      color="inherit"
                      size="small"
                      disabled={isLoading}
                      sx={{ ml: 1 }}
                    >
                      {t('app-button-finish-next')}
                    </Button>
                  )}

                {isStopWarning && (
                  <WarningDialog
                    handleClose={() => {
                      setIsStopWarning(false)
                    }}
                    handleConfirm={handleExerciseStop}
                    title={t('app-finish-exercise-dialog-title')}
                    note={t('app-finish-exercise-dialog-text')}
                  />
                )}
              </Box>
            )}
          </div>
        )}
    </Card>
  )
}

export default ExerciseSuperset
