import React, { FC, useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'
import CloseIcon from '@mui/icons-material/Close'

const VideoButton: FC<{ link: string; end?: number; start?: number }> = ({
  link,
  end,
  start,
}) => {
  const isYoutube = link.includes('youtube')
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return isYoutube ? (
    <>
      <IconButton size="small" onClick={handleOpen}>
        <SmartDisplayIcon color="primary" />
      </IconButton>

      <Dialog
        open={open}
        fullScreen={true}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            background: 'transparent',
            boxShadow: 0,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 2,
            color: (theme) => theme.palette.grey[300],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#00000099',
          }}
        >
          <iframe
            width="315"
            height="560"
            src={`${link
              .replace('/shorts/', '/embed/')
              .replace(
                '/watch?v=',
                '/embed/'
              )}?controls=0&autoplay=1&loop=1&mute=1&showinfo=0${
              end ? '&end=' + end : ''
            }${start ? '&start=' + start : ''}`}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen={true}
          />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <a href={link} target="_blank" rel="noreferrer">
      <IconButton size="small">
        <SmartDisplayIcon color="primary" />
      </IconButton>
    </a>
  )
}

export default VideoButton
