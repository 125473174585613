export const machineWeights = new Array(41).fill(0).map((_, idx) => idx * 5)
export const dumbbellWeights = new Array(41)
  .fill(0)
  .map((_, idx) => (idx < 8 ? idx + 2 : idx * 2 - 6))
export const freeWeights = new Array(31).fill(0).map((_, idx) => idx)
export const repValues = new Array(41).fill(0).map((_, idx) => idx)

export const TIMER = 60

export const APP_LOCALE = 'en-gb'
export const DEFAULT_LOCALE = 'en'

export const WARMUP_DATA = { length: 7 }
export const EXERCISE_DATA = { reps: 12, weight: 0 }
export const CARDIO_DATA = { length: 20, speed: 6 }

export const DICTIONARIES = ['general', 'exercises']

export const LANGUAGES = ['en', 'uk', 'de', 'ru']
export const UA_KEY = 'ua'

export const DATE_FORMAT = 'DD.MM.YY'
export const DATE_FORMAT_WEEKDAYS = 'dd DD.MM.YY'
export const DATE_FORMAT_WEEKDAYS_FULL = 'dd DD.MM.YYYY'
export const DATE_FORMAT_DATA = 'YYYY-MM-DD'

export const LANGUAGES_FULL: { [key: string]: string } = {
  de: 'Deutsch',
  en: 'English',
  uk: 'Українська',
  ru: 'Русский',
}

export const ROLES: { [key: string]: string } = {
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  TRAINER: 'TRAINER',
  SUPER_TRAINER: 'SUPER_TRAINER',
}
