import React, { useContext } from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import useTranslations from '../../../hooks/useTranslations'
import routes from '../../../helpers/routes'
import { WorkoutsContext } from '../../../store'
import { useNavigate, useParams } from 'react-router-dom'

const AddWorkoutButton: React.FC = () => {
  const { createNewWorkout } = useContext(WorkoutsContext)
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { clientId } = useParams()

  const handleAddWorkoutClick = () => {
    if (createNewWorkout) {
      createNewWorkout()

      navigate(
        clientId
          ? routes.clientWorkoutEdit(clientId, 'new')
          : routes.workoutEdit('new')
      )
    }
  }

  return (
    <Button
      onClick={handleAddWorkoutClick}
      className="flex-row"
      sx={{ fontSize: 12, textTransform: 'none', padding: 0 }}
      size="small"
    >
      <AddIcon sx={{ width: 16, height: 16 }} />
      {t('app-button-add-workout')}
    </Button>
  )
}

export default AddWorkoutButton
