import React, { useContext, useState, useEffect } from 'react'
import { Box, Card, TextField, IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import cn from 'classnames'

import { DataContext, WorkoutsContext } from '../../../store'
import SetList from '../Sets/SetList'

import '../../../styles/module.scss'

import { Exercise, Status, Workout } from '../../../types'
import useTranslations from '../../../hooks/useTranslations'
import { deepClone } from '../../../helpers'
import SearchExerciseDialog from '../../Dialogs/SearchExerciseDialog'
import { WARMUP_DATA } from '../../../helpers/constants'
import StatsButton from '../../Buttons/StatsButton'
import { findMaxResult } from '../../../helpers/stats'

interface Props {
  exercise: Exercise
}

const EditExerciseItem: React.FC<Props> = ({ exercise }) => {
  const { exercises, findExerciseGroup } = useContext(DataContext)
  const { currentWorkout, updateEditWorkout } = useContext(WorkoutsContext)

  const { t } = useTranslations()

  const [isEditTitle, setIsEditTitle] = useState<boolean>(false)

  useEffect(() => {
    let isValidId = false
    exercises?.groups.forEach((group) => {
      if (exercise.id.includes(group.id)) {
        if (group.exercises.some((ex) => ex.id === exercise.exercise_id)) {
          isValidId = true
        }
      }
    })

    if (!exercise.id || !isValidId) {
      setIsEditTitle(true)
    }
  }, [exercise.id])

  const exerciseGroup = findExerciseGroup(exercise.exercise_id)

  const groupTitle = exerciseGroup?.id
    ? t(`group-title-${exerciseGroup?.group_name_id}`)
    : ''

  const exerciseTitle = () => {
    let title = ''

    exercises?.groups.forEach((group) => {
      const exerciseItem = group.exercises.find(
        (ex) => ex.id === exercise.exercise_id
      )

      if (exerciseItem?.title) {
        title += t(`exercise-title-${exerciseItem.id}`) || exerciseItem?.title
      }
    })

    return title
  }

  const handleCommentChange = (comments: string) => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData?.exercises?.forEach((ex) => {
        if (ex.exercise_id === exercise.exercise_id) {
          ex.comments = comments
        }
      })

      updateEditWorkout && updateEditWorkout(newData as Workout)
    }
  }

  const handleExerciseDeleteClick = (isHard: boolean = false) => {
    if (
      currentWorkout &&
      (isHard || (!isHard && global.confirm('Delete exercise?')))
    ) {
      const exIdx = currentWorkout?.exercises?.findIndex((ex) => {
        return ex.id === exercise.id
      })

      if (updateEditWorkout) {
        currentWorkout?.exercises?.splice(exIdx, 1)
        updateEditWorkout(currentWorkout)
      }
    }
  }

  const handleSearchCancel = () => {
    setIsEditTitle(false)
    if (!exercise.title) {
      handleExerciseDeleteClick(true)
    }
  }

  const handleTitleChange = (data: { title: string; id: string }) => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData?.exercises?.forEach((item, index) => {
        if (item.exercise_id === exercise.exercise_id) {
          if (item.id) {
            item.id = item.id.replace(item.exercise_id, data.id)
            item.sets.forEach((el, idx) => {
              el.id = el.id.replace(item.exercise_id, data.id)
            })
          } else {
            const newId = `${data.id}_${Date.now()}_${index + 1}`
            item.id = newId

            item.sets.forEach((el, idx) => {
              const exerciseGroup = findExerciseGroup(data.id)
              el.id = `${newId}_${idx + 1}`
              el.data =
                exercise.type === 'warmup'
                  ? WARMUP_DATA
                  : {
                      ...(exerciseGroup?.exercises.find((e) => e.id === data.id)
                        ?.initialData ?? exerciseGroup?.initialData),
                    }
            })
          }

          item.exercise_id = data.id
          item.title = data.title
        }
      })

      updateEditWorkout && updateEditWorkout(newData as Workout)
    }
    setIsEditTitle(false)
  }

  const isNewWorkout = currentWorkout?.status === Status.new

  const handleAddSetButton = () => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData?.exercises?.forEach((item) => {
        if (item.exercise_id === exercise.exercise_id) {
          item.sets.forEach((set, idx) => {
            set.id = `${exercise.id}_${idx + 1}`
          })

          item.sets.push({
            id: `${exercise.id}_${item.sets.length + 1}`,
            data: item.sets[item.sets.length - 1]?.data
              ? { ...item.sets[item.sets.length - 1].data }
              : exercise.type === 'warmup'
              ? WARMUP_DATA
              : {
                  ...(exerciseGroup?.exercises.find((e) => e.id === item.id)
                    ?.initialData ?? exerciseGroup?.initialData),
                },
          })
        }
      })

      updateEditWorkout && updateEditWorkout(newData as Workout)
    }
  }

  const isCardio = exercise.type === 'warmup' || exercise.type === 'cardio'

  return (
    <Card
      className={cn(
        'exercise-item',
        'exercise-item-edit',
        'exercise-item-open',
        { 'exercise-item-title-edit': isEditTitle },
        { 'exercise-item-superset': exercise.superset }
      )}
    >
      {isEditTitle && isNewWorkout && (
        <SearchExerciseDialog
          currentTitle={exercise.title}
          handleClose={handleSearchCancel}
          onChange={handleTitleChange}
          isCardio={isCardio || exercise.exercise_id.includes('cardio')}
          exId={exercise.exercise_id}
        />
      )}

      <div className="exercise-title">
        <div>
          <span
            className={cn({
              'exercise-title-edit': isNewWorkout,
            })}
            onClick={() => {
              setIsEditTitle(true)
            }}
          >
            {exerciseTitle() || exercise.title}
          </span>

          <small>
            {exercise.type === 'warmup' ? t('app-workouts-warmup') : groupTitle}
          </small>
        </div>

        {isNewWorkout && (
          <div className="exercise-title-links">
            <StatsButton
              id={exercise.exercise_id}
              exerciseData={{
                date: String(currentWorkout?.date),
                value: findMaxResult(exercise.sets, true),
              }}
            />

            <IconButton
              onClick={() => {
                handleExerciseDeleteClick()
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </div>
        )}
      </div>

      {exercise.id && (
        <div className="exercise-data">
          {exercise.sets && (
            <SetList
              exerciseId={exercise.exercise_id}
              id={exercise.id}
              setList={exercise.sets}
            />
          )}
          {(!exercise.id.includes('cardio') || exercise.sets.length === 0) &&
            currentWorkout?.status === Status.new && (
              <IconButton
                size="small"
                onClick={handleAddSetButton}
                sx={{ display: 'flex', ml: -1, mt: 1 }}
              >
                <AddCircleIcon sx={{ width: 24, height: 24 }} />
              </IconButton>
            )}

          <Box sx={{ mt: 3, mx: '-10px' }}>
            <TextField
              sx={{ fontStyle: 'italic' }}
              fullWidth={true}
              multiline={true}
              rows={2}
              size="small"
              label={t('app-workout-comment')}
              defaultValue={
                exercise.sets
                  .reduce((res: string[], set) => {
                    if (set.results?.comment) {
                      res.push(set.results.comment)
                    }

                    return res
                  }, [])
                  .join(',') || exercise.comments
              }
              onChange={(e) => {
                handleCommentChange(e.target.value)
              }}
            />
          </Box>
        </div>
      )}
    </Card>
  )
}

export default EditExerciseItem
