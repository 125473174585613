import React, { useContext, useEffect, useState } from 'react'
import { Box, Checkbox, CircularProgress, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import EditIcon from '@mui/icons-material/Edit'

import '../../../styles/module.scss'

import { Set, SetData, Status } from '../../../types'
import SetConfirmDialog from '../../Dialogs/SetConfirmDialog'
import { DataContext, UserContext, WorkoutsContext } from '../../../store'
import Timer from '../../Timer'
import SetUnit from './SetUnit'
import useTranslations from '../../../hooks/useTranslations'

interface Props {
  set: Set
  idx: number
  exerciseStatus: Status
  exerciseId: string
  exId?: string
}

const SetItem: React.FC<Props> = ({
  set,
  exerciseStatus,
  idx,
  exerciseId,
  exId,
}) => {
  const { isClient } = useContext(UserContext)
  const { isLoading } = useContext(DataContext)
  const { updateSetProgress, currentWorkout, isViewMode } =
    useContext(WorkoutsContext)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslations()

  useEffect(() => {
    if (!isLoading) {
      setLoading(false)
    }
  }, [isLoading])

  const handleSetUpdate = (setId: string, value?: SetData) => {
    if (updateSetProgress) {
      if (value) {
        updateSetProgress(setId, value)
      } else if (!value && global.confirm(t('app-button-cancel-exercise'))) {
        updateSetProgress(setId)
      }
    }
  }

  const currentExercise = currentWorkout?.exercises?.find(
    (wkot) => wkot.id === exId
  )

  const noResultIndex =
    currentExercise?.sets?.findIndex((set) => !set.results) || 0

  const isComplete = Boolean(set.results)

  const handleEditClick = () => {
    setShowDialog(true)
  }

  const handleOnChange = () => {
    // setLoading(true)
    if (!isComplete) {
      handleSetUpdate(set.id, { ...set.data })
    } else {
      handleSetUpdate(set.id)
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleCloseClick = () => {
    handleDialogClose()
  }

  const handleSaveClick = (newData: SetData) => {
    // setLoading(true)
    handleSetUpdate(set.id, newData)
    handleDialogClose()
  }

  const prevTime: number =
    currentExercise?.sets?.[noResultIndex - 1]?.results?.time ?? 0

  const isTimer =
    currentWorkout?.status === Status.inProgress &&
    idx === noResultIndex &&
    exerciseStatus === Status.inProgress &&
    !set.results &&
    prevTime > 0

  const activeSetInProgress =
    exerciseStatus === Status.inProgress && idx === noResultIndex && isClient

  return (
    <div
      className={`exercise-set-data${
        isComplete && !isViewMode ? ' exercise-set-complete' : ''
      }${
        idx === noResultIndex &&
        !isViewMode &&
        exerciseStatus === Status.inProgress &&
        isClient
          ? ' active-set'
          : ''
      }`}
    >
      {isTimer && !loading && currentExercise && (
        <Timer timer={prevTime} isIntensive={currentExercise.sets.length > 5} />
      )}

      <div className="exercise-set-number">{idx + 1}</div>
      {typeof set.data.reps === 'number' && (
        <div className="exercise-set-pairs">
          {set.data.reps >= 0 && <SetUnit unit="reps" set={set} />}
          {typeof set.data.weight === 'number' && (
            <SetUnit unit="weight" set={set} />
          )}
        </div>
      )}
      {typeof set.data.length === 'number' && (
        <div className="exercise-set-pairs">
          {set.data.length >= 0 && <SetUnit unit="length" set={set} />}
          {typeof set.data.speed === 'number' && (
            <SetUnit unit="speed" set={set} />
          )}
        </div>
      )}
      <div className="exercise-status">
        {isViewMode && currentWorkout?.status === Status.completed ? (
          set.results ? (
            <CheckIcon sx={{ width: 16 }} />
          ) : (
            <RemoveIcon sx={{ width: 16 }} />
          )
        ) : (
          <>
            {(Boolean(idx <= noResultIndex) || set.results) &&
              currentExercise?.status === Status.inProgress &&
              isClient && (
                <IconButton
                  size="small"
                  color={idx === noResultIndex ? 'inherit' : 'primary'}
                  sx={{
                    position: 'absolute',
                    zIndex: 2,
                    right: 32,
                  }}
                  onClick={handleEditClick}
                  disabled={isLoading}
                >
                  <EditIcon sx={{ width: 16, height: 16 }} />
                </IconButton>
              )}
            {loading ? (
              <Box sx={{ height: 26, mr: activeSetInProgress ? 0 : '4px' }}>
                <CircularProgress
                  size={activeSetInProgress ? 26 : 20}
                  color="inherit"
                />
              </Box>
            ) : (
              <Checkbox
                className="set-checkbox"
                onChange={handleOnChange}
                checked={isComplete}
                size={activeSetInProgress ? 'medium' : 'small'}
                disabled={
                  exerciseStatus !== Status.inProgress ||
                  Boolean(idx > noResultIndex) ||
                  Boolean(idx < noResultIndex - 1) ||
                  isViewMode ||
                  isLoading ||
                  !isClient
                }
              />
            )}
          </>
        )}
      </div>

      {showDialog && (
        <SetConfirmDialog
          set={set}
          idx={idx}
          open={showDialog}
          exerciseId={exerciseId}
          handleCloseClick={handleCloseClick}
          handleSaveClick={handleSaveClick}
        />
      )}
    </div>
  )
}

export default SetItem
