import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import RCountdown from 'react-countdown'
import TimerIcon from '@mui/icons-material/Timer'
import TimeDiff from '../TimeDiff'

interface Props {
  startTime: number
  endTime?: number
}

const Countdown: React.FC<Props> = ({ startTime, endTime }) => {
  const [timer, setTimer] = useState<number | null>(null)

  useEffect(() => {
    if (startTime) {
      setTimer(startTime)
    }
  }, [startTime])

  const timeDifference = dayjs.duration(dayjs(endTime).diff(dayjs(startTime)))

  if (!timer) {
    return null
  }

  return (
    <div className="workout-timer">
      {Boolean(endTime) ? (
        <>
          <TimerIcon />

          <TimeDiff timeDifference={timeDifference} />
        </>
      ) : (
        <RCountdown
          date={timer}
          overtime={true}
          renderer={({ formatted }) => {
            return (
              <span>
                {formatted.hours}:{formatted.minutes}:{formatted.seconds}
              </span>
            )
          }}
        />
      )}
    </div>
  )
}

export default Countdown
