import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Button } from '@mui/material'
import Countdown from 'react-countdown'
import { useLocation, useNavigate } from 'react-router-dom'

import routes from '../../helpers/routes'
import { DataContext, UserContext, WorkoutsContext } from '../../store'
import { Status } from '../../types'

const WorkoutInProgressButton: React.FC = () => {
  const { isClient } = useContext(UserContext)
  const { workouts } = useContext(DataContext)
  const { setCurrentWorkout } = useContext(WorkoutsContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [isNotActiveWorkoutPage, setIsNotActiveWorkoutPage] =
    useState<boolean>(false)

  useEffect(() => {
    setIsNotActiveWorkoutPage(pathname !== routes.activeWorkout)
  }, [pathname])

  const currentWorkout = useMemo(
    () => workouts?.find((w) => w.status === Status.inProgress),
    [workouts]
  )

  return isClient &&
    isNotActiveWorkoutPage &&
    currentWorkout?.status === Status.inProgress ? (
    <Box sx={{ p: 2 }}>
      <Button
        fullWidth={true}
        variant="contained"
        color="error"
        size="large"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={() => {
          setCurrentWorkout && setCurrentWorkout(currentWorkout.uuid)
          navigate(routes.activeWorkout)
        }}
      >
        Workout in progress
        <Countdown
          date={currentWorkout.startTime}
          overtime={true}
          renderer={({ formatted }) => {
            return (
              <span>
                {formatted.hours}:{formatted.minutes}:{formatted.seconds}
              </span>
            )
          }}
        />
      </Button>
    </Box>
  ) : null
}

export default WorkoutInProgressButton
