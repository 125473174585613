import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      dark: '#295077',
    },
    secondary: {
      main: '#222222',
    },
    background: {
      paper: '#0F1F32',
    },
  },
})

export default theme
