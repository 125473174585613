import React from 'react'
import { Box } from '@mui/material'
import { DATE_FORMAT } from '../../helpers/constants'
import useTranslations from '../../hooks/useTranslations'
import { Dayjs } from 'dayjs'

const WeekTitle: React.FC<{ date: Dayjs }> = ({ date }) => {
  const { t } = useTranslations()

  return (
    <Box className="flex-row">
      {t('app-title-week')} #{date.week()}{' '}
      <span style={{ opacity: 0.6, fontWeight: 400, fontSize: '80%' }}>
        {date.startOf('week').format(DATE_FORMAT)} —{' '}
        {date.endOf('week').format(DATE_FORMAT)}
      </span>
    </Box>
  )
}

export default WeekTitle
