import React, { useContext, useState } from 'react'
import {
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import '../../../styles/module.scss'

import { Set, Status, Workout } from '../../../types'
import {
  freeWeights,
  machineWeights,
  repValues,
} from '../../../helpers/constants'
import { WorkoutsContext } from '../../../store'
import { deepClone } from '../../../helpers'
import useWeights from '../../../hooks/useWeights'

interface Props {
  set: Set
  idx: number
  exerciseStatus: Status
  exerciseId: string
}

const EditItem: React.FC<Props> = ({ set, idx, exerciseId }) => {
  const { currentWorkout, updateEditWorkout } = useContext(WorkoutsContext)
  const { weights } = useWeights({ exerciseId })
  const [deleteIdx, setDeleteIdx] = useState<string>('')

  const data = set.results || set.data

  const handleChange = ({
    type,
    value,
  }: {
    type: 'weight' | 'reps' | 'speed' | 'length'
    value: number | string
  }) => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData?.exercises?.forEach((ex) => {
        if (ex.exercise_id === exerciseId) {
          ex.sets.forEach((el) => {
            if (el.id === set.id) {
              if (el.results && Object.keys(el.results).includes(type)) {
                el.results[type] = Number(value)
              } else if (el.data && Object.keys(el.data).includes(type)) {
                el.data[type] = Number(value)
              }
            }
          })
        }
      })

      updateEditWorkout && updateEditWorkout(newData as Workout)
    }
  }

  const handleDeleteClick = () => {
    if (currentWorkout) {
      setDeleteIdx(set.id)

      setTimeout(() => {
        const newData = deepClone(currentWorkout)

        newData?.exercises?.forEach((ex) => {
          if (ex.exercise_id === exerciseId) {
            ex.sets.splice(idx, 1)
          }
        })

        updateEditWorkout && updateEditWorkout(newData as Workout)
        setDeleteIdx('')
      }, 220)
    }
  }

  const isCompletedWorkout = currentWorkout?.status === Status.completed

  return (
    <Collapse
      in={!set.id || set.id !== deleteIdx}
      timeout={deleteIdx ? 300 : 0}
    >
      <div className="exercise-set-data">
        <div className="exercise-set-number">{idx + 1}</div>
        {typeof data.reps === 'number' && (
          <div className="exercise-set-pairs">
            <FormControl
              sx={{ mt: 1, width: '47%' }}
              className="exercise-set-edit-item"
            >
              <Select
                size="small"
                value={data.reps}
                disabled={weights.length <= 0}
                onChange={(e) => {
                  handleChange({ type: 'reps', value: e.target.value })
                }}
              >
                {repValues.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {typeof data.weight === 'number' && weights.length > 0 && (
              <FormControl
                sx={{ mt: 1, width: '47%' }}
                className="exercise-set-edit-item"
              >
                <Select
                  size="small"
                  value={data.weight}
                  disabled={weights.length <= 0}
                  onChange={(e) => {
                    handleChange({ type: 'weight', value: e.target.value })
                  }}
                >
                  {weights.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
        {data.length && (
          <div className="exercise-set-pairs">
            {data.length && (
              <FormControl
                sx={{ mt: 1, width: '47%' }}
                className="exercise-set-edit-item"
              >
                <Select
                  size="small"
                  value={data.length}
                  disabled={weights.length <= 0}
                  onChange={(e) => {
                    handleChange({ type: 'length', value: e.target.value })
                  }}
                >
                  {(set.id.includes('core') ? machineWeights : freeWeights).map(
                    (el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            {data.speed && (
              <FormControl
                sx={{ mt: 1, width: '47%' }}
                className="exercise-set-edit-item"
              >
                <Select
                  size="small"
                  value={data.speed}
                  onChange={(e) => {
                    handleChange({ type: 'speed', value: e.target.value })
                  }}
                >
                  {repValues.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
        {!isCompletedWorkout && !exerciseId.includes('cardio') && (
          <div className="exercise-status">
            <IconButton
              size="small"
              onClick={handleDeleteClick}
              sx={{ mr: -1 }}
            >
              <ClearIcon sx={{ width: 20, height: 20 }} />
            </IconButton>
          </div>
        )}
      </div>
    </Collapse>
  )
}

export default EditItem
