import React, { useContext } from 'react'
import { CircularProgress, Box, Typography } from '@mui/material'
import Countdown from 'react-countdown'

import { TIMER } from '../../helpers/constants'
import { UserContext } from '../../store'

import '../../styles/module.scss'

const TimerRender = ({
  seconds,
  minutes,
  completed,
  timer,
  label,
}: {
  seconds: number
  minutes: number
  completed: boolean
  timer: number
  label?: string
}) => {
  const time = minutes * 60 + seconds

  if (completed || time === 0) {
    return null
  }

  return (
    <Box className="exercise-timer">
      {label && <Typography>{label}</Typography>}

      <CircularProgress
        variant="determinate"
        value={Math.round((time / timer) * 100)}
        size={30}
        color="inherit"
      />
      <Box className="exercise-timer-text">
        <Typography variant="caption" component="div" sx={{ fontSize: 12 }}>
          {time}
        </Typography>
      </Box>
    </Box>
  )
}

interface TimerProps {
  timer: number
  label?: string
  isSuperset?: boolean
  isIntensive?: boolean
}

const Timer: React.FC<TimerProps> = ({
  timer: startTime,
  label,
  isSuperset,
  isIntensive,
}) => {
  const { user } = useContext(UserContext)
  const timer = Math.round(
    (isIntensive ? 30 : user?.settings?.timer ?? TIMER) *
      (isSuperset ? 1.5 : 1) +
      1
  )

  return (
    <Countdown
      date={startTime + timer * 1000}
      intervalDelay={0}
      precision={3}
      renderer={(props) => (
        <TimerRender {...props} timer={timer} label={label} />
      )}
    />
  )
}

export default Timer
