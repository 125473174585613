import React, { Fragment, useContext, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { Exercise, Status } from '../../types'
import { DataContext, UserContext, WorkoutsContext } from '../../store'
import dayjs from 'dayjs'
import useTranslations from '../../hooks/useTranslations'
import { normalizeWorkout } from '../../helpers'
import { DATE_FORMAT_WEEKDAYS } from '../../helpers/constants'

interface Props {
  handleClose: () => void
}

const ExportDialog: React.FC<Props> = ({ handleClose }) => {
  const { locale } = useContext(UserContext)
  const { exercises } = useContext(DataContext)
  const { currentWorkout, generalExercises } = useContext(WorkoutsContext)
  const copyElem = useRef()
  const copyHiddenElem = useRef()
  const { t } = useTranslations()

  const [open, setOpen] = useState<boolean>(false)

  const getExerciseTitle = (exercise: Exercise) => {
    let title = ''

    exercises?.groups.forEach((group) => {
      const exerciseItem = group.exercises.find(
        (ex) => ex.id === exercise.exercise_id
      )

      if (exerciseItem) {
        title += exerciseItem?.title
      }
    })

    return title
  }

  // const copyJSON = () => {
  //   if (copyHiddenElem.current) {
  //     const r = document.createRange()
  //     r.selectNode(copyHiddenElem.current)
  //     window.getSelection()?.removeAllRanges()
  //     window.getSelection()?.addRange(r)
  //     document.execCommand('copy')
  //     window.getSelection()?.removeAllRanges()
  //   }
  // }

  const copyText = () => {
    if (copyElem.current) {
      const r = document.createRange()
      r.selectNode(copyElem.current)
      window.getSelection()?.removeAllRanges()
      window.getSelection()?.addRange(r)
      document.execCommand('copy')
      window.getSelection()?.removeAllRanges()
      setOpen(true)
    }
  }

  const normalizedData = useMemo(
    () =>
      currentWorkout &&
      exercises &&
      normalizeWorkout(currentWorkout, exercises, t),
    [currentWorkout, exercises]
  )

  if (!currentWorkout) {
    return null
  }

  const getExerciseComments = (ex: Exercise) =>
    ex.sets.reduce((res: string[], set) => {
      if (set.results?.comment) {
        res.push(set.results.comment)
      }

      return res
    }, [])

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Export</DialogTitle>
      <DialogContent
        ref={copyHiddenElem}
        style={{
          padding: 0,
          overflow: 'hidden',
          maxHeight: 1,
        }}
      >
        <pre>{JSON.stringify(currentWorkout, null, 2)}</pre>
      </DialogContent>
      <DialogContent ref={copyElem}>
        <div id="alert-dialog-description">
          <Box
            className="flex-row"
            sx={{
              alignItems: 'flex-start',
              mt: -3,
            }}
          >
            <Box>
              <b>
                {normalizedData && normalizedData.groups.length < 4
                  ? normalizedData.groups.join('/')
                  : 'Fullbody'}
              </b>
              <Box sx={{ textTransform: 'uppercase', fontSize: 13 }}>
                {dayjs(currentWorkout.startTime)
                  .locale(locale)
                  .format(DATE_FORMAT_WEEKDAYS)}
              </Box>
            </Box>
          </Box>

          <br />
          {generalExercises?.map((ex, indx, list) => {
            const isSuperset = Boolean(
              list[indx + 1] &&
                ex.superset === list[indx + 1].id &&
                list[indx + 1].superset === ex.id
            )
            const isSupersetPair = Boolean(
              list[indx - 1] &&
                ex.superset === list[indx - 1].id &&
                list[indx - 1].superset === ex.id
            )

            if (isSupersetPair) {
              return null
            }

            return (
              <div key={ex.id}>
                {indx > 0 && (
                  <div style={{ fontSize: '8px' }}>
                    <br />
                  </div>
                )}
                <div>
                  {indx + 1}) {getExerciseTitle(ex)} {ex.superset && ' + '}
                  {isSuperset && getExerciseTitle(list[indx + 1])}
                </div>
                <div style={{ fontSize: '90%', marginLeft: '12px' }}>
                  {(ex.superset
                    ? [
                        ex,
                        currentWorkout.exercises.find(
                          (el) => el.id === ex.superset
                        ),
                      ]
                    : [ex]
                  ).map((ex, ssIdx) => {
                    if (!ex) {
                      return null
                    }

                    return ex.status === Status.completed
                      ? ex.sets
                          .filter((_, idx, list) => {
                            return ex.sets.every((item) =>
                              typeof item.results?.weight === 'number'
                                ? item.results?.weight ===
                                  list[0].results?.weight
                                : typeof item.results?.reps === 'number' &&
                                  item.results?.reps === list[0].results?.reps
                            )
                              ? idx === 0
                              : true
                          })
                          .map((set, idx, list) => {
                            return set.results ? (
                              set.results?.reps ? (
                                <Fragment key={idx}>
                                  <span
                                    style={{
                                      marginRight: ex.superset ? 0 : '5px',
                                    }}
                                  >
                                    {ex.superset &&
                                      ssIdx > 0 &&
                                      idx === 0 &&
                                      ' | '}
                                    {/*{list.length > 1 && (*/}
                                    {/*  <span style={{ color: '#ffffff88' }}>*/}
                                    {/*    {idx + 1} -{' '}*/}
                                    {/*  </span>*/}
                                    {/*)}*/}
                                    {set.results?.weight &&
                                      `${set.results?.weight}кг`}

                                    {set.results?.reps && !set.results?.weight
                                      ? `${set.results?.reps}`
                                      : set.results?.reps !== set.data?.reps &&
                                        set.results?.weight
                                      ? ` (x${set.results?.reps})`
                                      : ''}

                                    {idx < list.length - 1 ? ', ' : ''}
                                  </span>
                                  {/*{isSuperset && ssIdx === 0 && ' | '}*/}
                                </Fragment>
                              ) : (
                                <span key={idx}>
                                  {set.results?.length}
                                  {ex.exercise_id.includes('core')
                                    ? 'сек'
                                    : 'мин'}
                                  {set.results?.speed ? 'x' : ''}
                                  {set.results?.speed}{' '}
                                </span>
                              )
                            ) : (
                              ''
                            )
                          })
                      : '–'
                  })}
                  {getExerciseComments(ex).length > 0 && (
                    <div>
                      <i>
                        {'( '}
                        {getExerciseComments(ex).map((el, idx) => (
                          <React.Fragment key={idx}>
                            {idx > 0 && ', '}
                            {el}
                          </React.Fragment>
                        ))}
                        {' )'}
                      </i>
                    </div>
                  )}
                </div>
              </div>
            )
          })}

          {currentWorkout.comment && (
            <>
              <br />
              <i>{currentWorkout.comment}</i>
            </>
          )}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          autoHideDuration={3000}
          message="Text copied to clipboard"
        />
      </DialogContent>
      <DialogActions
        sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          <Button
            onClick={copyText}
            color="primary"
            startIcon={<ContentCopyIcon />}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Copy Text
          </Button>
          {/*<Button*/}
          {/*  onClick={copyJSON}*/}
          {/*  color="primary"*/}
          {/*  startIcon={<ContentCopyIcon />}*/}
          {/*  variant="outlined"*/}
          {/*>*/}
          {/*  JSON*/}
          {/*</Button>*/}
        </div>

        <Button onClick={handleClose} color="inherit" variant="outlined">
          {t('app-button-dialog-close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExportDialog
