import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import TimerIcon from '@mui/icons-material/Timer'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FavoriteIcon from '@mui/icons-material/Favorite'
import BarChartIcon from '@mui/icons-material/BarChart'

import useTranslations from '../../hooks/useTranslations'
import TimeDiff from '../TimeDiff'
import routes from '../../helpers/routes'
import { UserContext } from '../../store'
import useClients from '../../hooks/useClients'

const WorkoutStats: React.FC = () => {
  const { user } = useContext(UserContext)
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { client } = useClients()

  const statsData = (client || user)?.statsData

  if (!statsData) {
    return <Typography variant="body2">No stats</Typography>
  }

  const { completionRate } = statsData

  return (
    <Box className="user-stats">
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        {t('page-title-stats')}:
      </Typography>
      <Box className="flex-row">
        {completionRate ? (
          <Box sx={{ width: '100%' }}>
            <Box className="user-stats-item">
              <EmojiEventsIcon
                sx={{ width: 16, height: 16, opacity: 0.8 }}
                color="warning"
              />
              <Box className="user-stats-item-title">
                {t('app-stats-completion-rate')}:
              </Box>
              <b>{completionRate.completed}</b>/{completionRate.all}
              {/*(*/}
              {/*{Math.round(*/}
              {/*  (completionRate.completed / completionRate.all) * 100*/}
              {/*)}*/}
              {/*%)*/}
            </Box>
            {statsData?.time && statsData?.time.length > 0 && (
              <Box className="user-stats-item">
                <AccessTimeIcon
                  sx={{ width: 16, height: 16, opacity: 0.8 }}
                  color="success"
                />
                <Box className="user-stats-item-title">
                  {t('app-stats-total-time')}:
                </Box>
                <b>
                  <TimeDiff
                    timeDifference={dayjs.duration(
                      statsData.time.reduce(
                        (acc: number, val: number) => acc + val,
                        0
                      )
                    )}
                  />
                </b>
              </Box>
            )}
            {statsData?.time && statsData?.time.length > 0 && (
              <Box className="user-stats-item">
                <TimerIcon
                  sx={{ width: 16, height: 16, opacity: 0.8 }}
                  color="success"
                />
                <Box className="user-stats-item-title">
                  {t('app-stats-average-time')}:
                </Box>
                <b>
                  <TimeDiff
                    timeDifference={dayjs.duration(
                      statsData.time.reduce(
                        (acc: number, val: number) => acc + val,
                        0
                      ) / statsData.time.length
                    )}
                  />
                </b>
              </Box>
            )}
            {statsData?.cardio && (
              <Box
                className="user-stats-item"
                sx={{ alignItems: 'flex-start !important' }}
              >
                <FavoriteIcon
                  sx={{ width: 16, height: 16, opacity: 0.8, mt: '2px' }}
                  color="error"
                />
                <Box className="user-stats-item-title">
                  {t('group-title-cardio')}:
                </Box>
                <Box>
                  {Object.keys(statsData.cardio).map((key, idx, list) => {
                    return (
                      <Box key={key}>
                        {t(`exercise-title-${key}`)}
                        {' – '}
                        <b>{statsData.cardio?.[key]}</b> (
                        {Math.round(
                          (Number(statsData.cardio?.[key]) /
                            Number(
                              list.reduce(
                                (acc: number, key: string) =>
                                  acc + Number(statsData.cardio?.[key]),
                                0
                              )
                            )) *
                            100
                        )}
                        %)
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )}

            <Button
              startIcon={<BarChartIcon />}
              size="small"
              sx={{ fontSize: 12, textTransform: 'none', padding: 0 }}
              onClick={() =>
                navigate(clientId ? routes.clientStats(clientId) : routes.stats)
              }
            >
              {t('page-title-exercises')}
            </Button>
          </Box>
        ) : (
          <Box>No data</Box>
        )}
      </Box>
    </Box>
  )
}

export default WorkoutStats
