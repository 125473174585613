import React, { useContext, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import useTranslations from '../../../hooks/useTranslations'
import { UserContext } from '../../../store'
import { LANGUAGES_FULL } from '../../../helpers/constants'
import EditSettingsDialog from '../../Dialogs/EditSettingsDialog'

import '../../../styles/profile.scss'

const ProfileSettings: React.FC = () => {
  const { user, locale } = useContext(UserContext)
  const { t } = useTranslations()

  const [edit, setEdit] = useState<boolean>(false)

  if (!user) {
    return null
  }

  return (
    <Box className="profile-section">
      <Box className="profile-section-title">
        <Typography variant="h6" sx={{ mb: 2, lineHeight: 1.3 }}>
          {t('profile-settings')}
        </Typography>
        <IconButton
          className="profile-section-edit-button"
          onClick={() => {
            setEdit(true)
          }}
        >
          <EditIcon sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>

      <Box className="profile-section-wrapper">
        <Box className="profile-section-item">
          <span>{t('profile-autostart')}:</span>
          <span>
            {user?.settings?.exercises?.autoStart
              ? t('profile-yes')
              : t('profile-no')}
          </span>
        </Box>
        <Box className="profile-section-item">
          <span>{t('profile-rest-delay')}:</span>
          <span>
            {user?.settings?.timer} {t('units-sec')}.
          </span>
        </Box>
        <Box className="profile-section-item">
          <span>{t('profile-language')}:</span>
          <span>{LANGUAGES_FULL[locale]}</span>
        </Box>
      </Box>

      {edit && (
        <EditSettingsDialog
          onClose={() => {
            setEdit(false)
          }}
        />
      )}
    </Box>
  )
}

export default ProfileSettings
