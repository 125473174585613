import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Divider, Grid, Typography } from '@mui/material'
import FitnessCenter from '@mui/icons-material/FitnessCenter'
import ChecklistIcon from '@mui/icons-material/Checklist'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

import routes from '../../helpers/routes'
import useTranslations from '../../hooks/useTranslations'
import { UserContext } from '../../store'
import PageWrapper from '../../containers/PageWrapper'
import { isTrainer } from '../../helpers'

const Home: React.FC = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const { t } = useTranslations()

  return (
    <PageWrapper className="page home-page">
      <Grid container spacing={2}>
        {isTrainer(user?.roles) && (
          <>
            <Grid item xs={12}>
              <Card
                className="home-button"
                sx={{ borderRadius: 4 }}
                onClick={() => navigate(routes.clients)}
              >
                <PeopleAltIcon sx={{ width: 48, height: 48 }} />
                <Typography variant="h5">{t('page-title-clients')}</Typography>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                className="home-button"
                sx={{ borderRadius: 4 }}
                onClick={() => navigate(routes.exercises)}
              >
                <FitnessCenter sx={{ width: 48, height: 48 }} />
                <Typography variant="h5">
                  {t('page-title-exercises')}
                </Typography>
              </Card>
            </Grid>
          </>
        )}
        <Divider sx={{ ml: 2, mt: 2, flex: 1 }} />
        <Grid item xs={12}>
          <Card
            className="home-button"
            sx={{ borderRadius: 4 }}
            onClick={() => navigate(routes.workouts)}
          >
            <ChecklistIcon sx={{ width: 48, height: 48 }} />
            <Typography variant="h5">{t('page-title-workouts')}</Typography>
          </Card>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Card*/}
        {/*    className="home-button"*/}
        {/*    sx={{ borderRadius: 4, opacity: 0.4 }}*/}
        {/*    // onClick={() => navigate(routes.stats)}*/}
        {/*  >*/}
        {/*    <LeaderboardIcon sx={{ width: 48, height: 48 }} />*/}
        {/*    <Typography variant="h5">{t('page-title-stats')}</Typography>*/}
        {/*  </Card>*/}
        {/*</Grid>*/}
      </Grid>
    </PageWrapper>
  )
}

export default Home
