import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'

import { freeWeights, machineWeights, repValues } from '../../helpers/constants'
import { Set, SetData } from '../../types'
import useTranslations from '../../hooks/useTranslations'
import useWeights from '../../hooks/useWeights'

interface Props {
  handleCloseClick: () => void
  handleSaveClick: (data: SetData) => void
  open: boolean
  idx: number
  set: Set
  exerciseId: string
}

const SetConfirmDialog: React.FC<Props> = ({
  open,
  idx,
  handleCloseClick,
  handleSaveClick,
  set,
  exerciseId,
}) => {
  const [result, setResult] = useState<SetData>({
    ...(set.results || set.data),
  })
  const { t } = useTranslations()
  const { weights } = useWeights({ exerciseId })

  const handleChange = ({
    type,
    value,
  }: {
    type: string
    value: string | number
  }) => {
    setResult((prevState) => ({ ...prevState, [type]: Number(value) }))
  }

  const handleCommentChange = (comment: string) => {
    setResult((prevState) => ({ ...prevState, comment }))
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        {t('app-set-complete-dialog-title')} #{idx + 1}
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {typeof set.data.reps === 'number' && (
            <FormControl sx={{ mt: 1, width: '47%' }}>
              <InputLabel>{t('app-workout-data-reps')}</InputLabel>
              <Select
                size="small"
                value={result.reps}
                onChange={(e) => {
                  handleChange({ type: 'reps', value: e.target.value })
                }}
                input={<OutlinedInput label={t('app-workout-data-reps')} />}
              >
                {repValues.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {typeof set.data.weight === 'number' && (
            <FormControl sx={{ mt: 1, width: '47%' }}>
              <InputLabel>{t('app-workout-data-weight')}</InputLabel>
              <Select
                size="small"
                value={result.weight}
                onChange={(e) => {
                  handleChange({ type: 'weight', value: e.target.value })
                }}
                input={<OutlinedInput label={t('app-workout-data-weight')} />}
              >
                {weights.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {typeof set.data.length === 'number' && (
            <FormControl sx={{ mt: 1, width: '47%' }}>
              <InputLabel>
                {set.id.includes('core')
                  ? t('app-workout-data-sec')
                  : t('app-workout-data-min')}
              </InputLabel>
              <Select
                size="small"
                value={result.length}
                onChange={(e) => {
                  handleChange({ type: 'length', value: e.target.value })
                }}
                input={
                  <OutlinedInput
                    label={
                      set.id.includes('core')
                        ? t('app-workout-data-sec')
                        : t('app-workout-data-min')
                    }
                  />
                }
              >
                {(set.id.includes('core') ? machineWeights : freeWeights).map(
                  (el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
          {typeof set.data.speed === 'number' && (
            <FormControl sx={{ mt: 1, width: '47%' }}>
              <InputLabel>{t('app-workout-data-speed')}</InputLabel>
              <Select
                size="small"
                value={result.speed}
                onChange={(e) => {
                  handleChange({ type: 'speed', value: e.target.value })
                }}
                input={<OutlinedInput label={t('app-workout-data-speed')} />}
              >
                {repValues.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            sx={{ mt: 3 }}
            fullWidth={true}
            multiline={true}
            value={result.comment}
            label={t('app-workout-comment')}
            onChange={(e) => {
              handleCommentChange(e.target.value)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 0 }}>
        <Button color="inherit" onClick={handleCloseClick}>
          {t('workout-edit-buttons-cancel')}
        </Button>
        <Button
          onClick={() => handleSaveClick(result)}
          color="primary"
          disableElevation={true}
          autoFocus={true}
          variant="contained"
        >
          {t('workout-edit-buttons-save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SetConfirmDialog
