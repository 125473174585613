import React, { useContext } from 'react'
import { Box, Divider } from '@mui/material'

import { UserContext } from '../../store'
import '../../styles/profile.scss'

import PageWrapper from '../../containers/PageWrapper'

import UserWeightChart from '../../components/UserWeightChart'
import WorkoutStats from '../../components/WorkoutStats'
import CurrentWeekWorkouts from '../../components/CurrentWeekWorkouts'

const ClientHome: React.FC = () => {
  const { user } = useContext(UserContext)

  if (!user) {
    return null
  }

  return (
    <PageWrapper className="page user-profile-page">
      <CurrentWeekWorkouts />

      <Divider sx={{ mb: 3, mx: -2 }} />

      <Box sx={{ mb: 3 }}>
        <WorkoutStats />
      </Box>

      <Divider sx={{ mb: 3, mx: -2 }} />

      <Box sx={{ mb: 3 }}>
        <UserWeightChart user={user} />
      </Box>
    </PageWrapper>
  )
}

export default ClientHome
