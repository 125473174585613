import React, { FC, useContext, useState } from 'react'
import { Dialog, DialogContent, IconButton, Box } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import CloseIcon from '@mui/icons-material/Close'
import WeightsChart from '../../WeightsChart'
import { UserContext } from '../../../store'
import { StatsWeightData } from '../../../types'

const StatsButton: FC<{
  id: string
  exerciseData: StatsWeightData
  sx?: { [key: string]: string | number }
}> = ({ id, exerciseData, sx }) => {
  const { user } = useContext(UserContext)
  const [open, setOpen] = useState<boolean>(false)

  const data = user?.statsData?.weights?.[id]

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!data) {
    return null
  }

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <BarChartIcon sx={sx} color="primary" />
      </IconButton>

      <Dialog open={open} fullWidth={true} onClose={handleClose}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 2,
            color: (theme) => theme.palette.grey[300],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ overflow: 'hidden' }}>
          <Box sx={{ mx: -1 }}>
            <WeightsChart
              id={id}
              data={data.sort((a, b) => (a.date < b.date ? -1 : 1))}
              extraData={[exerciseData]}
              isSingle={true}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default StatsButton
