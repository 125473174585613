import React from 'react'
import { Box, Typography } from '@mui/material'
import { LineChart } from '@mui/x-charts'
import dayjs from 'dayjs'
import useTranslations from '../../hooks/useTranslations'
import { UserData } from '../../types'

const UserWeightChart: React.FC<{ user: UserData }> = ({ user }) => {
  const { t } = useTranslations()

  const data = user.weightHistory?.sort((a, b) => (a.date < b.date ? -1 : 1))

  return (
    <Box sx={{ mx: -2, px: 2, overflow: 'hidden' }}>
      <Typography variant="subtitle2">
        {t('profile-weight')}: <b>{data?.[data.length - 1].value}</b>{' '}
        {t('units-kg')}
      </Typography>
      <Box sx={{ height: 200, m: -3 }}>
        <LineChart
          xAxis={[
            {
              data: data?.map((el) => dayjs(el.date).format('DD.MM')) || [],
              scaleType: 'point',
            },
          ]}
          series={[
            {
              data: data?.map((el) => el.value) || [],
              type: 'line',
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default UserWeightChart
