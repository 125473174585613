import { useCallback, useContext, useEffect } from 'react'
import { DataContext, WorkoutsContext } from '../store'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import routes from '../helpers/routes'

const usePathToWorkout = () => {
  const {
    isAppReady,
    fetchWorkoutsArchive,
    fetchWorkouts,
    workouts,
    workoutsArchive,
    fetchClientWorkouts,
  } = useContext(DataContext)
  const { currentWorkout, setCurrentWorkout } = useContext(WorkoutsContext)
  const { pathname } = useLocation()
  const { clientId, workoutId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!pathname.includes('/view') && !pathname.includes('/edit')) {
      if (
        (pathname.includes('/archive/') ||
          pathname.includes(routes.workouts)) &&
        !pathname.includes(routes.clients)
      ) {
        fetchWorkoutsArchive && fetchWorkoutsArchive()
      }
      if (
        pathname.includes(routes.workouts) &&
        !pathname.includes(routes.clients)
      ) {
        fetchWorkouts && fetchWorkouts()
      }
    }
  }, [pathname])

  useEffect(() => {
    if (
      pathname.includes('/archive/') &&
      !pathname.includes(routes.clients) &&
      !workoutsArchive
    ) {
      fetchWorkoutsArchive && fetchWorkoutsArchive()
    }
  }, [pathname, workoutsArchive])

  useEffect(() => {
    if (
      workoutId &&
      isAppReady &&
      workouts &&
      !pathname.includes('/archive/')
    ) {
      if (!currentWorkout) {
        if (workouts?.some((el) => el.uuid === workoutId)) {
          setCurrentWorkout && setCurrentWorkout(workoutId)
        } else {
          navigate(clientId ? routes.clientWorkouts(clientId) : routes.workouts)
        }
      }
    }
  }, [workoutId, isAppReady, clientId, workouts, pathname, currentWorkout])

  useEffect(() => {
    if (
      workoutId &&
      isAppReady &&
      workoutsArchive &&
      pathname.includes('/archive/')
    ) {
      if (workoutsArchive?.some((el) => el.uuid === workoutId)) {
        setCurrentWorkout && setCurrentWorkout(workoutId)
      } else {
        navigate(clientId ? routes.clientWorkouts(clientId) : routes.workouts)
      }
    }
  }, [workoutId, clientId, isAppReady, workoutsArchive, pathname])

  const getWorkouts = useCallback(async () => {
    if (fetchClientWorkouts && clientId) {
      await fetchClientWorkouts(clientId)
    }
  }, [clientId])

  useEffect(() => {
    if (clientId && !workouts) {
      getWorkouts()
    }

    if (clientId && workoutId && workouts) {
      setCurrentWorkout && setCurrentWorkout(workoutId)
    }
  }, [clientId, workoutId, workouts])
}

export default usePathToWorkout
