import firebase from './firebase'
import { DictionaryData } from '../types'

const translationsAPI = () => {
  const db = firebase.firestore()
  const dictionariesCollection = db.collection('dictionaries')

  const getTranslations = async (dictionaryName: string) => {
    console.log(`API: getTranslations ${dictionaryName}`)

    try {
      const doc = await dictionariesCollection.doc(dictionaryName).get()

      return doc.data()
    } catch (err) {
      throw err
    }
  }

  const updateTranslation = async (
    dictionaryName: string,
    data: DictionaryData
  ) => {
    console.log('API: updateTranslation')

    try {
      const dictionaryRef = dictionariesCollection.doc(dictionaryName)

      return dictionaryRef
        .update(data)
        .then(() => {
          console.log(`Translation added/updated in '${dictionaryName}'`)
        })
        .catch((error) => {
          console.error('Error adding/updating translations:', error)
        })
    } catch (err) {
      throw err
    }
  }

  const deleteTranslation = async (
    dictionaryName: string,
    translationKey: string
  ) => {
    console.log('API: deleteTranslation')

    try {
      const dictionaryRef = dictionariesCollection.doc(dictionaryName)
      const document = await dictionaryRef.get()
      if (document.exists) {
        const data = document.data()

        if (data) {
          delete data[translationKey]

          await dictionaryRef.set(data)

          console.log(`Translation ${translationKey} deleted`)
        }
      } else {
        console.error(`Dictionary ${dictionaryName} not found`)
      }
    } catch (err) {
      throw err
    }
  }

  // updateTranslation('test', { 'new-key': { de: '22', en: '' } })
  // deleteTranslation('test', 'new-key')

  return {
    getTranslations,
    updateTranslation,
    deleteTranslation,
  }
}

export default translationsAPI
