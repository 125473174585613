import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../store'
import { dumbbellWeights, machineWeights } from '../helpers/constants'

const useWeights = ({
  exerciseId,
}: {
  exerciseId: string
}): { weights: number[] } => {
  const { exercises } = useContext(DataContext)
  const [weights, setWeights] = useState<number[]>([])

  useEffect(() => {
    if (exercises) {
      const step = exercises.groups.reduce((res, el) => {
        if (el.exercises.some((ex) => ex.id === exerciseId)) {
          if (el.weightStep) {
            res = el.weightStep
          }

          const currentEx = el.exercises.find((ex) => ex.id === exerciseId)
          if (currentEx && currentEx.weightStep) {
            res = currentEx.weightStep
          }
        }

        return res
      }, 1)

      setWeights(step === 2 ? [0, ...dumbbellWeights] : machineWeights)
    }
  }, [exercises, exerciseId])

  return { weights }
}

export default useWeights
