import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import FitnessCenter from '@mui/icons-material/FitnessCenter'
import ChecklistIcon from '@mui/icons-material/Checklist'
import HistoryIcon from '@mui/icons-material/History'
import BarChartIcon from '@mui/icons-material/BarChart'
import CloseIcon from '@mui/icons-material/Close'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import DashboardIcon from '@mui/icons-material/Dashboard'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

import useTranslations from '../../hooks/useTranslations'
import routes from '../../helpers/routes'

import '../../styles/app.scss'
import { UserContext } from '../../store'
import { isTrainer } from '../../helpers'

const AppMenu: React.FC = () => {
  const { user, isClient } = useContext(UserContext)
  const navigate = useNavigate()
  const { t } = useTranslations()

  const [isMenu, setIsMenu] = useState<boolean>(false)

  return (
    <Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: -1 }}
        onClick={() => {
          setIsMenu(true)
        }}
      >
        {isMenu ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      <Drawer
        anchor="left"
        open={isMenu}
        onClose={() => {
          setIsMenu(false)
        }}
      >
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
          onClick={() => {
            setIsMenu(false)
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(routes.home)
                }}
              >
                <ListItemIcon>
                  <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary={t('page-title-home')} />
              </ListItemButton>
            </ListItem>
            <Divider />
            {isTrainer(user?.roles) && (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(routes.clients)
                    }}
                  >
                    <ListItemIcon>{<PeopleAltIcon />}</ListItemIcon>
                    <ListItemText primary={t('page-title-clients')} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(routes.exercises)
                    }}
                  >
                    <ListItemIcon>{<FitnessCenter />}</ListItemIcon>
                    <ListItemText primary={t('page-title-exercises')} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(routes.workouts)
                }}
              >
                <ListItemIcon>{<ChecklistIcon />}</ListItemIcon>
                <ListItemText primary={t('page-title-workouts')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(routes.workoutsArchive)
                }}
              >
                <ListItemIcon>{<HistoryIcon />}</ListItemIcon>
                <ListItemText primary={t('page-title-archive')} />
              </ListItemButton>
            </ListItem>
            {isClient && (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(routes.stats)
                    }}
                  >
                    <ListItemIcon>{<BarChartIcon />}</ListItemIcon>
                    <ListItemText primary={t('page-title-stats')} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(routes.exercises)
                    }}
                  >
                    <ListItemIcon>{<FitnessCenter />}</ListItemIcon>
                    <ListItemText primary={t('page-title-exercises')} />
                  </ListItemButton>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}

export default AppMenu
