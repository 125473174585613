import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'

import AddExerciseDialog from '../../components/Dialogs/AddExerciseDialog'
import useExercises from '../../hooks/useExercises'
import { UserContext, DataContext, TranslationsContext } from '../../store'
import useTranslations from '../../hooks/useTranslations'
import { EditTranslationData, NewExerciseData } from '../../types'
import { useNavigate } from 'react-router-dom'
import routes from '../../helpers/routes'
import EditTranslationDialog from '../../components/Dialogs/EditTranslationDialog'
import PageWrapper from '../../containers/PageWrapper'
import { isAdmin, isTrainer } from '../../helpers'

const ExercisesPage: React.FC = () => {
  const { user, locale } = useContext(UserContext)
  const { addNewExercise } = useContext(DataContext)
  const { updateTranslation } = useContext(TranslationsContext)
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { options, value, setValue } = useExercises({})
  const [isNewExercise, setIsNewExercise] = useState<boolean>(false)
  const [editTranslation, setEditTranslation] =
    useState<EditTranslationData | null>(null)

  const handleAddExerciseConfirm = async (data: NewExerciseData) => {
    if (data && addNewExercise) {
      const exercisesId = await addNewExercise(data)
      setIsNewExercise(false)
      setValue(value)
      if (locale) {
        setEditTranslation({
          dictionary: 'exercises',
          key: `exercise-title-${exercisesId}`,
          values: { [locale]: data.title },
        })
      }
    }
  }

  const handleItemClick = (id: string) => {
    navigate(routes.exerciseItem(id))
  }

  const handleEditTranslationConfirm = async (props: EditTranslationData) => {
    if (updateTranslation) {
      const { dictionary, key, values } = props
      await updateTranslation(dictionary, { [key]: values })
      setEditTranslation(null)
    }
  }

  return (
    <PageWrapper className="page exercises-page">
      <TextField
        fullWidth={true}
        size="small"
        autoFocus={true}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {(isTrainer(user?.roles) || isAdmin(user?.roles)) && (
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          sx={{ position: 'fixed', right: 20, bottom: 20 }}
          onClick={() => {
            setIsNewExercise(true)
          }}
        >
          <AddIcon />
        </Fab>
      )}

      {editTranslation && (
        <EditTranslationDialog
          handleClose={() => {
            setEditTranslation(null)
          }}
          handleConfirm={handleEditTranslationConfirm}
          data={editTranslation}
        />
      )}

      <List className="exercises-list" subheader={<li />}>
        {options.length > 0 ? (
          options.map((group) => (
            <li key={`group-${group.id}`}>
              <ul>
                <ListSubheader
                  className="exercises-list-subheader"
                  sx={{ backgroundColor: 'background.paper' }}
                >
                  {t(`group-title-${group.id}`)}
                </ListSubheader>
                {group.exercises?.map((item) => (
                  <ListItem
                    key={`exercise-${group.id}-${item.id}`}
                    onClick={() => {
                      // onChange({ title: item.title, id: item.id })
                    }}
                  >
                    {user?.uuid && item.id.includes(user.uuid) ? (
                      <ListItemText
                        onClick={() => handleItemClick(item.id)}
                        primary={item.title}
                      />
                    ) : (
                      <ListItemText
                        onClick={() => handleItemClick(item.id)}
                        primary={t(`exercise-title-${item.id}`)}
                      />
                    )}

                    {item.link && (
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <IconButton size="small" color="primary">
                          <SmartDisplayIcon color="primary" />
                        </IconButton>
                      </a>
                    )}
                  </ListItem>
                ))}
              </ul>
            </li>
          ))
        ) : (
          <Box sx={{ px: 2, py: 1.5 }}>
            <Typography>{t('find-exercise-dialog-no-results')}</Typography>
            {(isTrainer(user?.roles) || isAdmin(user?.roles)) && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{ mt: 2 }}
                onClick={() => {
                  setIsNewExercise(true)
                }}
              >
                {t('add-exercise-button')}
              </Button>
            )}
          </Box>
        )}
      </List>

      {isNewExercise && (
        <AddExerciseDialog
          handleClose={() => {
            setIsNewExercise(false)
          }}
          handleConfirm={handleAddExerciseConfirm}
          defaultValue={value}
        />
      )}
    </PageWrapper>
  )
}

export default ExercisesPage
