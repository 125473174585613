import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'

import {
  Box,
  Tabs,
  Tab,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'

import useTranslations from '../../hooks/useTranslations'
import { TranslationsContext } from '../../store'
import EditTranslationDialog from '../../components/Dialogs/EditTranslationDialog'
import { DictionaryData, EditTranslationData } from '../../types'
import PageWrapper from '../../containers/PageWrapper'
import { LANGUAGES, UA_KEY } from '../../helpers/constants'

const Dictionary = () => {
  const { isLoaded, updateTranslation } = useContext(TranslationsContext)
  const { dictionaryExercises, dictionaryGeneral } = useTranslations()
  const [editTranslation, setEditTranslation] =
    useState<EditTranslationData | null>(null)
  const [searchValue, setSearchValue] = useState<string>()
  const [tab, setTab] = useState<number>(0)
  const [data, setData] = useState<DictionaryData>(dictionaryExercises)

  const handleEditTranslationConfirm = async (props: EditTranslationData) => {
    if (updateTranslation) {
      const { dictionary, key, values } = props
      await updateTranslation(dictionary, { [key]: values })
      setEditTranslation(null)
    }
  }

  const dictionaries = [
    {
      name: 'general',
      data: dictionaryGeneral,
    },
    {
      name: 'exercises',
      data: dictionaryExercises,
    },
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab((oldValue) => {
      if (oldValue !== newValue) {
        setSearchValue('')
      }

      return newValue
    })
  }

  useEffect(() => {
    setData(dictionaries[tab].data)
  }, [tab])

  useEffect(() => {
    const data = dictionaries[tab].data
    if (searchValue) {
      setData(
        Object.keys(data).reduce((acc: DictionaryData, key) => {
          const newData = data[key]
          if (
            JSON.stringify(newData)
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            key.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            acc[key] = newData
          }

          return acc
        }, {})
      )
    } else {
      setData(data)
    }
  }, [searchValue])

  return (
    <PageWrapper>
      {!isLoaded && <CircularProgress />}

      <Fab
        color="primary"
        aria-label="add"
        size="small"
        sx={{ position: 'fixed', right: 20, bottom: 20 }}
        onClick={() => {
          setEditTranslation({
            dictionary: dictionaries[tab].name,
            key: '',
            values: LANGUAGES.reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
          })
        }}
      >
        <AddIcon />
      </Fab>

      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth={true}
          size="small"
          autoFocus={true}
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          {dictionaries.map(({ name }) => {
            return <Tab label={name} key={name} />
          })}
        </Tabs>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 500,
                  fontSize: '110%',
                }}
              >
                Key
              </TableCell>
              {LANGUAGES.map((key: any) => (
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: '110%',
                    textTransform: 'uppercase',
                  }}
                  key={key}
                >
                  {key === 'uk' ? UA_KEY : key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).map((key) => (
              <TableRow
                hover={true}
                key={key}
                onClick={() => {
                  setEditTranslation({
                    dictionary: dictionaries[tab].name,
                    key,
                    values: data[key],
                  })
                }}
                className={classNames({
                  disabledRow: isLoaded,
                })}
              >
                <TableCell>{key}</TableCell>
                {LANGUAGES.map((lang: any) => {
                  const value = data[key][lang] || ''

                  return (
                    <TableCell key={lang}>
                      <div>
                        <div>{value}</div>
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editTranslation && (
        <EditTranslationDialog
          handleClose={() => {
            setEditTranslation(null)
          }}
          handleConfirm={handleEditTranslationConfirm}
          data={editTranslation}
          dictionaries={dictionaries.map(({ name }) => name)}
        />
      )}
    </PageWrapper>
  )
}

export default Dictionary
