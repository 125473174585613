import React, { useContext, useState } from 'react'

import ExerciseItem from './ExerciseItem'
import ExerciseSuperset from './ExerciseSuperset'

import { WorkoutsContext } from '../../../store'
import '../../../styles/module.scss'
import EditExerciseItem from '../EditExercises/EditExerciseItem'

const ExerciseList: React.FC = () => {
  const { currentWorkout, warmupExercises, generalExercises, cardioExercises } =
    useContext(WorkoutsContext)
  const [openEx, setOpenEx] = useState<string>('')
  const [startNext, setStartNext] = useState<string>('')

  const handleStartNext = (id: string) => {
    const exIndex =
      (currentWorkout?.exercises.findIndex((ex) => ex.id === id) || 0) + 1

    if (id && exIndex && currentWorkout?.exercises[exIndex]) {
      setStartNext(currentWorkout?.exercises[exIndex].id)
    }
  }

  return (
    <div className="exercise-list">
      {warmupExercises?.map((exercise, idx) => (
        <div className="exercise-list-warmup" key={idx}>
          <ExerciseItem
            isOpen={openEx === currentWorkout?.exercises[0].id}
            setIsOpen={setOpenEx}
            exercise={exercise}
            isWarmUp={true}
            setStartNext={handleStartNext}
          />
        </div>
      ))}
      {generalExercises?.map((item, idx, list) => {
        const isSuperset = Boolean(
          list[idx + 1] &&
            item.superset === list[idx + 1].id &&
            list[idx + 1].superset === item.id
        )
        const isSupersetPair = Boolean(
          list[idx - 1] &&
            item.superset === list[idx - 1].id &&
            list[idx - 1].superset === item.id
        )

        if (isSuperset) {
          return (
            <ExerciseSuperset
              isOpen={openEx === item.id}
              setIsOpen={setOpenEx}
              key={`${item.id}${idx}`}
              data={[item, list[idx + 1]]}
              setStartNext={handleStartNext}
              isStartNext={startNext === item.id}
            />
          )
        }

        if (isSupersetPair) {
          return null
        }

        return (
          <ExerciseItem
            isOpen={openEx === item.id}
            setIsOpen={setOpenEx}
            key={`${item.id}${idx}`}
            exercise={item}
            setStartNext={handleStartNext}
            isStartNext={startNext === item.id}
          />
        )
      })}
      <div className="exercise-list-cardio">
        {cardioExercises?.map((item, idx) => {
          return (
            <ExerciseItem
              isOpen={openEx === item.id}
              setIsOpen={setOpenEx}
              key={`${item.id}${idx}`}
              exercise={item}
              isStartNext={startNext === item.id}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ExerciseList
