import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import useTranslations from '../../hooks/useTranslations'
import { UserContext } from '../../store'
import Rating from '../Rating'

interface Result {
  comment?: string
  rating?: number
}

interface Props {
  handleCloseClick: () => void
  handleSaveClick: (result: Result) => void
}

const WorkoutCompleteDialog: React.FC<Props> = ({
  handleCloseClick,
  handleSaveClick,
}) => {
  const { user } = useContext(UserContext)
  const [result, setResult] = useState<Result>({})
  const { t } = useTranslations()

  const handleCommentChange = (comment: string) => {
    setResult((prevState) => ({ ...prevState, comment }))
  }
  const handleRatingChange = (rating: number) => {
    setResult((prevState) => ({ ...prevState, rating }))
  }

  return (
    <Dialog open={true} maxWidth="xs" fullWidth={true}>
      <DialogTitle>{t('app-workout-complete-dialog-title')}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography sx={{ mb: 2 }}>
            {t('workout-final-dialog-how').replace(
              '%name%',
              String(user?.name.split(' ')[0])
            )}
          </Typography>

          <Rating
            defaultValue={3}
            onChange={(value) => {
              value && handleRatingChange(value)
            }}
          />

          <TextField
            sx={{ mt: 3 }}
            fullWidth={true}
            multiline={true}
            label={t('app-workout-comment')}
            onChange={(e) => {
              handleCommentChange(e.target.value)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 0 }}>
        {/*<Button color="inherit" onClick={handleCloseClick}>*/}
        {/*  {t('app-button-cancel-workout')}*/}
        {/*</Button>*/}
        <Button
          onClick={() => handleSaveClick(result)}
          color="primary"
          disableElevation={true}
          autoFocus={true}
          variant="contained"
        >
          {t('workout-edit-buttons-save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WorkoutCompleteDialog
