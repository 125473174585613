import React, { useContext } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'

import EditExerciseItem from './EditExerciseItem'
import { WorkoutsContext } from '../../../store'
import '../../../styles/module.scss'
import { Exercise, Status } from '../../../types'
import {
  CARDIO_DATA,
  EXERCISE_DATA,
  WARMUP_DATA,
} from '../../../helpers/constants'
import useTranslations from '../../../hooks/useTranslations'
import cn from 'classnames'

interface AddButtonProps {
  onClick: () => void
  disabled: boolean
  styles?: { [key: string]: number | string }
  label: string
}

const AddExerciseButtons: React.FC<AddButtonProps> = ({
  onClick,
  disabled,
  styles,
  label,
}) => {
  return (
    <Button
      size="small"
      sx={{
        display: 'flex',
        // mx: 'auto',
        mt: -0.5,
        zIndex: 1,
        fontSize: '80%',
        ...styles,
      }}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      startIcon={<AddCircleIcon />}
    >
      {label}
    </Button>
  )
}

const EditExerciseList: React.FC = () => {
  const {
    currentWorkout,
    warmupExercises,
    generalExercises,
    cardioExercises,
    updateEditWorkout,
  } = useContext(WorkoutsContext)
  const { t } = useTranslations()

  const isCompleted = currentWorkout?.status === Status.completed

  const handleAddExerciseClick = (idx: number = 0, type?: string) => {
    if (currentWorkout) {
      const newExercise: Exercise = {
        status: 0,
        exercise_id: '',
        id: '',
        sets: [
          {
            id: '',
            data:
              type === 'warmup'
                ? WARMUP_DATA
                : type === 'cardio'
                ? CARDIO_DATA
                : EXERCISE_DATA,
          },
        ],
        type,
      }

      currentWorkout.exercises.splice(idx, 0, newExercise)

      updateEditWorkout && updateEditWorkout(currentWorkout)
    }
  }

  const linkExercises = (ex1Id: string, ex2Id: string) => {
    if (currentWorkout && updateEditWorkout) {
      const ex1 = currentWorkout.exercises.find((el) => el.id === ex1Id)
      const ex2 = currentWorkout.exercises.find((el) => el.id === ex2Id)

      if (ex1 && ex2) {
        ex1.superset = ex2Id
        ex2.superset = ex1Id
      }

      updateEditWorkout(currentWorkout)
    }
  }

  const unlinkExercises = (ex1Id: string, ex2Id: string) => {
    if (currentWorkout && updateEditWorkout) {
      const ex1 = currentWorkout.exercises.find((el) => el.id === ex1Id)
      const ex2 = currentWorkout.exercises.find((el) => el.id === ex2Id)

      if (ex1 && ex2) {
        ex1.superset = undefined
        ex2.superset = undefined
      }

      updateEditWorkout(currentWorkout)
    }
  }

  const isNew = currentWorkout?.exercises.some((el) => !el.id)

  return (
    <div className="exercise-list">
      <div className="exercise-list-warmup">
        {warmupExercises && warmupExercises?.length > 0 && (
          <EditExerciseItem exercise={warmupExercises[0]} />
        )}
        {warmupExercises?.length === 0 && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            sx={{ my: 1, mb: 2 }}
            onClick={() => {
              handleAddExerciseClick(0, 'warmup')
            }}
            disabled={isNew}
          >
            {t('add-warmup-button')}
          </Button>
        )}
      </div>

      {!isCompleted &&
        (generalExercises && generalExercises.length > 0 ? (
          <Box className="exercise-edit-buttons-wrapper">
            <AddExerciseButtons
              disabled={Boolean(isNew)}
              label={t('add-exercise-button')}
              onClick={() => {
                handleAddExerciseClick(
                  warmupExercises && warmupExercises?.length > 0 ? 1 : 0
                )
              }}
              styles={{
                mt: -1,
              }}
            />
          </Box>
        ) : (
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            sx={{ my: 1 }}
            onClick={() => {
              handleAddExerciseClick(1)
            }}
            disabled={isNew}
          >
            {t('add-exercise-button')}
          </Button>
        ))}

      {generalExercises?.map((item, idx, list) => {
        const isSuperset = Boolean(
          list[idx + 1] &&
            item.superset === list[idx + 1].id &&
            list[idx + 1].superset === item.id
        )
        const isSupersetPair = Boolean(
          list[idx - 1] &&
            item.superset === list[idx - 1].id &&
            list[idx - 1].superset === item.id
        )

        return (
          <div
            className={cn('exercise-edit-list-item', {
              'exercise-superset': isSuperset,
              'exercise-supersetPair': isSupersetPair,
            })}
            key={`${item.id}${idx}`}
          >
            <EditExerciseItem exercise={item} />

            {!isCompleted && (
              <Box className="exercise-edit-buttons-wrapper">
                {!isSuperset && (
                  <AddExerciseButtons
                    disabled={Boolean(isNew)}
                    label={t('add-exercise-button')}
                    onClick={() => {
                      handleAddExerciseClick(
                        (currentWorkout?.exercises.findIndex(
                          (ex) => ex.id === item.id
                        ) || 0) + 1
                      )
                    }}
                  />
                )}
                {idx < list.length - 1 &&
                  (isSuperset ? (
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        left: '50%',
                        ml: -2,
                        top: -8,
                        zIndex: 2,
                      }}
                      onClick={() => {
                        unlinkExercises(item.id, list[idx + 1].id)
                      }}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  ) : (
                    !isSuperset &&
                    !isSupersetPair && (
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          left: '50%',
                          ml: -2,
                          top: -4,
                          zIndex: 2,
                        }}
                        onClick={() => {
                          linkExercises(item.id, list[idx + 1].id)
                        }}
                      >
                        <LinkIcon />
                      </IconButton>
                    )
                  ))}
              </Box>
            )}
          </div>
        )
      })}

      <div className="exercise-list-cardio">
        {cardioExercises && !cardioExercises.length && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            sx={{ my: 1 }}
            onClick={() => {
              handleAddExerciseClick(1, 'cardio')
            }}
            disabled={isNew}
          >
            {t('add-cardio-button')}
          </Button>
        )}

        {currentWorkout?.exercises &&
          cardioExercises
            ?.filter(
              (el) => !isCompleted || (isCompleted && el.sets[0].results)
            )
            .map((item, idx) => {
              return (
                <div
                  className="exercise-edit-list-item"
                  key={`${item.id}${idx}`}
                >
                  <EditExerciseItem exercise={item} />

                  <Box className="exercise-edit-buttons-wrapper">
                    <AddExerciseButtons
                      disabled={Boolean(isNew)}
                      label={t('add-exercise-button')}
                      onClick={() => {
                        handleAddExerciseClick(
                          currentWorkout?.exercises.findIndex(
                            (ex) => ex.id === item.id
                          ) + 1,
                          'cardio'
                        )
                      }}
                    />
                  </Box>
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default EditExerciseList
