import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ExerciseData, UserData, Workout } from '../types'
import { userAPI } from '../api'

import { DEFAULT_LOCALE, ROLES, TIMER } from '../helpers/constants'
import { AuthContext } from './AuthProvider'
import { isClient, isTrainer } from '../helpers'

interface ContextType {
  clients?: UserData[]
  getUserByEmail?: (email: string) => void
  getClients?: () => void
  handleTelegramResponse?: (res: any) => void
  isLoading: boolean
  isClient?: boolean
  role: string
  locale: string
  setRole?: (role: string) => void
  updateUser?: (data: UserData) => void
  updateUserStats?: (
    userId: string,
    data: {
      exercises: ExerciseData
      workouts?: Workout[]
      workoutsArchive?: Workout[]
    }
  ) => void
  user?: UserData | null
  userId: string
  activeClient?: string
  setActiveClient?: (id?: string) => void
  createNewClient?: (
    id: string,
    data: { [key: string]: string }
  ) => Promise<any>
}

const initialValues: ContextType = {
  isLoading: true,
  role: '',
  userId: '',
  locale: DEFAULT_LOCALE,
}

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { authData, isAuthInProgress, handleAuthError } =
    useContext(AuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(initialValues.isLoading)
  const [user, setUser] = useState<UserData | null>(null)
  const [clients, setClients] = useState<UserData[]>()
  const [activeClient, setActiveClient] = useState<string>()
  const [role, setRole] = useState<string>(initialValues.role)

  const updateNewUser = async (data: any) => {
    if (authData) {
      const { locale, ...userData } = authData
      await userAPI().updateUser(data.uuid, {
        ...data,
        ...userData,
        draft: false,
        role,
        settings: {
          timer: TIMER,
          locale: locale ?? DEFAULT_LOCALE,
          workouts: {
            delay: TIMER,
          },
          exercises: {
            autoStart: false,
          },
        },
      })
    }
  }

  const getUserByEmail = async (email: string) => {
    try {
      const user = await userAPI().getUser(email)

      return user
    } catch (err) {
      console.error(err)
    }
  }

  const getCurrentUser = async (email: string) => {
    setIsLoading(true)
    try {
      const user = await getUserByEmail(email)

      if (user && user.draft) {
        await updateNewUser(user)
        await getCurrentUser(email)
      } else if (user) {
        setUser(user)
      } else {
        alert(`Error: email ${email} is not found`)
        handleAuthError && handleAuthError()
      }

      return user
    } catch (err) {
      console.error(err)
    } finally {
      setRole('')
      setIsLoading(false)
    }
  }

  const getClients = useCallback(async () => {
    if (user?.uuid) {
      const clients = await userAPI().getClients(user.uuid)

      if (setClients.length >= 0) {
        setClients(clients)
      }
    }
  }, [user?.uuid])

  const createNewClient = async (
    trainerId: string,
    data: { [key: string]: string }
  ) => {
    return await userAPI().createUser({
      name: `${data.first_name} ${data.last_name}`,
      email: data.email,
      isNew: true,
      deleted: false,
      roles: [ROLES.CLIENT],
      trainerId,
    })
  }

  useEffect(() => {
    if (isTrainer(user?.roles)) {
      getClients()
    }
  }, [user?.role])

  const updateUser = async (data: UserData) => {
    try {
      if (data.uuid) {
        await userAPI().updateUser(
          data.uuid,
          data.isNew ? { ...data, isNew: false } : data
        )
      }

      setUser({ ...data, isNew: false })
    } catch (err) {
      console.error(err)
    }
  }

  const updateUserStats = useCallback(
    async (
      userId: string,
      data: {
        exercises: ExerciseData
        workouts?: Workout[]
        workoutsArchive?: Workout[]
      }
    ) => {
      try {
        const userData = activeClient
          ? clients?.find((c) => c.uuid === activeClient)
          : user

        if (userData) {
          await userAPI().updateUserStats(userData, data)
        }
      } catch (err) {
        console.error(err)
      }
    },
    [user, clients, activeClient]
  )

  useEffect(() => {
    if (!isAuthInProgress && !authData) {
      setIsLoading(false)
    }

    if (authData?.email) {
      getCurrentUser(authData.email) // 'zadnepryansky@gmail.com' ||
    }
  }, [isAuthInProgress, authData])

  return (
    <UserContext.Provider
      value={{
        clients,
        getClients,
        getUserByEmail,
        isLoading,
        role,
        setRole,
        updateUser,
        user,
        locale: user?.settings?.locale || DEFAULT_LOCALE,
        isClient: isClient(user?.roles),
        userId: user?.uuid ?? '',
        activeClient,
        setActiveClient,
        createNewClient,
        updateUserStats,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const UserContext = createContext<ContextType>(initialValues)
