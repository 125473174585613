import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'

import routes from '../../helpers/routes'
import { useContext, useState } from 'react'
import { DataContext, UserContext, WorkoutsContext } from '../../store'
import WarningDialog from '../Dialogs/WarningDialog'
import useTranslations from '../../hooks/useTranslations'
import { isAdmin } from '../../helpers'

const ITEM_HEIGHT = 48

interface Props {
  uuid: string
  isArchive: boolean
  isCompleted?: boolean
}

const CardMenu: React.FC<Props> = ({ uuid, isArchive, isCompleted }) => {
  const { activeClient, user } = useContext(UserContext)
  const { deleteWorkout, archiveWorkout, unarchiveWorkout, duplicateWorkout } =
    useContext(DataContext)
  const { setCurrentWorkout } = useContext(WorkoutsContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslations()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ mt: '2px', ml: 1 }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {(!activeClient || (activeClient && !isCompleted)) && (
          <MenuItem
            onClick={() => {
              navigate(
                isArchive
                  ? activeClient
                    ? routes.clientWorkoutArchiveEdit(activeClient, uuid)
                    : routes.archiveEdit(uuid)
                  : activeClient
                  ? routes.clientWorkoutEdit(activeClient, uuid)
                  : routes.workoutEdit(uuid)
              )
            }}
            disableRipple
          >
            <EditIcon sx={{ mr: 1 }} />
            {/*{t(isArchive ? 'card-menu-edit-results' : 'card-menu-edit')}*/}
            {t('card-menu-edit')}
          </MenuItem>
        )}
        {isAdmin(user?.roles) &&
          (isArchive ? (
            <MenuItem
              onClick={async () => {
                if (unarchiveWorkout) {
                  await unarchiveWorkout(uuid)
                  // navigate(routes.workouts)
                }
              }}
              disableRipple
            >
              <UnarchiveIcon sx={{ mr: 1 }} />
              Unarchive
            </MenuItem>
          ) : (
            <MenuItem
              onClick={async () => {
                if (archiveWorkout) {
                  await archiveWorkout(uuid)
                  // navigate(routes.workoutsArchive)
                }
              }}
              disableRipple
            >
              <ArchiveIcon sx={{ mr: 1 }} />
              Archive
            </MenuItem>
          ))}
        <MenuItem
          onClick={() => {
            setIsDuplicate(true)
          }}
          disableRipple
        >
          <CopyAllIcon sx={{ mr: 1 }} />
          {t('card-menu-duplicate')}
        </MenuItem>
        {!isArchive && (
          <MenuItem
            onClick={() => {
              setShowDeleteDialog(true)
            }}
            disableRipple
          >
            <DeleteForeverIcon sx={{ mr: 1 }} />
            {t('card-menu-delete')}
          </MenuItem>
        )}
      </Menu>

      {isDuplicate && (
        <WarningDialog
          handleClose={() => {
            setIsDuplicate(false)
          }}
          handleConfirm={async () => {
            if (duplicateWorkout && setCurrentWorkout) {
              const id = await duplicateWorkout(uuid, isArchive)

              if (id) {
                setCurrentWorkout()
                navigate(
                  activeClient
                    ? routes.clientWorkoutEdit(activeClient, id)
                    : routes.workoutEdit(id)
                )
              }
            }
          }}
          title={t('app-duplicate-workout-dialog-title')}
          // note={t('app-duplicate-workout-dialog-text')}
        />
      )}

      {showDeleteDialog && (
        <WarningDialog
          handleClose={() => {
            setShowDeleteDialog(false)
          }}
          handleConfirm={async () => {
            if (deleteWorkout) {
              await deleteWorkout(uuid, isArchive)

              setShowDeleteDialog(false)

              if (pathname.includes('/view/')) {
                navigate(routes.workouts)
              }
            } else {
              setShowDeleteDialog(false)
            }
          }}
          title={t('app-delete-workout-dialog-title')}
          note={t('app-delete-workout-dialog-text')}
          isCancel={true}
        />
      )}
    </Box>
  )
}

export default CardMenu
