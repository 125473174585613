import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

import useTranslations from '../../hooks/useTranslations'
import ExercisesList from '../ExercisesList'

type Option = { title: string; id: string }

interface Props {
  handleClose: () => void
  onChange: (data: Option) => void
  currentTitle?: string
  exId: string
  isCardio: boolean
  group?: string
}

const SearchExerciseDialog: React.FC<Props> = ({
  currentTitle,
  handleClose,
  onChange,
  exId,
  isCardio,
  group,
}) => {
  const { t } = useTranslations()

  return (
    <Dialog open={true} fullScreen={true}>
      <DialogTitle>{t('find-exercise-dialog-title')}</DialogTitle>
      <DialogContent className="exercises-search-dialog-content">
        {currentTitle && !group && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            Помилкова назва: <i>{currentTitle}</i>
          </Typography>
        )}
        <ExercisesList
          onChange={onChange}
          exId={exId}
          isCardio={isCardio}
          group={group}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit">
          {t('app-button-cancel-exercise')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SearchExerciseDialog
