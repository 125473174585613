import React from 'react'
import MuiAvatar from '@mui/material/Avatar'

import { UserData } from '../../types'

const Avatar: React.FC<{
  user: UserData
  size?: number
  sx?: { [key: string]: number | string }
}> = ({ user, size = 72, sx }) => {
  return (
    <MuiAvatar
      alt={user.name}
      src={user.picture}
      sx={{ width: size, height: size, ...sx }}
      imgProps={{
        referrerPolicy: 'no-referrer',
      }}
    />
  )
}

export default Avatar
