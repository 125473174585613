import React, { useContext, useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'

import BarChartIcon from '@mui/icons-material/BarChart'
import EditIcon from '@mui/icons-material/Edit'

import useTranslations from '../../../hooks/useTranslations'
import { UserContext } from '../../../store'
import EditBodyDialog from '../../Dialogs/EditBodyDialog'

import '../../../styles/profile.scss'
import CloseIcon from '@mui/icons-material/Close'
import UserWeightChart from '../../UserWeightChart'

const ProfileBody: React.FC = () => {
  const { user } = useContext(UserContext)
  const { t } = useTranslations()

  const [edit, setEdit] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (user?.isNew) {
      setEdit(true)
    }
  }, [user])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!user) {
    return null
  }

  return (
    <Box className="profile-section">
      <Box className="profile-section-title">
        <Typography variant="h6" sx={{ mb: 2, lineHeight: 1.3 }}>
          {t('profile-physique')}
        </Typography>
        {!edit && (
          <IconButton
            className="profile-section-edit-button"
            onClick={() => {
              setEdit(true)
            }}
          >
            <EditIcon sx={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </Box>

      <Box className="profile-section-wrapper">
        <Box className="profile-section-item">
          <span>{t('profile-age')}:</span>
          <span>{user.age || '—'}</span>
        </Box>
        <Box className="profile-section-item">
          <span>{t('profile-height')}:</span>
          <span>
            {user.height || '—'} {user.height && t('units-cm').toLowerCase()}
          </span>
        </Box>
        <Box className="profile-section-item">
          <span>{t('profile-weight')}:</span>
          <Box className="flex-row">
            <IconButton
              size="small"
              sx={{ fontSize: 12, textTransform: 'none', p: 0, mr: 1 }}
              onClick={handleOpen}
              color="primary"
            >
              <BarChartIcon sx={{ width: 20, height: 20 }} />
            </IconButton>

            <Dialog open={open} fullWidth={true} onClose={handleClose}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  zIndex: 2,
                  color: (theme) => theme.palette.grey[300],
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent sx={{ overflow: 'hidden' }}>
                <Box sx={{ ml: -1, mr: -3 }}>
                  <UserWeightChart user={user} />
                </Box>
              </DialogContent>
            </Dialog>

            <span>
              {user?.weightHistory?.sort((a, b) =>
                a.date > b.date ? -1 : 1
              )[0].value || '—'}{' '}
              {user.weightHistory && t('units-kg')}
            </span>
          </Box>
        </Box>

        {edit && (
          <EditBodyDialog
            onClose={() => {
              setEdit(false)
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default ProfileBody
