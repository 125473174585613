import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DoneIcon from '@mui/icons-material/Done'
import SkipNextIcon from '@mui/icons-material/SkipNext'

import { Box, Button, Card, IconButton } from '@mui/material'
import cn from 'classnames'

import { DataContext, UserContext, WorkoutsContext } from '../../../store'
import SetList from '../Sets/SetList'

import '../../../styles/module.scss'

import { Exercise, Status, Workout } from '../../../types'
import WarningDialog from '../../Dialogs/WarningDialog'
import Countdown from '../../Countdown'
import useTranslations from '../../../hooks/useTranslations'
import { deepClone, findLastIndexWithProperty } from '../../../helpers'
import VideoButton from '../../Buttons/VideoButton'
import StatsButton from '../../Buttons/StatsButton'
import { findMaxResult } from '../../../helpers/stats'
import EditIcon from '@mui/icons-material/Edit'
import SearchExerciseDialog from '../../Dialogs/SearchExerciseDialog'
import { WARMUP_DATA } from '../../../helpers/constants'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddIcon from '@mui/icons-material/Add'

interface Props {
  exercise: Exercise
  isWarmUp?: boolean
  isOpen: boolean
  setIsOpen: (key: string) => void
  setStartNext?: (key: string) => void
  isStartNext?: boolean
}

const ExerciseItem: React.FC<Props> = ({
  exercise,
  isWarmUp,
  isOpen,
  setIsOpen,
  setStartNext,
  isStartNext,
}) => {
  const { isClient } = useContext(UserContext)
  const { exercises, findExerciseGroup, isLoading } = useContext(DataContext)
  const {
    updateExerciseProgress,
    currentWorkout,
    updateEditWorkout,
    isEditMode,
    isViewMode,
  } = useContext(WorkoutsContext)
  const { t } = useTranslations()
  const elementRef = useRef<null | HTMLDivElement>(null)

  const [isCancelWarning, setIsCancelWarning] = useState<boolean>(false)
  const [isStopWarning, setIsStopWarning] = useState<boolean>(false)
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false)

  const isInProgress = exercise.status === Status.inProgress
  const isCompleted = exercise.status === Status.completed
  const isWorkoutCompleted = currentWorkout?.status === Status.completed

  useEffect(() => {
    if (currentWorkout) {
      const lastCompletedIdx =
        findLastIndexWithProperty(currentWorkout.exercises, 'status', 2) + 1

      if (
        currentWorkout.status === Status.inProgress &&
        currentWorkout.exercises[lastCompletedIdx]?.exercise_id &&
        !currentWorkout.exercises.some((ex) => ex.status === Status.inProgress)
      ) {
        setIsOpen(currentWorkout.exercises[lastCompletedIdx].id)
      }

      if (currentWorkout.status === Status.inProgress) {
        window.scrollTo()
      }
    }
  }, [currentWorkout])

  useEffect(() => {
    if (
      currentWorkout?.status === Status.inProgress &&
      isInProgress &&
      elementRef.current
    ) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [currentWorkout, isInProgress, elementRef])

  useEffect(() => {
    if (
      isStartNext &&
      setStartNext &&
      updateExerciseProgress &&
      exercise.status === Status.new
    ) {
      setStartNext('')
      updateExerciseProgress(exercise.id, Status.inProgress)
    }
  }, [isStartNext])

  const groupTitle = t(
    `group-title-${findExerciseGroup(exercise.exercise_id)?.group_name_id}`
  )

  const exerciseTitle = () => {
    let title = ''
    let link = ''

    exercises?.groups.forEach((group) => {
      const exerciseItem = group.exercises.find(
        (ex) => ex.id === exercise.exercise_id
      )

      if (exerciseItem?.title) {
        title += t(`exercise-title-${exerciseItem.id}`) || exerciseItem.title
      }
      if (exerciseItem?.link) {
        link += exerciseItem.link
      }
    })

    const handleTitleChange = (data: { title: string; id: string }) => {
      if (currentWorkout) {
        const newData = deepClone(currentWorkout)

        newData?.exercises?.forEach((item, index) => {
          if (item.exercise_id === exercise.exercise_id) {
            if (item.id) {
              item.id = item.id.replace(item.exercise_id, data.id)
              item.sets.forEach((el, idx) => {
                el.id = el.id.replace(item.exercise_id, data.id)
              })
            } else {
              const newId = `${data.id}_${Date.now()}_${index + 1}`
              item.id = newId

              item.sets.forEach((el, idx) => {
                const exerciseGroup = findExerciseGroup(data.id)
                el.id = `${newId}_${idx + 1}`
                el.data =
                  exercise.type === 'warmup'
                    ? WARMUP_DATA
                    : {
                        ...(exerciseGroup?.exercises.find(
                          (e) => e.id === data.id
                        )?.initialData ?? exerciseGroup?.initialData),
                      }
              })
            }

            item.exercise_id = data.id
            item.title = data.title
          }
        })

        updateEditWorkout && updateEditWorkout(newData as Workout)
      }
      setIsEditTitle(false)
    }

    const handleSearchCancel = () => {
      setIsEditTitle(false)
    }

    return (
      <>
        {isEditTitle && (
          <SearchExerciseDialog
            currentTitle={exercise.title}
            handleClose={handleSearchCancel}
            onChange={handleTitleChange}
            isCardio={exercise.exercise_id.includes('cardio')}
            exId={exercise.exercise_id}
            group={findExerciseGroup(exercise.exercise_id)?.id}
          />
        )}

        <span onClick={handleViewClick}>
          {exercise.status === Status.completed &&
            currentWorkout?.status === Status.inProgress && (
              <DoneIcon
                color="success"
                sx={{ width: 20, height: 20, my: -0.5, mr: 0.5 }}
              />
            )}

          {title}

          {!isOpen && currentWorkout?.status !== Status.completed && (
            <span className="exercise-title-data">
              [{exercise.sets.length > 1 ? exercise.sets.length + ':' : ''}
              {exercise.sets.some((e) => e?.data?.reps)
                ? `${(exercise.sets[1] ?? exercise.sets[0]).data.reps}${
                    typeof (exercise.sets[1] ?? exercise.sets[0]).data
                      .weight === 'number'
                      ? 'x' + (exercise.sets[1] ?? exercise.sets[0]).data.weight
                      : ''
                  }`
                : `${exercise.sets[0].data.length}${
                    exercise.sets[0].data.speed
                      ? 'x' + exercise.sets[0].data.speed
                      : ''
                  }`}
              ]
            </span>
          )}

          <small>{isWarmUp ? t('app-workouts-warmup') : groupTitle}</small>
        </span>

        {(isOpen || isInProgress || isWorkoutCompleted) && (
          <div
            className="exercise-title-links"
            style={{ right: isWorkoutCompleted ? 7 : isInProgress ? 76 : 42 }}
          >
            {!isEditMode &&
              !isInProgress &&
              !isCompleted &&
              currentWorkout?.status === Status.inProgress && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setIsEditTitle(true)
                  }}
                >
                  <EditIcon color="primary" sx={{ fontSize: 20 }} />
                </IconButton>
              )}
            {link && !isEditMode && <VideoButton link={link} />}
            <StatsButton
              id={exercise.exercise_id}
              exerciseData={{
                date: String(currentWorkout?.date),
                value: findMaxResult(exercise.sets, !isWorkoutCompleted),
              }}
            />
          </div>
        )}
      </>
    )
  }

  const handleViewClick = () => {
    if (!isWorkoutCompleted) {
      setIsOpen(isOpen ? '' : exercise.id)
    }
  }

  const handleExerciseStart = () => {
    updateExerciseProgress &&
      updateExerciseProgress(exercise.id, Status.inProgress)
  }

  const handleExerciseStop = async (isNext?: boolean) => {
    if (updateExerciseProgress) {
      await updateExerciseProgress(exercise.id, Status.completed)

      if (isNext && setStartNext) {
        setStartNext(exercise.id)
      }

      setIsStopWarning(false)
    }
  }

  const handleExerciseCancel = () => {
    updateExerciseProgress && updateExerciseProgress(exercise.id, Status.new)
    setIsCancelWarning(false)
  }

  const getExerciseComments = useMemo(
    () => () =>
      exercise.sets.reduce((res: string[], set) => {
        if (set.results?.comment) {
          res.push(set.results.comment)
        }

        return res
      }, []),
    [exercise]
  )

  const handleAddSetButton = () => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData?.exercises?.forEach((item) => {
        if (item.exercise_id === exercise.exercise_id) {
          item.sets.forEach((set, idx) => {
            set.id = `${exercise.id}_${idx + 1}`
          })

          item.sets.push({
            id: `${exercise.id}_${item.sets.length + 1}`,
            data: { ...item.sets[item.sets.length - 1].data },
          })
        }
      })

      updateEditWorkout && updateEditWorkout(newData as Workout)
    }
  }

  return (
    <Card
      ref={elementRef}
      className={cn('exercise-item', {
        'exercise-item-in-progress': isInProgress,
        'exercise-item-completed': isCompleted || isWorkoutCompleted,
        'exercise-item-completed-view': isWorkoutCompleted,
        'exercise-item-open': isOpen || isWorkoutCompleted,
        'exercise-item-superset': exercise.superset,
      })}
    >
      <div className="exercise-title">{exerciseTitle()}</div>
      <div className="exercise-buttons">
        {exercise.startTime && isInProgress && (
          <div className="exercise-countdown">
            <Countdown
              startTime={exercise.startTime}
              endTime={exercise.endTime}
            />
          </div>
        )}

        {exercise.status !== Status.inProgress && !isWorkoutCompleted && (
          <IconButton
            className="exercise-view-button"
            onClick={handleViewClick}
            size="small"
            disabled={isLoading}
          >
            {isOpen ? (
              <ExpandLessIcon sx={{ width: 32, height: 32 }} color="primary" />
            ) : (
              <ExpandMoreIcon sx={{ width: 32, height: 32 }} color="primary" />
            )}
          </IconButton>
        )}
      </div>
      {(isOpen || isWorkoutCompleted || isInProgress) && (
        <div className="exercise-data">
          {exercise.sets && (
            <SetList
              exerciseId={exercise.exercise_id}
              exId={exercise.id}
              id={exercise.id}
              setList={exercise.sets}
            />
          )}
          {isInProgress && (
            // !exercise.id.includes('cardio') &&
            <IconButton
              size="small"
              onClick={handleAddSetButton}
              sx={{ display: 'flex', ml: '-9px' }}
            >
              <AddIcon sx={{ width: 24, height: 24 }} />
            </IconButton>
          )}
        </div>
      )}

      {!isEditMode && exercise.comments && (
        <div className="exercise-item-comments">
          <b>{t('app-trainer')}</b>: "{exercise.comments}"
        </div>
      )}

      {!isEditMode &&
        (isInProgress || isOpen || isCompleted) &&
        getExerciseComments().length > 0 && (
          <div className="exercise-item-comments">
            <b>{t('app-workout-comment')}</b>: "
            {getExerciseComments().map((el, idx) => (
              <React.Fragment key={idx}>
                {idx > 0 && ', '}
                {el}
              </React.Fragment>
            ))}
            "
          </div>
        )}

      {isClient &&
        !isEditMode &&
        (!isViewMode || (isViewMode && isCompleted)) &&
        (isInProgress || isOpen) && (
          <div className="exercise-bottom-buttons">
            {isOpen &&
              currentWorkout?.status === Status.inProgress &&
              (exercise.status === Status.new || !exercise.status) &&
              !currentWorkout?.exercises.some(
                ({ status }) => status === Status.inProgress
              ) && (
                <Button
                  size="small"
                  fullWidth={true}
                  color="warning"
                  variant="contained"
                  sx={{ margin: '0 auto' }}
                  onClick={handleExerciseStart}
                  startIcon={<PlayArrowIcon />}
                  disabled={isLoading}
                >
                  {t('app-button-start-exercise')}
                </Button>
              )}
            {(isInProgress || (isCompleted && isOpen)) && (
              <>
                {!exercise.sets.some((set) => !set.results) && !isCompleted ? (
                  <IconButton
                    color="secondary"
                    size="small"
                    disabled={isLoading}
                    onClick={() => {
                      setIsCancelWarning(true)
                    }}
                  >
                    <CancelIcon sx={{ width: 20, height: 20 }} />
                  </IconButton>
                ) : (
                  <Button
                    className="exercise-cancel-button"
                    onClick={() => {
                      setIsCancelWarning(true)
                    }}
                    startIcon={<CancelIcon />}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={isLoading}
                  >
                    {t('app-button-cancel-exercise')}
                  </Button>
                )}

                {isCancelWarning && (
                  <WarningDialog
                    handleClose={() => {
                      setIsCancelWarning(false)
                    }}
                    handleConfirm={handleExerciseCancel}
                    title={t('app-cancel-exercise-dialog-title')}
                    note={t('app-cancel-exercise-dialog-text')}
                    isCancel={true}
                  />
                )}
              </>
            )}
            {isInProgress && (
              <Box>
                {exercise.sets.some((set) => set.results) && (
                  <Button
                    className="exercise-cancel-button"
                    onClick={() => {
                      !exercise.sets.some((s) => !s.results)
                        ? handleExerciseStop()
                        : setIsStopWarning(true)
                    }}
                    startIcon={<DoneIcon />}
                    variant="outlined"
                    color={
                      !exercise.sets.some((s) => !s.results)
                        ? 'inherit'
                        : 'primary'
                    }
                    size="small"
                    disabled={isLoading}
                  >
                    {t('app-button-finish-exercise')}
                  </Button>
                )}

                {isStopWarning && (
                  <WarningDialog
                    handleClose={() => {
                      setIsStopWarning(false)
                    }}
                    handleConfirm={handleExerciseStop}
                    title={t('app-finish-exercise-dialog-title')}
                    note={t('app-finish-exercise-dialog-text')}
                  />
                )}

                {setStartNext && !exercise.sets.some((set) => !set.results) && (
                  <Button
                    className="exercise-cancel-button"
                    onClick={() => {
                      !exercise.sets.some((s) => !s.results)
                        ? handleExerciseStop(true)
                        : setIsStopWarning(true)
                    }}
                    startIcon={<SkipNextIcon />}
                    variant="outlined"
                    color={
                      !exercise.sets.some((s) => !s.results)
                        ? 'inherit'
                        : 'primary'
                    }
                    size="small"
                    disabled={isLoading}
                    sx={{ ml: 2 }}
                  >
                    {t('app-button-finish-next')}
                  </Button>
                )}

                {isStopWarning && (
                  <WarningDialog
                    handleClose={() => {
                      setIsStopWarning(false)
                    }}
                    handleConfirm={() => {
                      handleExerciseStop()
                    }}
                    title={t('app-finish-exercise-dialog-title')}
                    note={t('app-finish-exercise-dialog-text')}
                  />
                )}
              </Box>
            )}
          </div>
        )}
    </Card>
  )
}

export default ExerciseItem
