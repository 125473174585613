import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import 'dayjs/locale/en-gb'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'
import 'dayjs/locale/de'

import './helpers/dayjsConfig'

import Root from './Root'
import { AuthProvider, UserProvider } from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <AuthProvider>
    <UserProvider>
      <Root />
    </UserProvider>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
