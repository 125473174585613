import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Fab } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

import { UserContext } from '../../store'
import usePathToSetData from '../../hooks/usePathToSetData'
import AddClientDialog from '../../components/Dialogs/AddClientDialog'
import routes from '../../helpers/routes'
import PageWrapper from '../../containers/PageWrapper'
import Avatar from '../../components/Avatar'
import useTranslations from '../../hooks/useTranslations'
import dayjs from 'dayjs'

const Clients = () => {
  const { clients } = useContext(UserContext)
  const navigate = useNavigate()
  const { t } = useTranslations()
  usePathToSetData()

  const [isNewClient, setIsNewClient] = useState<boolean>(false)

  if (!clients) {
    return null
  }

  return (
    <PageWrapper className="page clients-page">
      {clients.map((user) => (
        <Box
          key={user.uuid}
          className="clients-list-item"
          sx={{
            backgroundColor: ({ palette }) => palette.primary.dark,
          }}
          onClick={() => {
            navigate(routes.clientView(user.uuid))
          }}
        >
          <Avatar user={user} size={42} sx={{ mr: 2 }} />
          <Box className="flex-row" sx={{ flex: 1 }}>
            <Box>
              <Box sx={{ lineHeight: 1.2 }}>{user.name}</Box>{' '}
              <small>
                {user.statsData?.workouts?.lastCompleted?.date && (
                  <span>
                    {t('app-clients-last-workout')}:{' '}
                    {t('app-clients-last-workout-days-ago').replace(
                      '%d%',
                      String(
                        dayjs().diff(
                          user.statsData?.workouts?.lastCompleted?.date,
                          'd'
                        )
                      )
                    )}
                  </span>
                )}

                {/*{!user.statsData?.workouts?.lastCompleted?.date &&*/}
                {/*  user.statsData?.workouts?.upcoming?.groupTitles && (*/}
                {/*    <span>*/}
                {/*      Upcoming:{' '}*/}
                {/*      {user.statsData.workouts.upcoming.groupTitles*/}
                {/*        .map((k) => t(k))*/}
                {/*        .join('/')}*/}
                {/*      {user.statsData?.workouts?.upcoming?.date &&*/}
                {/*        `(${formatDateWithWeekdays(*/}
                {/*          user.statsData.workouts.upcoming.date*/}
                {/*        )})`}*/}
                {/*    </span>*/}
                {/*  )}*/}
              </small>
            </Box>

            {user?.workoutInProgress && (
              <EmojiEventsIcon
                color="inherit"
                className="clients-list-item-progress"
              />
            )}
          </Box>
        </Box>
      ))}

      <Fab
        color="primary"
        aria-label="add"
        size="small"
        sx={{ position: 'fixed', right: 20, bottom: 20 }}
        onClick={() => {
          setIsNewClient(true)
        }}
      >
        <PersonAddIcon />
      </Fab>

      {isNewClient && (
        <AddClientDialog
          handleClose={() => {
            setIsNewClient(false)
          }}
        />
      )}
    </PageWrapper>
  )
}

export default Clients
