import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress, Divider } from '@mui/material'

import { DataContext, UserContext } from '../../store'
import '../../styles/profile.scss'

import routes from '../../helpers/routes'
import PageWrapper from '../../containers/PageWrapper'
import WorkoutStats from '../../components/WorkoutStats'
import WeightChart from '../../components/UserWeightChart'
import CurrentWeekWorkouts from '../../components/CurrentWeekWorkouts'
import Avatar from '../../components/Avatar'
import useClients from '../../hooks/useClients'

const ClientProfile: React.FC = () => {
  const { clients, isLoading: isLoadingUserData } = useContext(UserContext)
  const { fetchClientWorkouts, isLoading } = useContext(DataContext)
  const { clientId } = useParams()
  const navigate = useNavigate()
  const { client } = useClients()

  useEffect(() => {
    if (clientId) {
      if (fetchClientWorkouts && clientId) {
        fetchClientWorkouts(clientId)
      }
    }
  }, [clientId])

  if (!isLoadingUserData && (!clients || clients.length <= 0)) {
    navigate(routes.home)
  }

  if (!client) {
    return null
  }

  if (isLoading) {
    return (
      <PageWrapper className="page user-profile-page">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper className="page user-profile-page">
      <Box
        className="flex-row"
        sx={{ mb: 3, fontSize: 24, justifyContent: 'flex-start' }}
      >
        {client.picture && <Avatar user={client} sx={{ mr: 2 }} />}
        {client.name}
      </Box>

      <Divider sx={{ mb: 2, mx: -2 }} />

      <CurrentWeekWorkouts />

      <Divider sx={{ my: 2, mx: -2 }} />

      <Box sx={{ mb: 3 }}>
        <WorkoutStats />
      </Box>

      <Divider sx={{ mb: 2, mx: -2 }} />

      <Box sx={{ mb: 3 }}>
        <WeightChart user={client} />
      </Box>
    </PageWrapper>
  )
}

export default ClientProfile
