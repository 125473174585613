import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import WeekTitle from '../WeekTitle'
import routes from '../../helpers/routes'
import AddWorkoutButton from '../Buttons/AddWorkoutButton'
import WorkoutList from '../WorkoutList'
import useWorkouts from '../../hooks/useWorkouts'
import { useNavigate, useParams } from 'react-router-dom'
import useTranslations from '../../hooks/useTranslations'

const CurrentWeekWorkouts: React.FC = () => {
  const { today, upcomingWorkouts } = useWorkouts()
  const navigate = useNavigate()
  const { t } = useTranslations()
  const { clientId } = useParams()

  const handleClick = () => {
    navigate(clientId ? routes.clientWorkouts(clientId) : routes.workouts)
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ mb: 2, fontSize: 18 }}
        onClick={handleClick}
      >
        <WeekTitle date={today} />
      </Typography>

      <Box sx={{ mb: 2 }}>
        {upcomingWorkouts && upcomingWorkouts.length > 0 ? (
          <Box className="client-workouts-list">
            {/*<Box className="flex-row">*/}
            {/*  <Typography variant="subtitle2" onClick={handleClick}>*/}
            {/*    {t('app-workouts-planned')}*/}
            {/*  </Typography>*/}
            {/*  <AddWorkoutButton />*/}
            {/*</Box>*/}
            <WorkoutList data={upcomingWorkouts} hideCompleted={true} />
          </Box>
        ) : (
          <Box className="flex-row">
            <Typography variant="subtitle2">
              {t('app-workouts-no-planned')}
            </Typography>
            <AddWorkoutButton />
          </Box>
        )}
      </Box>

      {/*{lastWorkouts && lastWorkouts.length > 0 && (*/}
      {/*  <Box sx={{ mb: 3 }} className="client-workouts-list">*/}
      {/*    <Box className="flex-row">*/}
      {/*      <Typography variant="subtitle2" onClick={handleClick}>*/}
      {/*        {t('app-workouts-completed')}:*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}

      {/*    {lastWorkouts && lastWorkouts.length > 0 && (*/}
      {/*      <WorkoutList data={lastWorkouts} />*/}
      {/*    )}*/}
      {/*  </Box>*/}
      {/*)}*/}

      {clientId && (
        <Button
          size="small"
          sx={{ p: 0, textTransform: 'none', mt: -1 }}
          onClick={() => {
            navigate(routes.clientWorkouts(clientId))
          }}
        >
          {t('page-title-workouts')}
        </Button>
      )}
    </>
  )
}

export default CurrentWeekWorkouts
