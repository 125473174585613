import { createContext, ReactNode, useEffect, useState } from 'react'

import {
  onAuthStateChanged,
  getAuth,
  signOut,
  sendEmailVerification,
} from '../api/firebase'

const auth = getAuth()

interface AuthUser {
  email: string | null
  name: string | null
  locale?: string | null
  picture: string | null
}

interface ContextType {
  authData?: AuthUser | null
  handleAuthError?: () => void
  signOutHandler?: () => void
  isAuthInProgress?: boolean
  isEmailVerification?: boolean
  isAuthorized?: boolean
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthInProgress, setIsAuthInProgress] = useState<boolean>(true)
  const [authData, setAuthData] = useState<AuthUser | null>(null)
  const [isEmailVerification, setIsEmailVerification] = useState<boolean>(false)

  const signOutHandler = () => {
    signOut(auth).then(() => {
      window.location.href = '/'
      window.location.reload()
    })
  }

  const handleAuthError = async () => {
    setAuthData(null)
    localStorage.removeItem('auth')
    signOutHandler()
  }

  useEffect(() => {
    const unRegistered = onAuthStateChanged(auth, async (user) => {
      if (user?.emailVerified) {
        localStorage.removeItem('verificationEmailSent')
        setAuthData({
          email: user.email,
          name: user.displayName,
          picture: user.photoURL,
        })
        console.log('Signed in')
      } else {
        if (user) {
          const isEmailSent = localStorage.getItem('verificationEmailSent')
          if (isEmailSent === 'true') {
            setIsEmailVerification(true)
          } else {
            await sendEmailVerification(user)
              .then((res) => {
                localStorage.setItem('verificationEmailSent', 'true')
                setIsEmailVerification(true)
              })
              .catch((err) => {
                console.log(err)
              })
          }
        }
      }
      setIsAuthInProgress(false)
    })

    return () => unRegistered()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authData,
        handleAuthError,
        signOutHandler,
        isAuthInProgress,
        isEmailVerification,
        isAuthorized: Boolean(authData?.email),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthContext = createContext<ContextType>({})
