import React, { ReactNode, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UserContext } from '../../store'

const PageWrapper: React.FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  const { setActiveClient } = useContext(UserContext)
  const { clientId } = useParams()

  useEffect(() => {
    if (setActiveClient) {
      if (clientId) {
        setActiveClient(clientId)
      } else {
        setActiveClient()
      }
    }
  }, [clientId, setActiveClient])

  return <div className={className}>{children}</div>
}

export default PageWrapper
