import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { DictionaryData } from '../types'
import { translationsAPI } from '../api'
import { DICTIONARIES } from '../helpers/constants'
import { UserContext } from './UserProvider'

interface ContextType {
  isLoaded: boolean
  dictionary: DictionaryData
  dictionaryGeneral: DictionaryData
  dictionaryExercises: DictionaryData
  updateTranslation?: (dictionary: string, data: DictionaryData) => Promise<any>
}

const initialValues: ContextType = {
  isLoaded: true,
  dictionary: {},
  dictionaryGeneral: {},
  dictionaryExercises: {},
}

export const TranslationsProvider = ({ children }: { children: ReactNode }) => {
  const { userId } = useContext(UserContext)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [dictionaryGeneral, setDictionaryGeneral] = useState<DictionaryData>({})
  const [dictionaryExercises, setDictionaryExercises] =
    useState<DictionaryData>({})

  const getDictionaryByName = async (name: string) => {
    const dictionary = await translationsAPI().getTranslations(name)

    if (dictionary) {
      if (name === 'general') {
        setDictionaryGeneral(dictionary)
      } else {
        setDictionaryExercises(dictionary)
      }
    }
  }

  const fetchTranslations = async () => {
    try {
      DICTIONARIES.map(getDictionaryByName)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoaded(true)
    }
  }

  const updateTranslation = async (
    dictionary: string,
    data: DictionaryData
  ) => {
    setIsLoaded(false)
    try {
      await translationsAPI().updateTranslation(dictionary, data)

      await getDictionaryByName(dictionary)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoaded(true)
    }
  }

  // const deleteTranslation = async (dictionary: string, key: string) => {
  //   setIsLoaded(true)
  //   try {
  //     await translationsAPI().deleteTranslation(dictionary, key)
  //
  //     await getDictionaryByName(dictionary)
  //   } catch (err) {
  //     console.error(err)
  //   } finally {
  //     setIsLoaded(false)
  //   }
  // }

  useEffect(() => {
    if (userId) {
      fetchTranslations()
    }
  }, [userId])

  return (
    <TranslationsContext.Provider
      value={{
        isLoaded,
        dictionary: { ...dictionaryGeneral, ...dictionaryExercises },
        dictionaryGeneral,
        dictionaryExercises,
        updateTranslation,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

export const TranslationsContext = createContext<ContextType>(initialValues)
