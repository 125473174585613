import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiRating, { IconContainerProps } from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'

const StyledRating = styled(MuiRating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}))

export const customIcons: {
  [index: string]: {
    icon: React.ReactElement
    label: string
  }
} = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        color="error"
        sx={{ color: (theme) => theme.palette.error.dark }}
      />
    ),
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon
        color="success"
        sx={{ color: (theme) => theme.palette.success.dark }}
      />
    ),
    label: 'Satisfied',
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon
        color="success"
        sx={{ color: (theme) => theme.palette.success.light }}
      />
    ),
    label: 'Very Satisfied',
  },
}

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

const Rating: React.FC<{
  defaultValue?: number
  value?: number
  readOnly?: boolean
  sx?: { [key: string]: number | string }
  size?: 'small' | 'medium' | 'large'
  onChange?: (value: number) => void
}> = ({
  defaultValue,
  value,
  onChange,
  readOnly = false,
  size = 'medium',
  sx,
}) => {
  return (
    <StyledRating
      defaultValue={defaultValue}
      value={value}
      readOnly={readOnly}
      size={size}
      sx={sx}
      onChange={(_, value) => {
        value && onChange && onChange(value)
      }}
      name="highlight-selected-only"
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value].label}
      highlightSelectedOnly
    />
  )
}

export default Rating
