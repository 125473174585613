import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useTranslations from '../../hooks/useTranslations'
import { Box, InputAdornment, TextField } from '@mui/material'
import { UserData } from '../../types'
import { UserContext } from '../../store'
import dayjs from 'dayjs'
import { DATE_FORMAT_DATA } from '../../helpers/constants'

interface Props {
  onClose: () => void
}

const EditSettingsDialog: React.FC<Props> = ({ onClose }) => {
  const { user, updateUser } = useContext(UserContext)
  const { t } = useTranslations()

  const [values, setValues] = useState<{
    age: number
    height: number
    weight: number
  }>({
    age: user?.age || 0,
    height: user?.height || 0,
    weight: user?.weightHistory
      ? user?.weightHistory?.sort((a, b) => (a.date > b.date ? -1 : 1))[0].value
      : 0,
  })

  useEffect(() => {
    if (user) {
      setValues({
        age: user.age || 0,
        height: user.height || 0,
        weight: user?.weightHistory
          ? user?.weightHistory?.sort((a, b) => (a.date > b.date ? -1 : 1))[0]
              .value
          : 0,
      })
    }
  }, [user])

  const handleChange = (prop: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({ ...prevState, [prop]: Number(e.target.value) }))
  }

  const handleSaveClick = () => {
    if (user && updateUser) {
      const { age, height, weight } = values

      const newUserData: UserData = {
        ...user,
        age,
        height,
        weightHistory: [
          ...(user.weightHistory || []),
          { date: dayjs().format(DATE_FORMAT_DATA), value: weight },
        ],
      }

      updateUser(newUserData)
    }

    onClose()
  }

  const handleCancelClick = () => {
    onClose()
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('profile-physique')}</DialogTitle>
      <DialogContent>
        <Box>
          <Box className="profile-section-item">
            <span>{t('profile-age')}:</span>
            <TextField
              value={String(values.age)}
              size="small"
              sx={{ maxWidth: 100 }}
              type="number"
              onChange={handleChange('age')}
            />
          </Box>
          <Box className="profile-section-item">
            <span>{t('profile-height')}:</span>
            <TextField
              value={String(values.height)}
              size="small"
              sx={{ maxWidth: 100 }}
              type="number"
              onChange={handleChange('height')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t('units-cm')}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="profile-section-item">
            <span>{t('profile-weight')}:</span>
            <TextField
              value={String(values.weight)}
              size="small"
              sx={{ maxWidth: 100 }}
              type="number"
              onChange={handleChange('weight')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t('units-kg')}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button color="inherit" onClick={handleCancelClick}>
          {t('workout-edit-buttons-cancel')}
        </Button>
        <Button color="warning" variant="contained" onClick={handleSaveClick}>
          {t('workout-edit-buttons-save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditSettingsDialog
