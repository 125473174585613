import React, { useContext } from 'react'
import { Box, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../store'
import WorkoutList from '../../components/WorkoutList'

import '../../styles/module.scss'
import usePathToWorkout from '../../hooks/usePathToWorkout'
import PageWrapper from '../../containers/PageWrapper'
import Calendar from '../../components/Calendar'
import routes from '../../helpers/routes'

const WorkoutsArchive: React.FC = () => {
  const { workoutsArchive, isLoading } = useContext(DataContext)
  const navigate = useNavigate()
  usePathToWorkout()

  return (
    <PageWrapper className="page workouts-page">
      {isLoading && (
        <div className="top-loader">
          <LinearProgress sx={{ height: '3px' }} />
        </div>
      )}

      <Box sx={{ mb: 4 }}>
        <Calendar
          onDateClick={(uuid, isArchive) => {
            if (isArchive) {
              navigate(routes.archiveView(uuid))
            } else {
              navigate(routes.workoutView(uuid))
            }
          }}
        />
      </Box>

      {workoutsArchive && workoutsArchive.length > 0 ? (
        <Box className="client-workouts-list">
          <WorkoutList data={workoutsArchive} isArchive={true} />
        </Box>
      ) : (
        <Box>No data</Box>
      )}
    </PageWrapper>
  )
}

export default WorkoutsArchive
