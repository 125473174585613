import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { UserContext, DataContext } from '../../store'
import AddExerciseDialog from '../Dialogs/AddExerciseDialog'
import useExercises from '../../hooks/useExercises'
import useTranslations from '../../hooks/useTranslations'
import { NewExerciseData } from '../../types'

type Option = { title: string; id: string }

interface Props {
  isCardio?: boolean
  onChange: (data: Option) => void
  exId?: string
  group?: string
}

const ExercisesList: React.FC<Props> = ({
  exId,
  isCardio,
  onChange,
  group,
}) => {
  const { user } = useContext(UserContext)
  const { addNewExercise } = useContext(DataContext)
  const { options, value, setValue } = useExercises({ isCardio, group })
  const { t } = useTranslations()
  const [isNewExercise, setIsNewExercise] = useState<boolean>(false)

  const handleAddExerciseConfirm = async (data: NewExerciseData) => {
    if (data && addNewExercise && user?.uuid) {
      await addNewExercise(data, user.uuid)
      setIsNewExercise(false)
      setValue(value)
    }
  }

  return (
    <>
      <TextField
        fullWidth={true}
        size="small"
        autoFocus={true}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <List className="exercises-list" subheader={<li />}>
        {options.length > 0 ? (
          options.map((group) => (
            <li key={`group-${group.id}`}>
              <ul>
                <ListSubheader
                  className="exercises-list-subheader"
                  sx={{ backgroundColor: 'background.paper' }}
                >
                  {t(`group-title-${group.id}`)}
                </ListSubheader>
                {group.exercises.map((item) => (
                  <ListItem
                    key={`exercise-${group.id}-${item.id}`}
                    onClick={() => {
                      if (exId !== item.id) {
                        onChange({ title: item.title, id: item.id })
                      }
                    }}
                  >
                    {user?.uuid && item.id.includes(user.uuid) ? (
                      <ListItemText primary={item.title} />
                    ) : (
                      <ListItemText primary={t(`exercise-title-${item.id}`)} />
                    )}
                  </ListItem>
                ))}
              </ul>
            </li>
          ))
        ) : (
          <Box sx={{ px: 2, py: 1.5 }}>
            <Typography>{t('find-exercise-dialog-no-results')}</Typography>
            <Button
              size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ mt: 2 }}
              onClick={() => {
                setIsNewExercise(true)
              }}
            >
              {t('add-exercise-button')}
            </Button>
          </Box>
        )}
      </List>

      {isNewExercise && (
        <AddExerciseDialog
          handleClose={() => {
            setIsNewExercise(false)
          }}
          handleConfirm={handleAddExerciseConfirm}
          defaultValue={value}
        />
      )}
    </>
  )
}

export default ExercisesList
