import { useContext, useEffect } from 'react'
import { UserContext, DataContext, WorkoutsContext } from '../store'
import { useLocation } from 'react-router-dom'

const usePathToSetData = () => {
  const { getClients } = useContext(UserContext)
  const { isAppReady, setWorkouts } = useContext(DataContext)
  const { currentWorkout, setCurrentWorkout } = useContext(WorkoutsContext)
  const { pathname } = useLocation()

  const defaultFn = () => {}

  useEffect(() => {
    if (pathname && isAppReady) {
      const pathsMappings = {
        '/edit/new': defaultFn,
        '/edit': defaultFn,
        '/view': defaultFn,
        '/new': defaultFn,
        '/clients': () => {
          getClients && getClients()
        },
        '/archive': defaultFn,
        '/workouts': () => {
          setCurrentWorkout && setCurrentWorkout()
        },
        '/workout': defaultFn,
        '/': () => {
          setCurrentWorkout && setCurrentWorkout()
        },
      }

      const pathsKey =
        Object.keys(pathsMappings).find((path) => pathname.includes(path)) || ''

      if (pathsKey) {
        // @ts-ignore
        pathsMappings[pathsKey]()
      }
    }
  }, [pathname, isAppReady])
}

export default usePathToSetData
