import React, { useContext, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import HistoryIcon from '@mui/icons-material/History'

import { DataContext } from '../../store'
import WorkoutList from '../../components/WorkoutList'
import useTranslations from '../../hooks/useTranslations'

import '../../styles/module.scss'
import routes from '../../helpers/routes'
import usePathToWorkout from '../../hooks/usePathToWorkout'
import PageWrapper from '../../containers/PageWrapper'
import WeekTitle from '../../components/WeekTitle'
import AddWorkoutButton from '../../components/Buttons/AddWorkoutButton'
import useWorkouts from '../../hooks/useWorkouts'

const Workouts: React.FC = () => {
  const {
    workoutsArchive,
    fetchClientWorkouts,
    setWorkouts,
    setWorkoutsArchive,
    isLoading,
  } = useContext(DataContext)
  const { t } = useTranslations()
  const navigate = useNavigate()
  const { clientId } = useParams()
  usePathToWorkout()

  const { today, upcomingWorkouts, futureWorkouts, lastWorkouts } =
    useWorkouts()

  useEffect(() => {
    if (clientId) {
      if (setWorkouts && setWorkoutsArchive) {
        setWorkouts()
        setWorkoutsArchive()
      }

      if (fetchClientWorkouts && clientId) {
        fetchClientWorkouts(clientId, true)
      }
    }
  }, [clientId])

  if (!workoutsArchive || isLoading) {
    return (
      <PageWrapper className="page workouts-page">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper className="page workouts-page">
      <Typography variant="h6" sx={{ mb: 2, fontSize: 18 }}>
        <WeekTitle date={today} />
      </Typography>

      {upcomingWorkouts && upcomingWorkouts.length > 0 ? (
        <Box sx={{ mb: 2 }}>
          <WorkoutList data={upcomingWorkouts} />
          <Box sx={{ mt: 1 }}>
            <AddWorkoutButton />
          </Box>
        </Box>
      ) : (
        <Box className="flex-row" sx={{ mb: 4 }}>
          <Typography variant="subtitle2">
            {t('app-workouts-no-planned')}
          </Typography>
          <AddWorkoutButton />
        </Box>
      )}

      {futureWorkouts && futureWorkouts.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2">
            {t('app-workouts-upcoming')}
          </Typography>
          <WorkoutList data={futureWorkouts} />
        </Box>
      )}

      {/*<Divider />*/}

      {lastWorkouts && lastWorkouts.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">
            {t('app-workouts-recent')}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <WorkoutList data={lastWorkouts} isArchive={true} />
        </Box>
      )}

      <div className="workout-bottom-buttons">
        {workoutsArchive && workoutsArchive.length > 0 && (
          <Button
            startIcon={<HistoryIcon />}
            onClick={() => {
              navigate(
                clientId
                  ? routes.clientWorkoutsArchive(clientId)
                  : routes.workoutsArchive
              )
            }}
            size="small"
          >
            {t('page-title-archive')}
          </Button>
        )}
      </div>
    </PageWrapper>
  )
}

export default Workouts
