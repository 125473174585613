import React from 'react'

import '../../../styles/module.scss'

import { Set } from '../../../types'

type SetUnits = 'reps' | 'weight' | 'length' | 'speed'

interface Props {
  set: Set
  unit: SetUnits
}

const SetUnit: React.FC<Props> = ({ set, unit }) => {
  const getResultsData = (unit: SetUnits) =>
    set.results?.[unit] && set.results?.[unit] !== set.data[unit] ? (
      <>
        <span className="set-result-value">{set.results?.[unit]}</span>
        {/*<span className="set-default-value">({set.data?.[unit]})</span>*/}
      </>
    ) : (
      set.data[unit]
    )

  const withResultsCN = (unit: SetUnits) =>
    set.results?.[unit] && set.results?.[unit] !== set.data[unit]
      ? ' set-with-result'
      : ''

  return (
    <div
      className={`exercise-set-value exercise-set-${unit}${withResultsCN(
        unit
      )}`}
    >
      {getResultsData(unit)}
      {unit === 'reps' && typeof set.data.weight === 'number' && (
        <small>x</small>
      )}
    </div>
  )
}

export default SetUnit
