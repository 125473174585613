import dayjs, { Dayjs } from 'dayjs'
import { APP_LOCALE } from '../helpers/constants'
import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../store'
import { Status, Workout } from '../types'

const useWorkouts = () => {
  const { workouts, workoutsArchive } = useContext(DataContext)

  const [upcomingWorkouts, setUpcomingWorkouts] = useState<Workout[]>()
  const [futureWorkouts, setFutureWorkouts] = useState<Workout[]>()
  const [lastWorkouts, setLastWorkouts] = useState<Workout[]>()
  const [today, setToday] = useState<Dayjs>(dayjs().locale(APP_LOCALE))

  useEffect(() => {
    const today = dayjs().locale(APP_LOCALE)

    setToday(today)

    setUpcomingWorkouts(
      workouts
        ?.filter(
          (el) =>
            (dayjs(el.date).isSameOrAfter(today.startOf('week'), 'd') &&
              dayjs(el.date).isSameOrBefore(today.endOf('week'), 'd')) ||
            !el.date
        )
        .sort((a, b) => {
          if (a.date && b.date) {
            return a.date > b.date ? 1 : -1
          }

          if (a.created_on && b.created_on) {
            return a.created_on > b.created_on ? 1 : -1
          }

          return 0
        })
    )

    setFutureWorkouts(
      workouts
        ?.filter((el) => dayjs(el.date).isAfter(today.endOf('week'), 'd'))
        .sort((a, b) => {
          if (a.date && b.date) {
            return a.date > b.date ? 1 : -1
          }

          if (a.created_on && b.created_on) {
            return a.created_on > b.created_on ? 1 : -1
          }

          return 0
        })
    )

    const lastWorkouts = workoutsArchive?.filter(
      (el) =>
        dayjs(el.date).isSameOrAfter(
          today.subtract(1, 'week').startOf('week'),
          'd'
        ) &&
        dayjs(el.date).isSameOrBefore(
          today.subtract(1, 'week').endOf('week'),
          'd'
        ) &&
        el.status === Status.completed
    )

    setLastWorkouts(lastWorkouts)
  }, [workouts])

  return {
    today,
    upcomingWorkouts,
    futureWorkouts,
    lastWorkouts,
  }
}

export default useWorkouts
