import React, { useContext } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Box, CircularProgress, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import './styles/app.scss'
import App from './App'
import theme from './theme'
import {
  AuthContext,
  DataProvider,
  TranslationsProvider,
  UserContext,
  WorkoutsProvider,
} from './store'

import Login from './components/Login'
import VerifyEmail from './components/VerifyEmail'

function Root() {
  const { isAuthorized, isEmailVerification } = useContext(AuthContext)
  const { isLoading } = useContext(UserContext)

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : isAuthorized ? (
        <TranslationsProvider>
          <DataProvider>
            <WorkoutsProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </WorkoutsProvider>
          </DataProvider>
        </TranslationsProvider>
      ) : isEmailVerification ? (
        <VerifyEmail />
      ) : (
        <Login />
      )}
    </ThemeProvider>
  )
}

export default Root
