import dayjs from 'dayjs'

import { APP_LOCALE } from './constants'
import { ExerciseData, Set, StatsData, Status, Workout } from '../types'

export const findMaxResult = (sets: Set[], isData = false) =>
  sets.reduce((r: number, s: Set) => {
    const data = isData ? s.data : s.results

    if (
      (data?.weight && data.weight > r && data.reps && data.reps >= 8) ||
      (!data?.weight && data?.reps && data.reps > r)
    ) {
      return Number(data.weight || data.reps)
    }

    return r
  }, 0)

export const getUserStats = ({
  exercises,
  workouts,
  workoutsArchive,
}: {
  exercises: ExerciseData
  workouts: Workout[]
  workoutsArchive: Workout[]
}) => {
  let stats: StatsData = {}
  let completedWorkouts

  const allWorkouts = [...(workouts || []), ...(workoutsArchive || [])]
  if (allWorkouts.length > 0) {
    completedWorkouts = allWorkouts.filter(
      (el) => el.status === Status.completed
    )

    const allPastWorkouts =
      (allWorkouts?.filter((el) =>
        dayjs(el.date).isBefore(dayjs().locale(APP_LOCALE).startOf('week'))
      )?.length || 0) +
      (allWorkouts?.filter(
        (el) =>
          dayjs(el.date).isAfter(dayjs().locale(APP_LOCALE).startOf('week')) &&
          el.status === 2
      )?.length || 0)
    const today = dayjs().locale(APP_LOCALE)
    const upcomingWorkout = allWorkouts
      .filter((w) => w.status === Status.new)
      .filter(
        (el) =>
          dayjs(el.date).isSameOrAfter(today.startOf('week'), 'd') || !el.date
      )[0]

    const groupTitles =
      upcomingWorkout?.exercises
        .filter(
          (el) => !el.exercise_id.includes('cardio') && el.type !== 'warmup'
        )
        ?.filter((ex) => !ex.id.includes('core'))
        .map((ex) => {
          const group = exercises?.groups.find((g) => ex.id.includes(g.id))

          return `group-title-${group?.group_name_id}`
        })
        .filter((value, index, self) => self.indexOf(value) === index) || []

    const lastCompleted = allWorkouts.filter(
      (w) => w.status === Status.completed
    )[0]

    stats.completionRate = {
      completed: completedWorkouts.length,
      all: allPastWorkouts,
    }
    stats.workouts = {
      lastCompleted: {
        date: lastCompleted?.date || '',
      },
      upcoming: { date: upcomingWorkout?.date || '', groupTitles },
    }

    if (completedWorkouts && exercises) {
      let time: number[] = []
      let cardio: { [key: string]: number } = {}
      let weights: { [key: string]: { [key: string]: number | string }[] } = {}

      const groups = completedWorkouts.reduce(
        (res: { [key: string]: number }, w) => {
          if (w.endTime && w.startTime) {
            time.push(w.endTime - w.startTime)
          }

          w.exercises
            .filter(
              (w) =>
                w.exercise_id.includes('cardio') &&
                w.type !== 'warmup' &&
                w.status === Status.completed &&
                w.sets[0].results
            )
            .forEach((e, idx) => {
              if (cardio[e.exercise_id]) {
                cardio[e.exercise_id] += 1
              } else {
                cardio[e.exercise_id] = 1
              }
            })

          w.exercises
            .filter(
              (w) =>
                !w.exercise_id.includes('cardio') &&
                !w.exercise_id.includes('core')
            )
            .forEach((e) => {
              const group = exercises.groups.find((g) =>
                e.exercise_id.includes(g.id)
              )
              const groupId = group?.group_name_id

              if (group?.exercises.some((ex) => ex.id === e.exercise_id)) {
                if (e.sets[0].results) {
                  if (!weights[e.exercise_id]) {
                    weights[e.exercise_id] = []
                  }

                  weights[e.exercise_id].push({
                    date: w.date,
                    value: findMaxResult(e.sets),
                  })
                }
              }

              if (groupId) {
                if (res[groupId]) {
                  res[groupId] += 1
                } else {
                  res[groupId] = 1
                }
              }
            })
          return res
        },
        {}
      )

      stats = {
        ...stats,
        groups,
        time,
        cardio,
        weights,
      }

      return stats
    }
  }

  return stats
}
