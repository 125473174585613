import { useContext } from 'react'

import { UserContext, TranslationsContext } from '../store'

const useTranslations = () => {
  const { user, locale } = useContext(UserContext)
  const { dictionary, dictionaryExercises, dictionaryGeneral } =
    useContext(TranslationsContext)

  const t = (key: string, currentLocale?: string): string => {
    if (user?.uuid && key.includes(user.uuid)) {
      return ''
    }

    if (Object.keys(dictionary).includes(key) && dictionary[key]) {
      return dictionary[key][currentLocale || locale]
    }

    return key
  }

  return {
    t,
    dictionary,
    dictionaryExercises,
    dictionaryGeneral,
  }
}

export default useTranslations
