import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, IconButton, Typography } from '@mui/material'
import AppShortcutIcon from '@mui/icons-material/AppShortcut'
import ClearIcon from '@mui/icons-material/Clear'
import cn from 'classnames'
import dayjs from 'dayjs'

import { UserContext, WorkoutsContext } from '../../store'
import EditExerciseList from '../../components/Workouts/EditExercises/EditExerciseList'
import '../../styles/module.scss'
import DatePicker from '../../components/DatePicker'
import { Status } from '../../types'
import useTranslations from '../../hooks/useTranslations'
import { deepClone } from '../../helpers'
import routes from '../../helpers/routes'
import usePathToWorkout from '../../hooks/usePathToWorkout'
import WorkoutGenerator from '../../components/WorkoutGenerator'
import PageWrapper from '../../containers/PageWrapper'
import { DATE_FORMAT_WEEKDAYS_FULL } from '../../helpers/constants'
import Rating from '../../components/Rating'

const EditWorkout: React.FC = () => {
  const { user, activeClient, locale } = useContext(UserContext)
  const {
    currentWorkout,
    isEditDirty,
    updateEditWorkout,
    resetEditWorkout,
    saveEditWorkout,
    setCurrentWorkout,
  } = useContext(WorkoutsContext)
  const navigate = useNavigate()
  const { workoutId } = useParams()
  const { t } = useTranslations()
  usePathToWorkout()

  const [isOpenAi, setIsOpenAi] = useState<boolean>(false)

  const handleDateChange = (date: string) => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData.date = date

      updateEditWorkout && updateEditWorkout(newData)
    }
  }

  const handleRatingChange = (rating: number) => {
    if (currentWorkout) {
      const newData = deepClone(currentWorkout)

      newData.rating = rating

      updateEditWorkout && updateEditWorkout(newData)
    }
  }

  const handleCancelClick = () => {
    resetEditWorkout && resetEditWorkout()
  }

  const handleSaveClick = async () => {
    if (saveEditWorkout) {
      const resId = await saveEditWorkout()

      if (resId && resId !== workoutId) {
        if (currentWorkout?.uuid.includes('new') && setCurrentWorkout) {
          setCurrentWorkout()
        }
        navigate(
          activeClient
            ? routes.clientWorkoutView(activeClient, resId)
            : routes.workoutView(resId)
        )
      } else if (workoutId) {
        navigate(
          activeClient
            ? routes.clientWorkoutView(activeClient, workoutId)
            : routes.workoutView(workoutId)
        )
      }
    }
  }

  if (!currentWorkout) {
    return null
  }

  return (
    <PageWrapper className="page workouts-page edit-workout-page">
      <Box sx={{ pb: isEditDirty ? '70px' : 0 }}>
        <div className="workout-header">
          <div className="workout-data">
            <Box className="flex-row" sx={{ justifyContent: 'flex-start' }}>
              <div
                className={cn('workout-date-edit-datepicker', {
                  hidden: currentWorkout.date,
                })}
              >
                <DatePicker
                  onChange={handleDateChange}
                  value={currentWorkout.date}
                />
              </div>
              <div className="workout-date-edit">
                {currentWorkout.date &&
                  dayjs(currentWorkout.date)
                    .locale(locale)
                    .format(DATE_FORMAT_WEEKDAYS_FULL)}
              </div>

              {currentWorkout.status !== Status.completed &&
                currentWorkout.date && (
                  <Button
                    size="small"
                    sx={{ p: 0 }}
                    color="inherit"
                    onClick={() => {
                      handleDateChange('')
                    }}
                  >
                    <ClearIcon />
                  </Button>
                )}
            </Box>

            {currentWorkout.status === Status.completed &&
              currentWorkout.rating && (
                <Rating
                  value={currentWorkout.rating}
                  onChange={(value) => {
                    value && handleRatingChange(value)
                  }}
                />
              )}

            {user?.settings?.openai_secret &&
              currentWorkout.exercises.length <= 0 && (
                <IconButton
                  onClick={() => {
                    setIsOpenAi(true)
                  }}
                >
                  <AppShortcutIcon color="primary" />
                </IconButton>
              )}
          </div>
        </div>

        {currentWorkout.status === Status.completed && (
          <Typography
            variant="body2"
            sx={{ fontStyle: 'italic', mt: 1, fontSize: 12 }}
          >
            {t('workout-edit-completed-note')}
          </Typography>
        )}

        <EditExerciseList />

        {/*{isEditDirty && (*/}
        <div className={cn('workout-bottom-buttons', 'bottom-fixed-buttons')}>
          <Button
            color="inherit"
            onClick={() => {
              handleCancelClick()
              navigate(-1)
            }}
          >
            {t('app-button-add-import-cancel')}
          </Button>

          <Box>
            {isEditDirty && (
              <Button color="inherit" onClick={handleCancelClick}>
                {t('workout-edit-buttons-cancel')}
              </Button>
            )}
            <Button
              color="warning"
              variant="contained"
              disabled={!isEditDirty}
              onClick={handleSaveClick}
            >
              {t('workout-edit-buttons-save')}
            </Button>
          </Box>
        </div>
        {/*)}*/}

        {isOpenAi && (
          <WorkoutGenerator
            setIsOpen={setIsOpenAi}
            date={currentWorkout.date}
          />
        )}
      </Box>
    </PageWrapper>
  )
}

export default EditWorkout
