import React, { useContext } from 'react'

import SetItem from './SetItem'

import '../../../styles/module.scss'
import { Set } from '../../../types'
import { DataContext, WorkoutsContext } from '../../../store'
import useTranslations from '../../../hooks/useTranslations'
import EditItem from './EditItem'

interface Props {
  setList: Set[]
  id: string
  exerciseId: string
  exId?: string
}

const SetList: React.FC<Props> = ({ setList, id, exId, exerciseId }) => {
  const { findExerciseGroup } = useContext(DataContext)
  const { currentWorkout, isEditMode } = useContext(WorkoutsContext)
  const { t } = useTranslations()

  const exerciseGroup = findExerciseGroup(exerciseId)

  const isDataByKey = (key: string) =>
    setList[0]?.data
      ? Object.keys(setList[0].data).includes(key)
      : exerciseGroup?.initialData &&
        Object.keys(exerciseGroup.initialData).includes(key)

  const currentExercise = currentWorkout?.exercises.find((ex) => ex.id === id)
  const exerciseStatus = currentExercise?.status ?? 0

  return (
    <div className="exercise-sets">
      <div className="exercise-set-header">
        <div className="exercise-set-number">#</div>
        <div className="exercise-set-titles">
          {isDataByKey('reps') && (
            <span className="exercise-set-titles-item">
              {t('app-workout-data-reps')}
            </span>
          )}
          {isDataByKey('weight') && (
            <span
              className="exercise-set-titles-item"
              style={{ marginRight: 0 }}
            >
              {isDataByKey('weight') ? t('app-workout-data-kg') : ''}
            </span>
          )}
          {isDataByKey('length') && (
            <span className="exercise-set-titles-item">
              {exerciseId.includes('core')
                ? t('app-workout-data-sec')
                : t('app-workout-data-min')}
            </span>
          )}
          {isDataByKey('speed') && (
            <span
              className="exercise-set-titles-item"
              style={{ marginRight: 0 }}
            >
              {isDataByKey('speed') ? t('app-workout-data-speed') : ''}
            </span>
          )}
        </div>
        <div className="exercise-status" />
      </div>
      {setList.map((setItem, idx) =>
        isEditMode ? (
          <EditItem
            set={setItem}
            key={setItem.id}
            idx={idx}
            exerciseStatus={exerciseStatus}
            exerciseId={exerciseId}
          />
        ) : (
          <SetItem
            set={setItem}
            key={setItem.id}
            idx={idx}
            exerciseStatus={exerciseStatus}
            exerciseId={exerciseId}
            exId={exId}
          />
        )
      )}
    </div>
  )
}

export default SetList
