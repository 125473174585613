import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Box } from '@mui/material'

import { DataContext, UserContext, WorkoutsContext } from '../../store'
import { Status } from '../../types'
import Countdown from '../Countdown'
import { customIcons } from '../Rating'
import CardMenu from '../CardMenu'
import useTranslations from '../../hooks/useTranslations'
import { DATE_FORMAT_WEEKDAYS } from '../../helpers/constants'

const WorkoutHeader = () => {
  const { locale } = useContext(UserContext)
  const { exercises } = useContext(DataContext)
  const { currentWorkout, generalExercises, isViewMode } =
    useContext(WorkoutsContext)
  const { pathname } = useLocation()
  const { t } = useTranslations()

  if (!currentWorkout) {
    return null
  }

  const groupTitles = generalExercises
    ?.filter((ex) => !ex.id.includes('core'))
    .map((ex) => {
      const group = exercises?.groups.find((g) => ex.id.includes(g.id))

      return t(`group-title-${group?.group_name_id}`)
    })
    .filter((value, index, self) => self.indexOf(value) === index)

  return (
    <div className="workout-header">
      <div className="workout-data">
        <div className="workout-date">
          {currentWorkout.status === Status.completed && (
            <EmojiEventsIcon sx={{ height: 20, width: 20 }} color="warning" />
          )}

          {groupTitles && groupTitles.length < 4
            ? groupTitles.join('/')
            : '💪 Fullbody 💪'}

          {currentWorkout?.rating && (
            <Box
              title={customIcons[currentWorkout.rating].label}
              sx={{ ml: 1, '& svg': { display: 'flex' } }}
            >
              {customIcons[currentWorkout.rating].icon}
            </Box>
          )}
        </div>

        {isViewMode && currentWorkout.status !== Status.inProgress && (
          <Box sx={{ mr: -1 }}>
            <CardMenu
              uuid={currentWorkout?.uuid}
              isArchive={pathname.includes('/archive')}
              isCompleted={currentWorkout.status === Status.completed}
            />
          </Box>
        )}
      </div>

      <div className="workout-data" style={{ marginTop: 8 }}>
        {currentWorkout.date && (
          <Box
            sx={{
              fontSize: '90%',
              textTransform: 'uppercase',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CalendarMonthIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
            {currentWorkout.date
              ? dayjs(currentWorkout.date)
                  .locale(locale)
                  .format(DATE_FORMAT_WEEKDAYS)
              : '--.--.--'}
          </Box>
        )}

        {currentWorkout.startTime && (
          <Countdown
            startTime={currentWorkout.startTime}
            endTime={currentWorkout.endTime}
          />
        )}
      </div>
    </div>
  )
}

export default WorkoutHeader
