import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Button } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import SportsScoreIcon from '@mui/icons-material/SportsScore'

import ExerciseList from '../../components/Workouts/Exercises/ExerciseList'

import '../../styles/module.scss'
import { DataContext, UserContext, WorkoutsContext } from '../../store/'
import { Status } from '../../types'
import WarningDialog from '../../components/Dialogs/WarningDialog'
import useTranslations from '../../hooks/useTranslations'
import routes from '../../helpers/routes'
import WorkoutCompleteDialog from '../../components/Dialogs/WorkoutCompleteDialog'
import PageWrapper from '../../containers/PageWrapper'

dayjs.extend(duration)

const ActiveWorkout: React.FC = () => {
  const nav = useNavigate()
  const { isClient } = useContext(UserContext)
  const { isAppReady, updateWorkout } = useContext(DataContext)
  const {
    currentWorkout,
    updateWorkoutProgress,
    updateExerciseProgress,
    resetCurrentWorkout,
  } = useContext(WorkoutsContext)
  const { t } = useTranslations()

  const [isCancelWarning, setIsCancelWarning] = useState<boolean>(false)
  const [isStopWarning, setIsStopWarning] = useState<boolean>(false)
  const [isFinalDialog, setIsFinalDialog] = useState<boolean>(false)

  useEffect(() => {
    if (currentWorkout?.status === Status.completed) {
      setIsFinalDialog(true)
    }
  }, [currentWorkout?.status])

  useEffect(() => {
    if (isAppReady && !currentWorkout) {
      nav(routes.workouts)
    }
  }, [isAppReady, currentWorkout])

  const handleCloseClick = () => {
    setIsFinalDialog(false)
  }

  const handleSaveClick = async (data: {
    comment?: string
    rating?: number
  }) => {
    if (currentWorkout && updateWorkout && resetCurrentWorkout) {
      setIsFinalDialog(false)
      await updateWorkout({ ...currentWorkout, ...data })
      resetCurrentWorkout && resetCurrentWorkout()
      nav(routes.workoutView(currentWorkout.uuid))
    } else {
      nav(routes.workouts)
    }
  }

  const handleWorkoutStop = () => {
    currentWorkout?.exercises.forEach(({ id }) => {
      updateExerciseProgress && updateExerciseProgress(id, Status.completed)
    })
    updateWorkoutProgress && updateWorkoutProgress(Status.completed)
    setIsStopWarning(false)
  }

  const handleWorkoutCancel = () => {
    updateWorkoutProgress && updateWorkoutProgress(Status.new)
    setIsCancelWarning(false)
  }

  if (!currentWorkout) {
    return null
  }

  return (
    <PageWrapper className="page workout-page">
      <ExerciseList />

      {isClient && currentWorkout?.status === Status.inProgress && (
        <div className="workout-bottom-buttons">
          <>
            <Button
              onClick={() => {
                setIsCancelWarning(true)
              }}
              startIcon={<CancelIcon />}
              variant="outlined"
              color="error"
              size="small"
            >
              {t('app-button-cancel-workout')}
            </Button>

            {isCancelWarning && (
              <WarningDialog
                handleClose={() => {
                  setIsCancelWarning(false)
                }}
                handleConfirm={handleWorkoutCancel}
                title={t('app-cancel-workout-dialog-title')}
                note={t('app-cancel-workout-dialog-text')}
                isCancel={true}
              />
            )}

            {currentWorkout?.exercises.some(
              (ex) => ex.status === Status.completed
            ) && (
              <Button
                onClick={() => {
                  setIsStopWarning(true)
                }}
                startIcon={<SportsScoreIcon />}
                variant="outlined"
                color="inherit"
                size="small"
              >
                {t('app-button-finish-workout')}
              </Button>
            )}

            {isStopWarning && (
              <WarningDialog
                handleClose={() => {
                  setIsStopWarning(false)
                }}
                handleConfirm={handleWorkoutStop}
                title={t('app-finish-workout-dialog-title')}
                note={t('app-finish-workout-dialog-text')}
              />
            )}
          </>
        </div>
      )}

      {isFinalDialog && (
        <WorkoutCompleteDialog
          handleCloseClick={handleCloseClick}
          handleSaveClick={handleSaveClick}
        />
      )}
    </PageWrapper>
  )
}

export default ActiveWorkout
