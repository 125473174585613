import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Box, Checkbox, CircularProgress, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'

import '../../../styles/module.scss'

import { Set, SetData, Status } from '../../../types'
import { DataContext, WorkoutsContext } from '../../../store'
import Timer from '../../Timer'
import SetUnit from './SetUnit'
import useTranslations from '../../../hooks/useTranslations'
import SupersetConfirmDialog from '../../Dialogs/SupersetConfirmDialog'
import cn from 'classnames'
import EditIcon from '@mui/icons-material/Edit'

interface Props {
  sets: Set[]
  idx: number
  exerciseStatus: Status
  exerciseIds: string[]
  titles: string[]
}

const SupersetItem: React.FC<Props> = ({
  sets,
  exerciseStatus,
  idx,
  exerciseIds,
  titles,
}) => {
  const { isLoading } = useContext(DataContext)
  const { updateSupersetProgress, currentWorkout, isViewMode } =
    useContext(WorkoutsContext)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslations()

  useEffect(() => {
    if (!isLoading) {
      // setLoading(false)
    }
  }, [isLoading])

  const handleSetsUpdate = (setIds: string[], values?: SetData[]) => {
    if (updateSupersetProgress) {
      if (values) {
        updateSupersetProgress(setIds, values)
      } else {
        if (global.confirm(t('app-button-cancel-exercise'))) {
          updateSupersetProgress(setIds)
        } else {
          // setLoading(false)
        }
      }
    }
  }

  const set = sets[0]

  const currentExercise = currentWorkout?.exercises?.find((wkot) =>
    wkot.sets.some(({ id }) => id === set.id)
  )

  const noResultIndex =
    currentExercise?.sets?.findIndex((set) => !set.results) || 0

  const isComplete = Boolean(set.results)

  const handleEditClick = () => {
    setShowDialog(true)
  }

  const handleOnChange = () => {
    // setLoading(true)
    if (!isComplete) {
      const newData = sets.map((set) => ({ ...set.data }))
      handleSetsUpdate(
        sets.map(({ id }) => id),
        newData
      )
    } else {
      handleSetsUpdate(sets.map(({ id }) => id))
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleCloseClick = () => {
    handleDialogClose()
  }

  const handleSaveClick = (newData: SetData[]) => {
    // setLoading(true)
    handleSetsUpdate(
      sets.map(({ id }) => id),
      newData
    )
    handleDialogClose()
  }

  const prevTime: number =
    currentExercise?.sets?.[noResultIndex - 1]?.results?.time ?? 0

  const isTimer =
    currentWorkout?.status === Status.inProgress &&
    idx === noResultIndex &&
    exerciseStatus === Status.inProgress &&
    !set.results &&
    prevTime > 0

  const activeSetInProgress =
    exerciseStatus === Status.inProgress && idx === noResultIndex

  return (
    <div
      className={cn('exercise-set-data', 'exercise-superset-data', {
        'exercise-set-complete': isComplete && !isViewMode,
        'active-set':
          idx === noResultIndex &&
          !isViewMode &&
          exerciseStatus === Status.inProgress,
      })}
    >
      {isTimer && !loading && <Timer timer={prevTime} isSuperset={true} />}

      <div className="exercise-set-number">{idx + 1}</div>
      {sets.map((set, idx) => {
        return (
          <Fragment key={idx}>
            {idx > 0 && (
              <Box className="exercise-set-plus">
                <Box
                  sx={{
                    position: 'absolute',
                    left: '-4px',
                    top: activeSetInProgress ? 8 : 2,
                  }}
                >
                  +
                </Box>
              </Box>
            )}
            <Box
              key={idx}
              className={cn('exercise-set-pairs', 'exercise-superset-pairs')}
            >
              {set?.data.reps && (
                <>
                  {set.data.reps && <SetUnit unit="reps" set={set} />}
                  {typeof set.data.weight === 'number' && (
                    <SetUnit unit="weight" set={set} />
                  )}
                </>
              )}
              {set?.data.length && (
                <>
                  {set.data.length && <SetUnit unit="length" set={set} />}
                  {set.data.speed && <SetUnit unit="speed" set={set} />}
                </>
              )}
            </Box>
          </Fragment>
        )
      })}
      <div className="exercise-status">
        {isViewMode && currentWorkout?.status === Status.completed ? (
          set.results ? (
            <CheckIcon sx={{ width: 16 }} />
          ) : (
            <RemoveIcon sx={{ width: 16 }} />
          )
        ) : (
          <>
            {(Boolean(idx <= noResultIndex) || set.results) &&
              currentExercise?.status === Status.inProgress && (
                <IconButton
                  size="small"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    right: 28,
                  }}
                  onClick={handleEditClick}
                  disabled={isLoading}
                >
                  <EditIcon sx={{ width: 16, height: 16 }} />
                </IconButton>
              )}
            {loading ? (
              <Box sx={{ height: 26, mr: activeSetInProgress ? 0 : '4px' }}>
                <CircularProgress
                  size={activeSetInProgress ? 26 : 20}
                  color="inherit"
                />
              </Box>
            ) : (
              <Checkbox
                className="set-checkbox"
                onChange={handleOnChange}
                checked={isComplete}
                size={activeSetInProgress ? 'medium' : 'small'}
                disabled={
                  exerciseStatus !== Status.inProgress ||
                  Boolean(idx > noResultIndex) ||
                  Boolean(idx < noResultIndex - 1) ||
                  isViewMode ||
                  isLoading
                }
              />
            )}
          </>
        )}
      </div>

      {showDialog && (
        <SupersetConfirmDialog
          sets={sets}
          idx={idx}
          // isTimer={isTimer}
          // prevTime={prevTime}
          open={showDialog}
          exerciseIds={exerciseIds}
          titles={titles}
          handleCloseClick={handleCloseClick}
          handleSaveClick={handleSaveClick}
        />
      )}
    </div>
  )
}

export default SupersetItem
