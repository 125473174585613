import React from 'react'

interface Props {
  timeDifference: any
}

const TimeDiff: React.FC<Props> = ({ timeDifference }) => {
  return (
    <span>{`${(timeDifference.days() * 24 + timeDifference.hours())
      .toString()
      .padStart(2, '0')}:${timeDifference
      .minutes()
      .toString()
      .padStart(2, '0')}:${timeDifference
      .seconds()
      .toString()
      .padStart(2, '0')}`}</span>
  )
}

export default TimeDiff
