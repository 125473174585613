import React, { useContext } from 'react'
import { Box, Button } from '@mui/material'

import { AuthContext, UserContext } from '../../store'

import '../../styles/profile.scss'
import FloatMenu from '../../components/FloatMenu'
import ProfileSettings from '../../components/Profile/Settings'
import ProfileBody from '../../components/Profile/Body'
import PageWrapper from '../../containers/PageWrapper'
import Avatar from '../../components/Avatar'

const UserProfile: React.FC = () => {
  const { signOutHandler } = useContext(AuthContext)
  const { user } = useContext(UserContext)

  if (!user) {
    return null
  }

  return (
    <PageWrapper className="page user-profile-page">
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Avatar user={user} sx={{ mr: 2 }} />
        <Box sx={{ fontSize: 20 }}>{user.name}</Box>
      </Box>

      <ProfileBody />

      <ProfileSettings />

      <FloatMenu />

      <Button sx={{ mt: 4 }} onClick={signOutHandler}>
        Log Out
      </Button>
    </PageWrapper>
  )
}

export default UserProfile
