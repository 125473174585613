import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useTranslations from '../../hooks/useTranslations'

interface Props {
  handleClose: () => void
  handleConfirm: () => void
  title: string
  note?: string
  isCancel?: boolean
}

const WarningDialog: React.FC<Props> = ({
  handleClose,
  handleConfirm,
  title,
  note,
  isCancel,
}) => {
  const { t } = useTranslations()

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {note && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {note}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit">
          {t('app-button-dialog-no')}
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus={true}
          variant="contained"
          color={isCancel ? 'error' : 'warning'}
        >
          {t('app-button-dialog-yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WarningDialog
