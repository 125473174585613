import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Exercise, SetData, Status, Workout } from '../types'
import { DataContext } from './DataProvider'
import { deepClone } from '../helpers'
import { UserContext } from './UserProvider'
import dayjs from 'dayjs'
import { DATE_FORMAT_DATA } from '../helpers/constants'

interface ContextType {
  currentWorkout?: Workout
  warmupExercises?: Exercise[]
  generalExercises?: Exercise[]
  cardioExercises?: Exercise[]
  isEditDirty?: boolean
  isEditMode?: boolean
  isViewMode?: boolean
  resetCurrentWorkout?: () => void
  resetEditWorkout?: () => void
  saveEditWorkout?: () => Promise<any>
  setCurrentWorkout?: (uuid?: string) => void
  setIsEditMode?: (value: boolean) => void
  setIsViewMode?: (value: boolean) => void
  updateEditWorkout?: (data: Workout) => void
  updateExerciseProgress?: (data: string | string[], status: Status) => void
  updateSetProgress?: (id: string, data?: SetData) => void
  updateSupersetProgress?: (ids: string[], data?: SetData[]) => void
  updateWorkoutProgress?: (status: Status) => void
  createNewWorkout?: () => void
}

export const WorkoutsProvider = ({ children }: { children: ReactNode }) => {
  const { user, activeClient, updateUser } = useContext(UserContext)
  const { workouts, workoutsArchive, updateWorkout, createWorkout } =
    useContext(DataContext)

  // const [activeWorkout, _setActiveWorkout] = useState<Workout | undefined>()
  const [currentWorkout, _setCurrentWorkout] = useState<Workout | undefined>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isViewMode, setIsViewMode] = useState<boolean>(false)
  const [isEditDirty, setIsEditDirty] = useState<boolean>(false)

  const warmupExercises = useMemo(
    () => currentWorkout?.exercises.filter((el) => el.type === 'warmup'),
    [currentWorkout]
  )
  const generalExercises = useMemo(
    () =>
      currentWorkout?.exercises.filter(
        (el) => !el.exercise_id.includes('cardio') && el.type !== 'warmup'
      ),
    [currentWorkout]
  )
  const cardioExercises = useMemo(
    () =>
      currentWorkout?.exercises.filter(
        (el) => el.exercise_id.includes('cardio') && el.type !== 'warmup'
      ),
    [currentWorkout]
  )

  const resetCurrentWorkout = () => {
    _setCurrentWorkout(undefined)
  }

  const updateEditWorkout = (data: Workout) => {
    _setCurrentWorkout({ ...data, updated_on: Date.now() })
    if (data.exercises.length > 0 || data.date) {
      setIsEditDirty(true)
    }
  }

  const createNewWorkout = useCallback(() => {
    if (user?.uuid && workouts) {
      const tempWorkout: Workout = {
        uuid: 'new',
        date: '',
        exercises: [],
        status: 0,
        user_id: activeClient || user?.uuid,
        created_on: Date.now(),
      }

      updateEditWorkout && updateEditWorkout(tempWorkout)
    }
  }, [activeClient, workouts, user])

  const saveEditWorkout = async () => {
    if (currentWorkout && workouts && updateWorkout && createWorkout) {
      try {
        if (currentWorkout.uuid.includes('new')) {
          return await createWorkout({
            ...prepareData(currentWorkout),
            uuid: '',
          })
        } else {
          await updateWorkout(currentWorkout)

          return ''
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsEditDirty(false)
      }
    }
  }

  const resetEditWorkout = () => {
    let newData
    const workoutList = [...(workouts || []), ...(workoutsArchive || [])]

    if (currentWorkout && workoutList) {
      workoutList.forEach((el) => {
        if (el.uuid === currentWorkout.uuid) {
          newData = { ...el }
        }
      })

      if (!newData && currentWorkout.uuid === 'new') {
        newData = { ...currentWorkout, exercises: [], date: '' }
      }

      _setCurrentWorkout(newData)
      setIsEditDirty(false)
    }
  }

  const prepareData = (workout: Workout) => ({
    ...workout,
    exercises: workout.exercises
      .sort((a, b) => {
        let orderA = 0
        let orderB = 0

        if (a.type === 'warmup') {
          orderA = -1
        }
        if (b.type === 'cardio') {
          orderB = 1
        }

        return orderA - orderB
      })
      .map((ex) => {
        ex.sets.forEach((set, idx) => {
          if (!set.id) {
            set.id = `${ex.id}_${idx + 1}`
          }
        })

        return ex
      }),
  })

  const updateProgressForWorkout = (workout: Workout) => {
    const storage = localStorage.getItem('progress')
    const progress = storage && JSON.parse(storage)

    if (progress) {
      progress.forEach((item: Exercise) => {
        const exercise = workout?.exercises.find((ex) => ex.id === item.id)

        if (exercise) {
          exercise.sets = item.sets
        }
      })
    }

    return workout
  }

  const setCurrentWorkout = (uuid?: string) => {
    if (uuid) {
      const workoutByUuid =
        workouts?.find((el) => el.uuid === uuid) ||
        workoutsArchive?.find((el) => el.uuid === uuid)

      if (workoutByUuid) {
        console.log('setCurrentWorkout', { workoutByUuid })

        const workout = updateProgressForWorkout(deepClone(workoutByUuid))

        _setCurrentWorkout(prepareData(workout))
      }
    } else {
      _setCurrentWorkout(undefined)
    }
  }

  useEffect(() => {
    if (workouts) {
      const activeWorkout = workouts.find(
        (el) => el.status === Status.inProgress
      )

      if (activeWorkout) {
        const workout = updateProgressForWorkout(activeWorkout)

        _setCurrentWorkout(prepareData(workout))
      }
    }
  }, [workouts])

  const updateSetProgress = async (setId: string, results?: SetData) => {
    if (currentWorkout && updateWorkout) {
      const workout = deepClone(currentWorkout)

      workout?.exercises?.forEach((ex) => {
        const currentSet = ex.sets.find((set) => set.id === setId)

        if (currentSet) {
          if (results) {
            currentSet.results = {
              ...results,
              time: currentSet.results?.time || Date.now(),
            }
          } else {
            delete currentSet.results
          }
        }

        if (currentSet && results) {
          if (ex.status === Status.new || !ex.status) {
            ex.status = Status.inProgress
          }

          // if (!ex.sets.some((set) => !set.results)) {
          //   ex.status = Status.completed
          // }

          if (
            !workout.exercises
              .filter((ex) => ex.type !== 'cardio')
              .some((ex) => ex.status !== Status.completed) &&
            workout.exercises
              .filter((ex) => ex.type === 'cardio')
              .some((ex) => ex.status === Status.completed)
          ) {
            workout.status = Status.completed
            workout.endTime = Date.now()
            workout.date = dayjs(Date.now()).format(DATE_FORMAT_DATA)
          }
        } else if (currentSet && !results) {
          ex.status = Status.inProgress

          if (
            !workout.exercises.some((ex) => ex.status === Status.inProgress) &&
            !workout.exercises.some((ex) => ex.status === Status.completed)
          ) {
            workout.status = Status.new
          } else {
            workout.status = Status.inProgress
          }
        }

        if (currentSet) {
          localStorage.setItem('progress', JSON.stringify([ex]))
        }
      })

      // await updateWorkout(workout)
      _setCurrentWorkout(workout)
    }

    return null
  }

  const updateSupersetProgress = async (setIds: string[], data?: SetData[]) => {
    if (currentWorkout && updateWorkout) {
      const workout = deepClone(currentWorkout)
      const progress: any[] = []

      setIds.forEach((setId, idx) => {
        const results = data?.[idx]

        workout?.exercises?.forEach((ex) => {
          const currentSet = ex.sets.find((set) => set.id === setId)

          if (currentSet) {
            if (results) {
              currentSet.results = { ...results, time: Date.now() }
            } else {
              delete currentSet.results
            }
          }

          if (currentSet && results) {
            if (ex.status === Status.new || !ex.status) {
              ex.status = Status.inProgress
            }

            if (
              !workout.exercises
                .filter((ex) => ex.type !== 'cardio')
                .some((ex) => ex.status !== Status.completed) &&
              workout.exercises
                .filter((ex) => ex.type === 'cardio')
                .some((ex) => ex.status === Status.completed)
            ) {
              workout.status = Status.completed
              workout.endTime = Date.now()
              workout.date = dayjs(Date.now()).format(DATE_FORMAT_DATA)
            }
          } else if (currentSet && !results) {
            ex.status = Status.inProgress

            if (
              !workout.exercises.some(
                (ex) => ex.status === Status.inProgress
              ) &&
              !workout.exercises.some((ex) => ex.status === Status.completed)
            ) {
              workout.status = Status.new
            } else {
              workout.status = Status.inProgress
            }
          }

          if (currentSet) {
            progress.push(ex)
          }
        })

        if (progress && progress.length > 0) {
          localStorage.setItem('progress', JSON.stringify(progress))
        }
      })

      // await updateWorkout(workout)
      _setCurrentWorkout(workout)
    }

    return null
  }

  const updateExerciseProgress = async (
    data: string | string[],
    status: Status
  ) => {
    const exerciseId: string = typeof data === 'string' ? data : data[0]
    const exercisePairId: string = typeof data !== 'string' ? data[1] : ''

    localStorage.removeItem('progress')

    if (!currentWorkout) {
      return
    }

    const workout = deepClone(currentWorkout)

    const currentExercise = workout?.exercises.find(
      ({ id }) => id === exerciseId
    )
    const pairedExercise = workout?.exercises.find(
      ({ id }) => id === exercisePairId
    )

    if (currentExercise) {
      if (status === Status.new) {
        delete currentExercise.startTime
        delete currentExercise.endTime
        currentExercise.sets.forEach((set) => {
          delete set.results
        })
      }

      if (status === Status.inProgress && !currentExercise.startTime) {
        currentExercise.startTime = Date.now()
      }

      if (status === Status.completed) {
        currentExercise.endTime = Date.now()
      }

      currentExercise.status = status

      if (pairedExercise) {
        if (status === Status.new) {
          delete pairedExercise.startTime
          delete pairedExercise.endTime
          pairedExercise.sets.forEach((set) => {
            delete set.results
          })
        }

        if (status === Status.inProgress && !pairedExercise.startTime) {
          pairedExercise.startTime = Date.now()
        }

        if (status === Status.completed) {
          pairedExercise.endTime = Date.now()
        }

        pairedExercise.status = status
      }
    }

    if (
      !workout.exercises
        .filter((el) => !el.exercise_id.includes('cardio'))
        .some((ex) => ex.status !== Status.completed) &&
      workout.exercises
        .filter(
          (el) => el.exercise_id.includes('cardio') && el.type !== 'warmup'
        )
        .some((ex) => ex.status === Status.completed)
    ) {
      workout.status = Status.completed
      workout.endTime = Date.now()
      workout.date = dayjs(Date.now()).format(DATE_FORMAT_DATA)
    }

    if (updateWorkout) {
      await updateWorkout(workout)
      _setCurrentWorkout(workout)
    }
  }

  const updateWorkoutProgress = async (status: Status) => {
    if (!currentWorkout) {
      return
    }

    localStorage.removeItem('progress')

    const workout = deepClone(currentWorkout)
    workout.status = status

    // new
    if (status === Status.new) {
      delete workout.startTime
      delete workout.endTime

      workout.exercises.forEach((ex) => {
        delete ex.startTime
        delete ex.endTime
        ex.status = Status.new
        ex.sets.forEach((set) => {
          delete set.results
        })
      })
    }

    // In Progress
    if (status === Status.inProgress) {
      if (!workout.startTime) {
        workout.startTime = Date.now()
      }
      if (!workout.date) {
        workout.date = dayjs(Date.now()).format(DATE_FORMAT_DATA)
      }
    }

    // Completed
    if (status === Status.completed) {
      workout.endTime = Date.now()

      if (!workout.date) {
        workout.date = dayjs(Date.now()).format(DATE_FORMAT_DATA)
      }
    }

    if (updateWorkout) {
      await updateWorkout(workout)

      if (user && updateUser) {
        updateUser({
          ...user,
          workoutInProgress:
            workout.status === Status.inProgress ? workout.uuid : '',
        })
      }

      _setCurrentWorkout(status !== Status.new ? workout : undefined)
    }
  }

  return (
    <WorkoutsContext.Provider
      value={{
        updateSetProgress,
        updateSupersetProgress,
        isEditMode,
        isViewMode,
        isEditDirty,
        currentWorkout,
        warmupExercises,
        generalExercises,
        cardioExercises,
        setCurrentWorkout,
        updateEditWorkout,
        setIsEditMode,
        setIsViewMode,
        updateExerciseProgress,
        updateWorkoutProgress,
        resetCurrentWorkout,
        resetEditWorkout,
        saveEditWorkout,
        createNewWorkout,
      }}
    >
      {children}
    </WorkoutsContext.Provider>
  )
}

export const WorkoutsContext = createContext<ContextType>({})
