import React, { useContext, useEffect } from 'react'
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import { CircularProgress, LinearProgress, Container, Box } from '@mui/material'

import ActiveWorkout from './pages/ActiveWorkout'
import Clients from './pages/Clients'
import ClientHome from './pages/ClientHome'
import ClientProfile from './pages/ClientProfile'
import Dictionary from './pages/Dictionary'
import ExerciseItem from './pages/Exercises/Item'
import Exercises from './pages/Exercises'
import Home from './pages/Home'
import StatsPage from './pages/Stats'
import Workouts from './pages/Workouts'
import WorkoutsArchive from './pages/WorkoutsArchive'
import WorkoutEdit from './pages/WorkoutEdit'
import WorkoutView from './pages/WorkoutView'
import UserProfile from './pages/UserProfile'

import AppBar from './components/AppBar'
import './styles/app.scss'
import { UserContext, DataContext, WorkoutsContext } from './store'
import WorkoutInProgressButton from './components/WorkoutInProgressButton'
import usePathToSetData from './hooks/usePathToSetData'
import routes from './helpers/routes'
import { isClient, isTrainer } from './helpers'

function App() {
  const { user } = useContext(UserContext)
  const { isLoading, isAppReady } = useContext(DataContext)
  const { setIsViewMode, setIsEditMode } = useContext(WorkoutsContext)

  const { pathname } = useLocation()
  const navigate = useNavigate()

  usePathToSetData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (user?.isNew) {
      navigate(routes.profile)
    }
  }, [user])

  useEffect(() => {
    if (setIsViewMode && setIsEditMode) {
      setIsViewMode(pathname.includes('view'))
      setIsEditMode(pathname.includes('edit'))
    }
  }, [pathname, setIsViewMode, setIsEditMode])

  return (
    <Container sx={{ p: 0, pt: 8 }} className="app">
      {isLoading && isAppReady && (
        <div className="top-loader">
          <LinearProgress sx={{ height: '3px' }} />
        </div>
      )}

      <AppBar />

      <WorkoutInProgressButton />

      {isAppReady ? (
        <Routes>
          <Route
            path="/"
            index={true}
            element={isClient(user?.roles) ? <ClientHome /> : <Home />}
          />

          <Route path="/exercises" element={<Exercises />} />
          <Route path="/exercises/:exerciseId" element={<ExerciseItem />} />

          <Route path="/dictionary" element={<Dictionary />} />

          <Route path="/profile" element={<UserProfile />} />

          {isTrainer(user?.roles) && (
            <Route path="/clients" element={<Clients />} />
          )}
          <Route path="/clients/:clientId" element={<ClientProfile />} />
          <Route
            path="/clients/:clientId/workouts/view/:workoutId"
            element={<WorkoutView />}
          />
          <Route
            path="/clients/:clientId/workouts/edit/:workoutId"
            element={<WorkoutEdit />}
          />
          <Route
            path="/clients/:clientId/workouts/archive"
            element={<WorkoutsArchive />}
          />
          <Route path="/clients/:clientId/workouts" element={<Workouts />} />
          <Route path="/clients/:clientId/stats" element={<StatsPage />} />

          <Route path="/stats" element={<StatsPage />} />

          <Route path="/workout" element={<ActiveWorkout />} />
          <Route path="/workouts" element={<Workouts />} />
          <Route path="/workouts/archive" element={<WorkoutsArchive />} />
          <Route path="/workouts/view/:workoutId" element={<WorkoutView />} />
          <Route path="/workouts/edit/:workoutId" element={<WorkoutEdit />} />
          <Route
            path="/workouts/archive/view/:workoutId"
            element={<WorkoutView />}
          />
          <Route
            path="/workouts/archive/edit/:workoutId"
            element={<WorkoutEdit />}
          />

          <Route path="*" element={<Navigate to={routes.home} />} />
        </Routes>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  )
}

export default App
