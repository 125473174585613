import React, { useContext, useEffect, useState } from 'react'

import { DataContext, TranslationsContext, UserContext } from '../../store'
import { useNavigate, useParams } from 'react-router-dom'
import {
  DataExercise,
  DataExerciseGroup,
  EditTranslationData,
  SetData,
} from '../../types'
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import useTranslations from '../../hooks/useTranslations'
import {
  dumbbellWeights,
  freeWeights,
  LANGUAGES,
  machineWeights,
  repValues,
  UA_KEY,
} from '../../helpers/constants'
import Button from '@mui/material/Button'
import routes from '../../helpers/routes'
import EditIcon from '@mui/icons-material/Edit'
import VideoButton from '../../components/Buttons/VideoButton'
import PageWrapper from '../../containers/PageWrapper'
import { isAdmin, isTrainer } from '../../helpers'
import EditTranslationDialog from '../../components/Dialogs/EditTranslationDialog'

interface Values extends SetData {
  weightStep: number
  link?: string
}

const ExerciseItemPage: React.FC = () => {
  const { user } = useContext(UserContext)
  const { exercises, editExercise, isLoading } = useContext(DataContext)
  const { updateTranslation } = useContext(TranslationsContext)
  const { dictionaryExercises } = useTranslations()

  const { exerciseId } = useParams()
  const { t } = useTranslations()
  const navigate = useNavigate()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [exercise, setExercise] = useState<DataExercise>()
  const [exerciseGroup, setExerciseGroup] = useState<DataExerciseGroup>()
  const [initialValues, setInitialValues] = useState<Values>()
  const [values, setValues] = useState<Values>()
  const [weights, setWeights] = useState<number[]>(freeWeights)
  const [editTranslation, setEditTranslation] =
    useState<EditTranslationData | null>(null)

  useEffect(() => {
    const exerciseGroup = exercises?.groups.reduce((res, group) => {
      if (group.exercises.some((ex) => ex.id === exerciseId)) {
        return group
      }
      return res
    }, {} as DataExerciseGroup)

    if (exerciseGroup) {
      setExerciseGroup(exerciseGroup)
    }

    const exercise = exerciseGroup?.exercises?.find(
      (ex) => ex.id === exerciseId
    )

    if (exercise) {
      setExercise(exercise)

      if (exercise || exerciseGroup) {
        setInitialValues({
          link: exercise.link,
          weightStep:
            typeof exercise.weightStep === 'number'
              ? exercise.weightStep
              : exerciseGroup?.weightStep || 1,
          ...(exercise.initialData || exerciseGroup?.initialData || {}),
        })
      }
    } else {
      navigate(routes.exercises)
    }
  }, [exercises, exerciseId])

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    if (values?.weightStep) {
      setWeights(
        values.weightStep === 2
          ? dumbbellWeights
          : values.weightStep === 5
          ? machineWeights
          : freeWeights
      )
    }
  }, [values?.weightStep])

  if (!exercise || !exerciseGroup || !values) {
    return null
  }

  const handleChange = ({
    type,
    value,
  }: {
    type: string
    value: string | number
  }) => {
    setIsDirty(true)
    // @ts-ignore
    setValues((prevState) => ({
      ...prevState,
      [type]: value,
      ...(type === 'weightStep' && value === 0 && { weight: 0 }),
    }))
  }

  const handleCancelClick = () => {
    setValues(initialValues)
    setIsEdit(false)
    setIsDirty(false)
  }

  const handleSaveClick = async () => {
    if (editExercise) {
      const { weightStep, link, ...initialData } = values
      const isDataChanged = Object.keys(initialData).reduce((res, key) => {
        if (
          // @ts-ignore
          (typeof exercise?.initialData?.[key] === 'number' &&
            // @ts-ignore
            exercise?.initialData?.[key] !== initialData[key]) ||
          !exercise?.initialData
        ) {
          return true
        }

        return res
      }, false)

      await editExercise({
        ...exercise,
        group: exerciseGroup.id,
        link,
        weightStep,
        ...(isDataChanged && { initialData }),
      })
      setIsEdit(false)
      setIsDirty(false)
    }
  }

  const handleEditClick = () => {
    setIsEdit(true)
  }

  const handleEditTranslationConfirm = async (props: EditTranslationData) => {
    if (updateTranslation) {
      const { dictionary, key, values } = props
      await updateTranslation(dictionary, { [key]: values })
      setEditTranslation(null)
    }
  }

  return (
    <PageWrapper className="page exercises-page">
      <Typography variant="h6">{t(`exercise-title-${exercise.id}`)}</Typography>
      <small>({t(`group-title-${exerciseGroup.id}`)})</small>
      {(isTrainer(user?.roles) || isAdmin(user?.roles)) && !isEdit && (
        <IconButton
          size="small"
          color="primary"
          sx={{ position: 'absolute', zIndex: 2, top: 20, right: 20 }}
          onClick={handleEditClick}
          disabled={isLoading}
        >
          <EditIcon sx={{ width: 20, height: 20 }} />
        </IconButton>
      )}

      <Box sx={{ mt: 4 }}>
        {isEdit ? (
          <TextField
            value={values.link}
            size="small"
            label={t('link-to-video')}
            onChange={(e) => {
              handleChange({ type: 'link', value: e.target.value })
            }}
            fullWidth={true}
            disabled={isLoading}
          />
        ) : exercise.link ? (
          <Box>
            {t('app-exercises-video')}:
            <VideoButton link={exercise.link} />
          </Box>
        ) : null}
      </Box>
      {(isTrainer(user?.roles) || isAdmin(user?.roles)) && (
        <Box sx={{ maxWidth: '60%', mt: 5 }}>
          <Box sx={{ fontWeight: 700 }}>{t('profile-settings')}:</Box>

          <Box sx={{ my: 2 }}>
            {isEdit ? (
              <FormControl sx={{ mt: 1, width: '47%' }}>
                <InputLabel>{t('app-workout-weightStep')}</InputLabel>
                <Select
                  size="small"
                  value={values.weightStep}
                  onChange={(e) => {
                    handleChange({
                      type: 'weightStep',
                      value: e.target.value,
                    })
                  }}
                  input={
                    <OutlinedInput label={t('app-workout-data-weightStep')} />
                  }
                  disabled={isLoading}
                >
                  <MenuItem value={0}>—</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Box>
                {t('app-workout-weightStep')}: {values.weightStep || '—'}
              </Box>
            )}
          </Box>

          {values.reps && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {isEdit ? (
                <FormControl sx={{ mt: 1, width: '47%' }}>
                  <InputLabel>{t('app-workout-data-reps')}</InputLabel>
                  <Select
                    size="small"
                    value={values.reps}
                    onChange={(e) => {
                      handleChange({ type: 'reps', value: e.target.value })
                    }}
                    input={<OutlinedInput label={t('app-workout-data-reps')} />}
                    disabled={isLoading}
                  >
                    {repValues.map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Box>
                  {t('app-workout-data-reps')}: {values.reps}
                </Box>
              )}
              {values.weightStep > 0 &&
                (isEdit ? (
                  <FormControl sx={{ mt: 1, width: '47%' }}>
                    <InputLabel>{t('app-workout-data-weight')}</InputLabel>
                    <Select
                      size="small"
                      value={values.weight}
                      onChange={(e) => {
                        handleChange({ type: 'weight', value: e.target.value })
                      }}
                      input={
                        <OutlinedInput label={t('app-workout-data-weight')} />
                      }
                      disabled={isLoading}
                    >
                      {weights.map((el) => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Box>
                    {t('app-workout-data-weight')}: {values.weight}
                    {t('app-workout-data-kg').toLowerCase()}
                  </Box>
                ))}
            </Box>
          )}

          {values.length && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {isEdit ? (
                <FormControl sx={{ mt: 1, width: '47%' }}>
                  <InputLabel>
                    {exerciseGroup.id.includes('core')
                      ? t('app-workout-data-sec')
                      : t('app-workout-data-min')}
                  </InputLabel>
                  <Select
                    size="small"
                    value={values.length}
                    onChange={(e) => {
                      handleChange({ type: 'length', value: e.target.value })
                    }}
                    input={
                      <OutlinedInput
                        label={
                          exerciseGroup.id.includes('core')
                            ? t('app-workout-data-sec')
                            : t('app-workout-data-min')
                        }
                      />
                    }
                    disabled={isLoading}
                  >
                    {(exerciseGroup.id.includes('core')
                      ? machineWeights
                      : freeWeights
                    ).map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Box>
                  {t('app-workout-data-duration')}: {values.length}{' '}
                  {(exerciseGroup.id.includes('core')
                    ? t('app-workout-data-sec')
                    : t('app-workout-data-min')
                  ).toLowerCase()}
                </Box>
              )}
              {values.speed &&
                (isEdit ? (
                  <FormControl sx={{ mt: 1, width: '47%' }}>
                    <InputLabel>{t('app-workout-data-speed')}</InputLabel>
                    <Select
                      size="small"
                      value={values.speed}
                      onChange={(e) => {
                        handleChange({ type: 'speed', value: e.target.value })
                      }}
                      input={
                        <OutlinedInput label={t('app-workout-data-speed')} />
                      }
                      disabled={isLoading}
                    >
                      {repValues.map((el) => (
                        <MenuItem key={el} value={el}>
                          {el}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Box>
                    {t('app-workout-data-speed')}: {values.speed}
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      )}

      {(isTrainer(user?.roles) || isAdmin(user?.roles)) && !isEdit && (
        <Box sx={{ mt: 3, position: 'relative' }}>
          <IconButton
            size="small"
            color="primary"
            sx={{ position: 'absolute', zIndex: 2, right: 4, top: 20 }}
            onClick={() => {
              setEditTranslation({
                dictionary: 'exercises',
                key: `exercise-title-${exercise?.id}`,
                values: dictionaryExercises[`exercise-title-${exercise?.id}`],
              })
            }}
            disabled={isLoading}
          >
            <EditIcon sx={{ width: 20, height: 20 }} />
          </IconButton>

          <Divider sx={{ mb: 3 }} />
          {LANGUAGES.map((key) => (
            <Box
              key={key}
              className="flex-row"
              sx={{ justifyContent: 'flex-start', mt: 1 }}
            >
              <b style={{ marginRight: '8px', opacity: 0.6 }}>
                {String(key === 'uk' ? UA_KEY : key).toUpperCase()}:
              </b>
              <Box>{t(`exercise-title-${exercise.id}`, key)}</Box>
            </Box>
          ))}
        </Box>
      )}

      {isEdit && (
        <Box
          sx={{
            mt: 6,
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <Button
            sx={{ mr: 2 }}
            color="inherit"
            onClick={handleCancelClick}
            disabled={isLoading}
          >
            {t('workout-edit-buttons-cancel')}
          </Button>
          <Button
            disabled={!isDirty || isLoading}
            color="warning"
            variant="contained"
            onClick={handleSaveClick}
          >
            {t('workout-edit-buttons-save')}
          </Button>
        </Box>
      )}

      {editTranslation && (
        <EditTranslationDialog
          handleClose={() => {
            setEditTranslation(null)
          }}
          handleConfirm={handleEditTranslationConfirm}
          data={editTranslation}
        />
      )}
    </PageWrapper>
  )
}

export default ExerciseItemPage
