import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material'
import OpenAI from 'openai'

import { UserContext, DataContext, WorkoutsContext } from '../../store'
import useTranslations from '../../hooks/useTranslations'
import { normalizeGeneratedWorkout } from '../../helpers'

const CODE_EXAMPLE = (userId: string) => `{
  "date": "",
  "status": 0,
  "user_id": "${userId}",
  "exercises": [
    {
      "id": "cardio_3_0",
      "title": "Орбитрек",
      "exercise_id": "cardio_3",
      "type": "warmup",
      "sets": [
        {
          "id": "cardio_3_0_1",
          "data": { "length": 7 }
        }
      ],
      "status": 0
    },
    {
      "id": "back_2_1",
      "title": "Подтягивания в гравитроне",
      "exercise_id": "back_2",
      "sets": [
        {
          "id": "back_2_1_1",
          "data": { "reps": 12, "weight": 40 }
        },
        {
          "id": "back_2_1_2",
          "data": { "reps": 12, "weight": 40 }
        },
        {
          "id": "back_2_1_3",
          "data": { "reps": 12, "weight": 40 }
        },
      ],
      "status": 0
    }, 
    ...
  ]
}`

interface Props {
  setIsOpen: (key: boolean) => void
  date: string
}

const WorkoutGenerator: React.FC<Props> = ({ setIsOpen, date }) => {
  const { user, activeClient } = useContext(UserContext)
  const { exercises } = useContext(DataContext)
  const { updateEditWorkout } = useContext(WorkoutsContext)
  // const navigate = useNavigate()
  const { t } = useTranslations()

  const [loading, setLoading] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [prompt, setPrompt] = useState<string>('')

  const exData = exercises?.groups.reduce(
    (res: { [key: string]: string }, gr) => {
      gr.exercises.forEach((ex) => {
        res[ex.title] = ex.id
      })

      return res
    },
    {}
  )

  useEffect(() => {
    if (text && exData) {
      setPrompt(
        `Return a workout plan based on input: """${text}""". If not mentioned in input, warmup is always an "orbitrek 7 minutes". Provide your response as a JSON object. Here's an output example: """${CODE_EXAMPLE(
          (activeClient || user?.uuid) ?? ''
        )}""". Exercises titles should correspond to this list of exercises: """${JSON.stringify(
          Object.keys(exData)
        )}"""; keep all ID fields empty, status should be 0, every exercises should have 4 sets (sometimes 5 for warmup)`
      )
    }
  }, [text, activeClient, user])

  const handleGenerate = async () => {
    setLoading(true)
    const apiKey = user?.settings?.openai_secret
    const openai = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true,
    })

    try {
      const callOpenApi = async () => {
        const response = await openai.chat.completions.create({
          model: 'gpt-4o',
          messages: [
            {
              role: 'system',
              content: 'You are a helpful assistant.',
            },
            { role: 'user', content: prompt },
          ],
          temperature: 0,
          response_format: { type: 'json_object' },
          max_tokens: 4096,
        })

        const { content } = response.choices[0].message

        if (content) {
          const data = JSON.parse(content)

          if (data && exData && updateEditWorkout) {
            const normalizedData = normalizeGeneratedWorkout(data, exData)

            updateEditWorkout({
              ...normalizedData,
              date,
              uuid: 'new_ai',
              user_id: String(activeClient || user?.uuid),
            })
            setIsOpen(false)
          }
        }
      }

      await callOpenApi()
    } catch (error) {
      console.error('Error generating response:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={true} fullScreen={true}>
      <DialogContent>
        <div>
          {loading && (
            <Box
              sx={{
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 3,
              }}
            >
              <LinearProgress color="primary" sx={{ height: '3px' }} />
            </Box>
          )}

          <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
            Увага, ця функція є експериментальною.
            <br />
            ШІ може робити помилки, тому перевірте та скорегуйте результат за
            потреби.
            <br />
            <br />
          </Typography>

          <div>
            <TextField
              sx={{ fontSize: '90%' }}
              fullWidth={true}
              multiline={true}
              // placeholder={t('app-add-import-placeholder')}
              placeholder={`Разминка на орбитреке 7 минут 
              
1. Подтягивания в гравитроне 
4х12-10-10-8 
40-45-35-30

2. Тяга верхнего блока к груди 4х12 вес 35-40 кг

3. Тяга с канатом за голову 4х15 вес 35-45 кг

4. Жим в тренажере сидя вверх 4х12 вес 25-30 кг

5. Подъем блина перед собой 4х10 вес 10 кг (5 кг)

6. Махи в стороны лежа на скамье вес 4-5 кг

7. Пресс на мяче 4х20 

Кардио на лесенке 15 минут скорость 6 
или дорожка 20 минут скорость 6 угол 10
или орбитрек 20 минут скорость 14`}
              rows={18}
              disabled={loading}
              onChange={(e) => setText(e.target.value)}
            />

            <Typography variant="body2">{text.length}</Typography>
          </div>

          <div className="page-buttons" style={{ marginTop: 20 }}>
            <Button
              onClick={() => {
                setIsOpen(false)
              }}
              color="inherit"
            >
              {t('app-button-add-import-cancel')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleGenerate}
              disabled={loading || !text}
            >
              {t('app-button-add-import-generate')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default WorkoutGenerator
