import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { DataContext, UserContext } from '../../store'
import useTranslations from '../../hooks/useTranslations'
import useExercises from '../../hooks/useExercises'

import PageWrapper from '../../containers/PageWrapper'
import WeightsChart from '../../components/WeightsChart'

import '../../styles/profile.scss'

const StatsPage: React.FC = () => {
  const { user } = useContext(UserContext)
  const { fetchWorkoutsArchive, fetchClientWorkouts, exercises } =
    useContext(DataContext)
  const { t } = useTranslations()
  const { clientId } = useParams()
  const { options, value, setValue } = useExercises({})

  const weights = user?.statsData?.weights

  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    if (clientId) {
      fetchClientWorkouts && fetchClientWorkouts(clientId)
    } else {
      fetchWorkoutsArchive && fetchWorkoutsArchive()
    }
  }, [])

  if (!user || !options) {
    return null
  }

  return (
    <PageWrapper className="page stats-page">
      <Box className="flex-row">
        <Typography variant="subtitle1" sx={{ mb: 2, fontSize: 18 }}>
          {t('page-title-stats')}
        </Typography>
      </Box>

      <TextField
        size="small"
        fullWidth={true}
        autoFocus={true}
        // onChange={(e) => {
        //   setSearch(e.target.value)
        // }}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Divider sx={{ my: 2 }} />

      <Box sx={{ mb: 3 }}>
        {options.length > 0 ? (
          options.map((g) => {
            return (
              <React.Fragment key={g.id}>
                {g.exercises.map((e) => {
                  if (!weights?.[e.id]) {
                    return null
                  }

                  return (
                    <WeightsChart
                      key={e.id}
                      id={e.id}
                      data={weights[e.id].sort((a, b) =>
                        a.date < b.date ? -1 : 1
                      )}
                    />
                  )
                })}
              </React.Fragment>
            )
          })
        ) : (
          <Box sx={{ px: 2, py: 1.5 }}>
            <Typography>{t('find-exercise-dialog-no-results')}</Typography>
          </Box>
        )}
      </Box>
    </PageWrapper>
  )
}

export default StatsPage
