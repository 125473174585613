import React, { useState } from 'react'
import { Button } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import ExportDialog from '../Dialogs/ExportDialog'

interface Props {}

const ExportAction: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button
        sx={{ marginLeft: 'auto' }}
        startIcon={<ShareIcon />}
        variant="outlined"
        onClick={() => {
          setIsOpen(true)
        }}
      >
        Export
      </Button>
      {isOpen && <ExportDialog handleClose={handleClose} />}
    </>
  )
}

export default ExportAction
