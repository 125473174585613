import { Exercise, ExerciseData, NormalizedWorkout, Workout } from '../types'
import { ROLES } from './constants'

export const helper = () => {}

export const capitalizeFirstLetter: (str: string) => string = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const deepClone = (obj: Workout): Workout => {
  return JSON.parse(JSON.stringify(obj))
}

export const normalizeWorkout = (
  workout: Workout,
  exercises: ExerciseData,
  t: (key: string) => string
) => {
  let data: NormalizedWorkout = {
    date: workout.date,
    createdOn: workout.created_on,
    uuid: workout.uuid,
    groups: [],
    status: workout.status,
    rating: workout.rating,
    startTime: workout.startTime,
  }

  workout.exercises.forEach((ex) => {
    const group = exercises?.groups.find((g) => ex.id.includes(g.id))
    if (
      group &&
      !data.groups.includes(t(`group-title-${group.group_name_id}`)) &&
      group?.group_name_id !== 'core' &&
      group?.group_name_id !== 'cardio'
    ) {
      data.groups.push(t(`group-title-${group.group_name_id}`))
    }
  })

  return data
}

export const normalizeWorkouts = (
  workouts: Workout[],
  exercises: ExerciseData,
  t: (key: string) => string
) => {
  return workouts?.map((w) => {
    return normalizeWorkout(w, exercises, t)
  })
}

export const findLastIndexWithProperty = (
  arr: Exercise[],
  propertyName: keyof Exercise,
  propertyValue: string | number
) => {
  let lastIndex = -1

  arr.reduce((index, item, currentIndex) => {
    if (item[propertyName] === propertyValue) {
      lastIndex = currentIndex
    }
    return lastIndex
  }, -1)

  return lastIndex
}

export const normalizeGeneratedWorkout = (
  data: Workout,
  exData: { [key: string]: string }
) => {
  data.exercises.forEach((item, index) => {
    Object.keys(exData).forEach((title) => {
      if (
        title &&
        item.title &&
        title.toLowerCase() === item.title.toLowerCase()
      ) {
        item.exercise_id = exData[title]
        item.id = `${item.exercise_id}_${index + 1}`

        item.sets.forEach((set, idx) => {
          set.id = `${item.id}_${idx + 1}`
        })
      }
    })
  })

  return data
}

export const isAdmin = (roles?: string[]) => roles?.includes(ROLES.ADMIN)

export const isClient = (roles?: string[]) => roles?.includes(ROLES.CLIENT)

export const isTrainer = (roles?: string[]) => roles?.includes(ROLES.TRAINER)

export const isAllowedToAddClient = (roles?: string[]) =>
  roles?.includes(ROLES.TRAINER)

export const isAllowedToAddTrainer = (roles?: string[]) =>
  roles?.includes(ROLES.SUPER_TRAINER)
