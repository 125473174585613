import React, { useContext } from 'react'
import {
  AppBar as MuiAppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import Breadcrumbs from '../Breadcrumbs'

import Countdown from '../Countdown'
import { UserContext, WorkoutsContext } from '../../store'
import routes from '../../helpers/routes'

import '../../styles/app.scss'
import Avatar from '../Avatar'

const AppBar: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { currentWorkout } = useContext(WorkoutsContext)
  const { user } = useContext(UserContext)

  const isActiveWorkoutPage = pathname === routes.activeWorkout

  return (
    <Box sx={{ flexGrow: 1 }} className="app-header">
      <MuiAppBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Breadcrumbs />

          <Typography
            variant="subtitle1"
            component="div"
            sx={{ justifyContent: 'flex-end' }}
          >
            {isActiveWorkoutPage && currentWorkout?.startTime && (
              <Countdown startTime={currentWorkout.startTime} />
            )}
          </Typography>

          {user && (
            <IconButton
              sx={{ mr: -1 }}
              onClick={() => {
                navigate(routes.profile)
              }}
            >
              <Avatar user={user} size={32} />
            </IconButton>
          )}
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}

export default AppBar
