import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useTranslations from '../../hooks/useTranslations'
import {
  Box,
  Checkbox,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { UserData } from '../../types'
import {
  DEFAULT_LOCALE,
  LANGUAGES,
  LANGUAGES_FULL,
  TIMER,
} from '../../helpers/constants'
import { UserContext } from '../../store'

interface Props {
  onClose: () => void
}

const EditSettingsDialog: React.FC<Props> = ({ onClose }) => {
  const { user, locale, updateUser } = useContext(UserContext)
  const { t } = useTranslations()

  const [values, setValues] = useState<{
    autoStart: boolean
    locale: string
    timer: number
  }>({
    autoStart: user?.settings?.exercises?.autoStart || false,
    locale,
    timer: user?.settings?.timer || TIMER,
  })

  useEffect(() => {
    if (user?.settings) {
      setValues({
        autoStart: user.settings.exercises.autoStart || false,
        locale,
        timer: user.settings.timer || TIMER,
      })
    }
  }, [user])

  const handleChangeLocale = () => {
    setValues((prevState) => ({
      ...prevState,
      autoStart: !prevState.autoStart,
    }))
  }

  const handleChangeTimer = (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({ ...prevState, timer: Number(e.target.value) }))
  }

  const handleChangeAutoStart = (e: SelectChangeEvent) => {
    setValues((prevState) => ({ ...prevState, locale: e.target.value }))
  }

  const handleSaveClick = () => {
    if (user && updateUser) {
      const newUserData: UserData = {
        ...user,
        settings: {
          ...user.settings,
          timer: values.timer,
          locale: values.locale,
          exercises: {
            autoStart: values.autoStart,
          },
        },
      }

      updateUser(newUserData)
    }

    onClose()
  }

  const handleCancelClick = () => {
    onClose()
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('profile-settings')}</DialogTitle>
      <DialogContent>
        <Box>
          <Box className="profile-section-item">
            <span>{t('profile-autostart')}:</span>
            <Checkbox
              checked={values.autoStart}
              onChange={handleChangeLocale}
            />
          </Box>
          <Box className="profile-section-item">
            <span>{t('profile-rest-delay')}:</span>
            <TextField
              value={String(values.timer)}
              size="small"
              sx={{ maxWidth: 100 }}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t('units-sec')}.
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeTimer}
            />
          </Box>
          <Box className="profile-section-item">
            <span>{t('profile-language')}:</span>
            <Select
              size="small"
              value={values.locale}
              onChange={handleChangeAutoStart}
            >
              {LANGUAGES.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {LANGUAGES_FULL[lang]}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button color="inherit" onClick={handleCancelClick}>
          {t('workout-edit-buttons-cancel', values.locale)}
        </Button>
        <Button color="warning" variant="contained" onClick={handleSaveClick}>
          {t('workout-edit-buttons-save', values.locale)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditSettingsDialog
