import React, { useState } from 'react'
import { Fab, Menu } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AddWeight from '../AddWeight'

const FloatMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        onClick={handleClick}
        sx={{ position: 'fixed', right: 16, bottom: 16 }}
      >
        <AddIcon />
      </Fab>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ '& ul': { p: 0 } }}
      >
        <AddWeight onClose={handleClose} />
      </Menu>
    </>
  )
}

export default FloatMenu
