import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'

import { freeWeights, machineWeights, repValues } from '../../helpers/constants'
import { Set, SetData } from '../../types'
import useTranslations from '../../hooks/useTranslations'
import useWeights from '../../hooks/useWeights'

interface Props {
  handleCloseClick: () => void
  handleSaveClick: (data: SetData[]) => void
  open: boolean
  idx: number
  sets: Set[]
  exerciseIds: string[]
  titles: string[]
}

const SupersetConfirmDialog: React.FC<Props> = ({
  open,
  idx,
  handleCloseClick,
  handleSaveClick,
  sets,
  exerciseIds,
  titles,
}) => {
  const [results, setResults] = useState<SetData[]>(
    sets.map((set) => ({ ...set.data }))
  )
  const { t } = useTranslations()
  const weights = [
    useWeights({ exerciseId: exerciseIds[0] }).weights,
    useWeights({ exerciseId: exerciseIds[1] }).weights,
  ]

  const handleChange = (
    {
      type,
      value,
    }: {
      type: string
      value: string | number
    },
    idx: number
  ) => {
    setResults((prevState) => {
      const newState = [...prevState]
      const setData: SetData = newState[idx]
      // @ts-ignore
      setData[type] = Number(value)

      return newState
    })
  }

  const handleCommentChange = (comment: string) => {
    setResults((prevState) => {
      const newState = [...prevState]
      newState[0].comment = comment

      return newState
    })
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        {t('app-set-complete-dialog-title')} #{idx + 1}
      </DialogTitle>
      <DialogContent>
        {sets.map((set, idx) => (
          <Box
            key={idx}
            component="form"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                flexBasis: '100%',
                pt: idx > 0 ? 3 : 1,
                pb: 1,
                fontSize: '90%',
              }}
            >
              {titles[idx]}:
            </Box>

            {set.data.reps && (
              <FormControl sx={{ mt: 1, width: '47%' }}>
                <InputLabel>{t('app-workout-data-reps')}</InputLabel>
                <Select
                  size="small"
                  value={results[idx].reps}
                  onChange={(e) => {
                    handleChange({ type: 'reps', value: e.target.value }, idx)
                  }}
                  input={<OutlinedInput label={t('app-workout-data-reps')} />}
                >
                  {repValues.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {set.data.weight && (
              <FormControl sx={{ mt: 1, width: '47%' }}>
                <InputLabel>{t('app-workout-data-weight')}</InputLabel>
                <Select
                  size="small"
                  value={results[idx].weight}
                  onChange={(e) => {
                    handleChange({ type: 'weight', value: e.target.value }, idx)
                  }}
                  input={<OutlinedInput label={t('app-workout-data-weight')} />}
                >
                  {weights[idx].map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {set.data.length && (
              <FormControl sx={{ mt: 1, width: '47%' }}>
                <InputLabel>
                  {set.id.includes('core')
                    ? t('app-workout-data-sec')
                    : t('app-workout-data-min')}
                </InputLabel>
                <Select
                  size="small"
                  value={results[idx].length}
                  onChange={(e) => {
                    handleChange({ type: 'length', value: e.target.value }, idx)
                  }}
                  input={
                    <OutlinedInput
                      label={
                        set.id.includes('core')
                          ? t('app-workout-data-sec')
                          : t('app-workout-data-min')
                      }
                    />
                  }
                >
                  {(set.id.includes('core') ? machineWeights : freeWeights).map(
                    (el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            {set.data.speed && (
              <FormControl sx={{ mt: 1, width: '47%' }}>
                <InputLabel>{t('app-workout-data-speed')}</InputLabel>
                <Select
                  size="small"
                  value={results[idx].speed}
                  onChange={(e) => {
                    handleChange({ type: 'speed', value: e.target.value }, idx)
                  }}
                  input={<OutlinedInput label={t('app-workout-data-speed')} />}
                >
                  {repValues.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {idx > 0 && (
              <TextField
                sx={{ mt: 4 }}
                fullWidth={true}
                multiline={true}
                label={t('app-workout-comment')}
                onChange={(e) => {
                  handleCommentChange(e.target.value)
                }}
              />
            )}
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ m: 2, mt: 0 }}>
        <Button color="inherit" onClick={handleCloseClick}>
          {t('app-button-dialog-close')}
        </Button>
        <Button
          onClick={() => handleSaveClick(results)}
          color="primary"
          disableElevation={true}
          autoFocus={true}
          variant="contained"
        >
          {t('workout-edit-buttons-save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SupersetConfirmDialog
