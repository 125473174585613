import { useContext, useEffect, useState } from 'react'
import { UserData } from '../types'
import { UserContext } from '../store'
import { useParams } from 'react-router-dom'

const useClients = () => {
  const { clients } = useContext(UserContext)
  const { clientId } = useParams()
  const [client, setClient] = useState<UserData>()

  useEffect(() => {
    if (clients && clientId) {
      if (clients.some((el) => el.uuid === clientId)) {
        setClient(clients.find((el) => el.uuid === clientId))
      }
    }
  }, [clients, clientId])

  return {
    client,
  }
}

export default useClients
