import { useContext, useEffect, useMemo, useState } from 'react'
import { DataContext } from '../store'
import { DataExerciseGroup } from '../types'
import useTranslations from './useTranslations'

const useExercises = ({
  isCardio,
  group,
}: {
  isCardio?: boolean
  group?: string
}) => {
  const { exercises } = useContext(DataContext)
  const [options, setOptions] = useState<DataExerciseGroup[]>(
    exercises?.groups ?? []
  )
  const [value, setValue] = useState<string>()
  const { t } = useTranslations()

  const prepareData = useMemo(
    () => (data: DataExerciseGroup[], value?: string) => {
      return data.reduce(
        (acc: DataExerciseGroup[], item: DataExerciseGroup) => {
          const data = { ...item }

          data.exercises = item.exercises?.filter(({ id, title }) => {
            return value
              ? t(`group-title-${item.id}`)
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                  t(`exercise-title-${id}`)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                  title.toLowerCase().includes(value.toLowerCase())
              : id
          })

          if (!value || (value && data.exercises?.length > 0)) {
            if (isCardio) {
              if (data.id === 'cardio') {
                acc.push(data)
              }
            } else {
              if (data.exercises.length > 0) {
                acc.push(data)
              }
            }
          }

          return acc
        },
        []
      )
    },
    [isCardio]
  )

  useEffect(() => {
    if (exercises?.groups) {
      const data = exercises?.groups.filter((el) =>
        group ? el.id === group : !group
      )

      if (value) {
        setOptions(prepareData(data, value))
      } else {
        setOptions(prepareData(data))
      }
    }
  }, [exercises?.groups, group, value])

  return { options, value, setValue }
}

export default useExercises
