import React, { useContext } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { UserContext } from '../../store'
import { DATE_FORMAT_DATA } from '../../helpers/constants'

interface Props {
  onChange: (date: string) => void
  onClose?: () => void
  disabled?: boolean
  initialValue?: string
  value?: string
  disablePast?: boolean
  disableFuture?: boolean
}

const BasicDatePicker: React.FC<Props> = ({
  onChange,
  onClose,
  disabled,
  initialValue,
  value,
  disablePast = false,
  disableFuture = false,
}) => {
  const { locale } = useContext(UserContext)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        sx={{ width: '100%', mb: 2 }}
        onChange={(e) => {
          onChange(dayjs(String(e)).format(DATE_FORMAT_DATA))
        }}
        format="DD.MM.YYYY"
        onClose={onClose}
        closeOnSelect={true}
        disablePast={disablePast}
        disableFuture={disableFuture}
        disabled={disabled}
        value={value ? dayjs(value) : null}
        defaultValue={initialValue ? dayjs(initialValue) : null}
        slotProps={{ textField: { size: 'small' } }}
      />
    </LocalizationProvider>
  )
}

export default BasicDatePicker
