import React, { ChangeEvent, useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import useTranslations from '../../hooks/useTranslations'
import { UserContext } from '../../store'

interface Props {
  handleClose: () => void
}

const AddClientDialog: React.FC<Props> = ({ handleClose }) => {
  const { t } = useTranslations()
  const { createNewClient, userId } = useContext(UserContext)

  const [values, setValues] = useState<{ [key: string]: string }>()

  const handleSetValues =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setValues((prevState: any) => ({
        ...prevState,
        [key]: e.target.value,
      }))
    }

  const handleConfirmClick = async () => {
    if (createNewClient && values) {
      const clientId = await createNewClient(userId, values)

      if (clientId) {
        console.log(clientId)
      }
    }
  }

  return (
    <Dialog
      open={true}
      fullScreen={true}
      sx={{
        '& .MuiPaper-root': {
          background: (theme) => theme.palette.background.paper,
        },
      }}
    >
      <DialogTitle
        sx={{ background: (theme) => theme.palette.primary.dark }}
        id="alert-dialog-title"
      >
        {t('add-client')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 4 }}>
          <TextField
            size="small"
            onChange={handleSetValues('first_name')}
            fullWidth={true}
            label="First name"
          />
        </Box>
        <Box sx={{ pt: 2 }}>
          <TextField
            size="small"
            onChange={handleSetValues('last_name')}
            fullWidth={true}
            label="Last name"
          />
        </Box>
        <Box sx={{ pt: 2 }}>
          <TextField
            size="small"
            onChange={handleSetValues('email')}
            fullWidth={true}
            label="Email"
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit">
          {t('app-button-dialog-no')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          autoFocus={true}
          variant="contained"
          color="warning"
        >
          {t('app-button-dialog-yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddClientDialog
