import React, { ChangeEvent, useContext, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import useTranslations from '../../hooks/useTranslations'
import { UserContext } from '../../store'
import { WeightHistoryEntry } from '../../types'
import dayjs from 'dayjs'
import DatePicker from '../DatePicker'
import { DATE_FORMAT_DATA } from '../../helpers/constants'

interface Props {
  handleClose: () => void
  handleConfirm: (data: WeightHistoryEntry) => void
}

const AddWeightDialog: React.FC<Props> = ({ handleClose, handleConfirm }) => {
  const { user } = useContext(UserContext)
  const { t } = useTranslations()

  const [values, setValues] = useState<WeightHistoryEntry>({
    value: 0,
    date: dayjs().format(DATE_FORMAT_DATA),
  })

  const handleSetValues =
    (key: string) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      setValues((prevState) => ({
        ...prevState,
        [key]: typeof e === 'string' ? e : Number(e.target.value),
      }))
    }

  const handleConfirmClick = () => {
    handleConfirm(values)
  }

  return (
    <Dialog open={true}>
      <DialogTitle id="alert-dialog-title">{t('add-weight')}</DialogTitle>
      <DialogContent>
        <DatePicker
          onChange={handleSetValues('date')}
          value={values.date}
          disablePast={false}
          disableFuture={true}
        />

        <TextField
          value={values.value ? String(values.value) : ''}
          type="number"
          size="small"
          onChange={handleSetValues('value')}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit">
          {t('app-button-dialog-no')}
        </Button>
        <Button
          onClick={handleConfirmClick}
          autoFocus={true}
          variant="contained"
          color="warning"
          disabled={!(values.value && values.date)}
        >
          {t('app-button-dialog-yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddWeightDialog
