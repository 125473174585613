import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  DataExerciseGroup,
  ExerciseData,
  NewExerciseData,
  Status,
  Workout,
} from '../types'

import { exercisesAPI, workoutsAPI } from '../api'
import { TranslationsContext, UserContext } from '.'

interface ContextType {
  exercises?: ExerciseData
  workouts?: Workout[]
  workoutsArchive?: Workout[]
  setWorkouts?: (data?: Workout[]) => void
  setWorkoutsArchive?: (data?: Workout[]) => void
  createWorkout?: (data: Workout) => Promise<any>
  updateWorkout?: (data: Workout) => Promise<any>
  deleteWorkout?: (uuid: string, isArchive: boolean) => void
  duplicateWorkout?: (uuid: string, isArchive: boolean) => Promise<any>
  archiveWorkout?: (uuid: string) => void
  unarchiveWorkout?: (uuid: string) => void
  addNewExercise?: (data: NewExerciseData, userId?: string) => Promise<any>
  editExercise?: (data: NewExerciseData) => Promise<any>
  findExerciseGroup: (uuid: string) => DataExerciseGroup | undefined
  fetchClientWorkouts?: (uuid: string, fetchAll?: boolean) => Promise<any>
  fetchWorkoutsArchive?: () => void
  fetchWorkouts?: () => void
  isLoading?: boolean
  isAppReady?: boolean
}

export const DataProvider = ({ children }: { children: ReactNode }) => {
  const { user, userId, updateUser, activeClient, updateUserStats } =
    useContext(UserContext)
  const { isLoaded: isDictionaryLoaded } = useContext(TranslationsContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [workouts, setWorkouts] = useState<Workout[]>()
  const [workoutsArchive, setWorkoutsArchive] = useState<Workout[]>()
  const [isAppReady, setIsAppReady] = useState<boolean>(false)
  const [exercises, setExercises] = useState<ExerciseData>()
  const [userIdToUpdateStats, setUserIdToUpdateStats] = useState<string>('')

  const fetchWorkouts = useCallback(async () => {
    setIsLoading(true)
    if (user) {
      try {
        const workouts = await workoutsAPI().getWorkouts(user.uuid)

        setWorkouts(workouts)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }, [user])

  const fetchClientWorkouts = useCallback(
    async (uuid: string, fetchAll?: boolean) => {
      setIsLoading(true)
      if (uuid) {
        try {
          const workouts = await workoutsAPI().getWorkouts(uuid)

          setWorkouts(workouts)

          if (fetchAll) {
            const workoutsArchive = await workoutsAPI().getWorkoutArchive(uuid)
            setWorkoutsArchive(workoutsArchive)

            return { workouts, workoutsArchive }
          }

          return { workouts }
        } catch (err) {
          console.error(err)
        } finally {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    },
    [activeClient, user]
  )

  const addNewExercise = useCallback(
    async (data: NewExerciseData, userId?: string) => {
      if (user && exercises) {
        setIsLoading(true)
        try {
          const { group, ...values } = data

          const exGroup = userId
            ? user.userExercises?.groups.find((el) => el.id === group)
            : exercises.groups.find((el) => el.id === group)

          if (exGroup) {
            exGroup.exercises.push({
              id: `${group}_${userId}_${exGroup.exercises.length}`,
              title: data.title,
            })
          } else {
            const newGroup = {
              id: group,
              group_name_id: group,
              exercises: [
                {
                  id: `${group}_${userId}_0`,
                  ...values,
                },
              ],
            }

            if (userId) {
              if (!user.userExercises) {
                user.userExercises = {
                  groups: [newGroup],
                }
              } else {
                user.userExercises.groups.push(newGroup)
              }
            }
          }

          if (userId && updateUser) {
            await updateUser({
              ...user,
              updated_on: Date.now(),
            })

            if (user.userExercises) {
              updateExercises()
            }
          } else {
            const exerciseId = await exercisesAPI().updateExercise(group, {
              id: `${group}_${exGroup?.exercises.length || 0}`,
              ...values,
            })

            await fetchExercises()

            return exerciseId
          }
        } catch (err) {
          console.error(err)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [user, exercises]
  )

  const editExercise = useCallback(
    async (data: NewExerciseData) => {
      if (exercises) {
        setIsLoading(true)
        try {
          const { group, ...values } = data

          const exGroup = exercises.groups.find((el) => el.id === group)
          const exercise = exGroup?.exercises.find((el) => el.id === data.id)

          if (exercise) {
            const exerciseId = await exercisesAPI().updateExercise(group, {
              id: exercise.id,
              ...values,
            })

            await fetchExercises()

            return exerciseId
          }
        } catch (err) {
          console.error(err)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [user, exercises]
  )

  const fetchWorkoutsArchive = async () => {
    setIsLoading(true)
    if (userId) {
      try {
        const workoutsArchive = await workoutsAPI().getWorkoutArchive(userId)

        setWorkoutsArchive(workoutsArchive)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const archiveWorkout = async (uuid: string) => {
    setIsLoading(true)

    try {
      await workoutsAPI().archiveWorkout(uuid)

      setWorkouts((prevState) => prevState?.filter((el) => el.uuid !== uuid))
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const unarchiveWorkout = async (uuid: string) => {
    setIsLoading(true)
    try {
      await workoutsAPI().unarchiveWorkout(uuid)

      if (activeClient) {
        await fetchClientWorkouts(activeClient)
      } else {
        await fetchWorkouts()
      }

      setWorkoutsArchive(
        (prevState) => prevState?.filter((el) => el.uuid !== uuid)
      )
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const createWorkout = async (data: Workout) => {
    setIsLoading(true)
    try {
      const uuid = await workoutsAPI().createWorkout({
        ...data,
        created_on: Date.now(),
        deleted: false,
      })

      if (uuid) {
        if (activeClient) {
          await fetchClientWorkouts(activeClient)
        } else {
          await fetchWorkouts()
        }
      }

      return uuid
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const updateWorkout = async (data: Workout) => {
    setIsLoading(true)
    try {
      const uuid = await workoutsAPI().updateWorkout({
        ...data,
        updated_on: Date.now(),
      })

      if (uuid) {
        if (activeClient) {
          await fetchClientWorkouts(activeClient)
        } else {
          await fetchWorkouts()
        }
        if (data.status === Status.completed) {
          setUserIdToUpdateStats(data.user_id)
        }
      }

      return uuid
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteWorkout = async (uuid: string, isArchive: boolean) => {
    setIsLoading(true)
    try {
      await workoutsAPI().deleteWorkout(uuid, isArchive)

      if (activeClient) {
        await fetchClientWorkouts(activeClient)
      } else {
        if (isArchive) {
          await fetchWorkoutsArchive()
        } else {
          await fetchWorkouts()
        }
      }
      const userId = workouts?.find((w) => w.uuid === uuid)?.user_id

      if (userId) {
        setUserIdToUpdateStats(userId)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const duplicateWorkout = async (uuid: string, isArchive: boolean) => {
    setIsLoading(true)
    try {
      const id = await workoutsAPI().duplicateWorkout(uuid, isArchive)

      if (id) {
        if (activeClient) {
          await fetchClientWorkouts(activeClient)
        } else {
          await fetchWorkouts()
        }
      }

      return id
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const findExerciseGroup = (exercise_id: string) =>
    exercises?.groups.find((group) =>
      group.exercises.some((ex) => ex.id === exercise_id)
    )

  const fetchExercises = useCallback(async () => {
    try {
      const data = await exercisesAPI().getExercises()

      if (data) {
        setExercises(data)
      }
    } catch (err) {
      throw err
    }
  }, [])

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      await fetchExercises()
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (user?.uuid && isDictionaryLoaded) {
      fetchData()
    }
  }, [user, isDictionaryLoaded])

  useEffect(() => {
    if (!workouts && userId && isDictionaryLoaded) {
      fetchWorkouts()
    }
  }, [userId, isDictionaryLoaded])

  // useEffect(() => {
  //   if (updateUserStats && exercises) {
  //     updateUserStats(userId, {
  //       exercises,
  //       workouts,
  //       workoutsArchive,
  //     })
  //   }
  // }, [userId, exercises])

  useEffect(() => {
    if (userIdToUpdateStats && updateUserStats && exercises) {
      if (userIdToUpdateStats && exercises) {
        updateUserStats(userIdToUpdateStats, {
          exercises,
          workouts,
          workoutsArchive,
        })

        setUserIdToUpdateStats('')
      }
    }
  }, [
    userIdToUpdateStats,
    updateUserStats,
    exercises,
    workouts,
    workoutsArchive,
  ])

  const updateExercises = () => {
    if (exercises && user?.userExercises) {
      const exercisesCopy = JSON.parse(JSON.stringify(exercises))

      const groups =
        user?.userExercises?.groups && exercisesCopy.groups
          ? user.userExercises.groups.reduce(
              (result, item1) => {
                const matchingItem = result.find(
                  (item2) => item2.id === item1.id
                )

                if (matchingItem) {
                  matchingItem.exercises = [
                    ...matchingItem.exercises,
                    ...item1.exercises,
                  ]
                } else {
                  result.push({ ...item1 })
                }
                return result
              },
              [...exercisesCopy.groups]
            )
          : exercisesCopy.groups

      setExercises({ ...exercises, groups })
    }
  }

  useEffect(() => {
    if (userId && exercises) {
      setIsAppReady(true)
    }
  }, [userId, exercises])

  return (
    <DataContext.Provider
      value={{
        exercises,
        workouts,
        isLoading,
        isAppReady,
        setWorkouts,
        createWorkout,
        updateWorkout,
        deleteWorkout,
        workoutsArchive,
        fetchWorkoutsArchive,
        archiveWorkout,
        unarchiveWorkout,
        duplicateWorkout,
        findExerciseGroup,
        addNewExercise,
        editExercise,
        fetchWorkouts,
        fetchClientWorkouts,
        setWorkoutsArchive,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const DataContext = createContext<ContextType>({
  findExerciseGroup: () => undefined,
})
