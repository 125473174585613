import dayjs from 'dayjs'
import firebase from './firebase'
import { Status, Workout } from '../types'
import { APP_LOCALE } from '../helpers/constants'

const workoutsAPI = () => {
  const db = firebase.firestore()
  const workoutsCollection = db.collection('workouts')
  const workoutsArchiveCollection = db.collection('workoutsArchive')

  const createWorkout = async (data: Workout) => {
    console.log('API: createWorkout')

    try {
      const docRef = await workoutsCollection.add(data)
      await docRef.update({ uuid: docRef.id })

      console.log('New workout created with ID: ', docRef.id)

      return docRef.id
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const updateWorkout = async (data: Workout) => {
    console.log('API: updateWorkout')

    try {
      const { uuid } = data
      const docSnapshot = await workoutsCollection.doc(uuid).get()

      let docRef

      if (docSnapshot.exists) {
        docRef = workoutsCollection.doc(uuid)
      } else {
        docRef = workoutsArchiveCollection.doc(uuid)
      }

      await docRef.set(data)

      console.log(`Workout ${uuid} successfully updated`)

      return uuid
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  }

  const deleteWorkout = async (uuid: string, isArchive: boolean) => {
    console.log('API: deleteWorkout')

    try {
      const docRef = isArchive
        ? workoutsArchiveCollection.doc(uuid)
        : workoutsCollection.doc(uuid)

      const docSnapshot = await docRef.get()

      if (docSnapshot.exists) {
        const data = docSnapshot.data()

        await docRef.set({ ...data, deleted: true })
      }

      console.log(`Workout ${uuid} is softly deleted`)

      return uuid
    } catch (error) {
      console.error('Error updating document: ', error)
    }
    // try {
    //   const docRef = isArchive
    //     ? workoutsArchiveCollection.doc(uuid)
    //     : workoutsCollection.doc(uuid)
    //
    //   await docRef.delete()
    //
    //   console.log(`Workout ${uuid} deleted`)
    //
    //   return uuid
    // } catch (error) {
    //   console.error('Error deleting document: ', error)
    // }
  }

  const getWorkouts = async (userId: string) => {
    console.log('API: getWorkouts')

    try {
      const querySnapshot = await workoutsCollection
        .where('user_id', '==', userId)
        // .where('deleted', '==', false)
        .orderBy('date', 'desc')
        .get()

      let result: Workout[] = []

      querySnapshot.forEach((doc) => {
        const workout = doc?.data()

        if (workout) {
          if (
            dayjs(workout.date)
              .locale(APP_LOCALE)
              .isBefore(dayjs().locale(APP_LOCALE).startOf('week')) ||
            workout.deleted
          ) {
            archiveWorkout(doc.id)
          } else {
            result.push({ uuid: doc.id, ...doc.data() } as Workout)
          }
        }
      })

      return result
    } catch (err) {
      throw err
    }
  }

  const archiveWorkout = async (uuid: string) => {
    console.log('API: archiveWorkout')

    try {
      const docSnapshot = await workoutsCollection.doc(uuid).get()

      if (docSnapshot.exists) {
        const data = docSnapshot.data()

        if (data) {
          await workoutsArchiveCollection.doc(uuid).set(data)
        }
      } else {
        console.error('Source document does not exist.')
      }

      console.log('Document archived successfully.')

      await workoutsCollection.doc(uuid).delete()
    } catch (error) {
      console.error('Error moving or deleting the document:', error)
    }
  }

  const duplicateWorkout = async (uuid: string, isArchive: boolean) => {
    console.log('API: duplicateWorkout')

    try {
      const collection = isArchive
        ? workoutsArchiveCollection
        : workoutsCollection

      const docSnapshot = await collection.doc(uuid).get()

      if (docSnapshot.exists) {
        const data = docSnapshot.data() as Workout
        const newWorkout = {} as Workout

        if (data) {
          newWorkout.exercises = data.exercises.map((ex) => {
            ex.status = Status.new
            delete ex.startTime
            delete ex.endTime
            ex.sets.forEach((set) => {
              delete set.results
            })
            return ex
          })
          newWorkout.date = ''
          newWorkout.status = Status.new
          newWorkout.deleted = false
          newWorkout.uuid = data.uuid
          newWorkout.user_id = data.user_id
          newWorkout.created_on = Date.now()

          const docRef = await workoutsCollection.add(newWorkout)
          await docRef.update({ uuid: docRef.id })

          console.log('Document duplicated with ID: ', docRef.id)

          return docRef.id
        }
      } else {
        console.error('Source document does not exist.')
      }

      console.log('Document archived successfully.')
    } catch (error) {
      console.error('Error moving or deleting the document:', error)
    }
  }

  const unarchiveWorkout = async (uuid: string) => {
    console.log('API: unarchiveWorkout')

    try {
      const docSnapshot = await workoutsArchiveCollection.doc(uuid).get()

      if (docSnapshot.exists) {
        const data = docSnapshot.data()
        if (data) {
          await workoutsCollection.doc(uuid).set(data)
        }
      } else {
        console.error('Source document does not exist.')
      }

      console.log('Document unarchived successfully.')

      await workoutsArchiveCollection.doc(uuid).delete()
    } catch (error) {
      console.error('Error moving or deleting the document:', error)
    }
  }

  const deleteArchivedWorkout = async (uuid: string) => {
    console.log('API: deleteArchivedWorkout')

    try {
      const docRef = workoutsArchiveCollection.doc(uuid)

      await docRef.delete()

      console.log(`Workout ${uuid} successfully deleted`)

      return uuid
    } catch (error) {
      console.error('Error deleting document: ', error)
    }
  }

  const getWorkoutArchive = async (userId: string, limit = 0) => {
    console.log('API: getWorkoutArchive')

    try {
      const querySnapshot = await workoutsArchiveCollection
        .where('user_id', '==', userId)
        .where('deleted', '==', false)
        .orderBy('date', 'desc')
        .limit(limit || 999)
        .get()

      let result: Workout[] = []

      querySnapshot.forEach((doc) => {
        if (doc?.data()) {
          result.push({ uuid: doc.id, ...doc.data() } as Workout)
        }
      })

      return result
    } catch (err) {
      throw err
    }
  }

  return {
    createWorkout,
    updateWorkout,
    deleteWorkout,
    getWorkouts,
    getWorkoutArchive,
    deleteArchivedWorkout,
    archiveWorkout,
    unarchiveWorkout,
    duplicateWorkout,
  }
}

export default workoutsAPI
