import React, { useContext } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Badge, Card } from '@mui/material'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import BlockIcon from '@mui/icons-material/Block'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'

import { DataContext, UserContext } from '../../store'

import '../../styles/module.scss'
import { DATE_FORMAT_DATA } from '../../helpers/constants'
import { Status } from '../../types'

interface Workout {
  date: Dayjs
  status: number
}

const Day = (
  props: PickersDayProps<Dayjs> & {
    workouts?: Workout[]
  }
) => {
  const { workouts = [], day, outsideCurrentMonth, ...other } = props

  const mapDays = (list: Workout[]) =>
    list
      .filter(({ date }) => {
        return date.year() === day.year() && date.month() === day.month()
      })
      .map(({ date }) => date.date())

  const completedDays = mapDays(
    workouts.filter(
      ({ date, status }) =>
        dayjs(date).isSameOrBefore(dayjs(), 'd') && status === Status.completed
    )
  )
  const skippedDays = mapDays(
    workouts.filter(
      ({ date, status }) =>
        dayjs(date).isSameOrBefore(dayjs(), 'd') && status === Status.new
    )
  )
  // const today = mapDays(workouts.filter((el) => dayjs(el).isSame(dayjs(), 'd')))
  // const upcomingDays = mapDays(
  //   workouts.filter((el) => dayjs(el).isAfter(dayjs(), 'd'))
  // )

  const foundDays = (days: number[]) =>
    !props.outsideCurrentMonth && days.indexOf(props.day.date()) >= 0

  const isCompleted = foundDays(completedDays)
  const isSkipped = foundDays(skippedDays)
  // const isToday = foundDays(today)
  // const isUpcoming = foundDays(upcomingDays)

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isCompleted ? (
          <EmojiEventsIcon color="warning" sx={{ width: 16, height: 16 }} />
        ) : isSkipped ? (
          <BlockIcon color="error" sx={{ width: 16, height: 16 }} />
        ) : undefined
      }
      sx={{
        '& *': {
          ...(isCompleted && { color: (theme) => theme.palette.warning.main }),
        },
      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  )
}

interface Props {
  onDateClick: (date: string, isArchive: boolean) => void
}

const Calendar: React.FC<Props> = ({ onDateClick }) => {
  const { locale } = useContext(UserContext)
  const { workouts, workoutsArchive } = useContext(DataContext)
  const workoutList = [...(workouts || []), ...(workoutsArchive || [])]?.map(
    ({ date, status }) => ({
      date: dayjs(date, 'YYYY-MM-DD'),
      status,
    })
  )

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={locale === 'en' ? 'en-gb' : locale}
    >
      <Card className="workouts-calendar">
        <DateCalendar
          sx={{ '& .MuiPickersCalendarHeader-root': { pl: 2, pr: 0 } }}
          displayWeekNumber={true}
          disableHighlightToday={true}
          slots={{ day: Day }}
          slotProps={{ day: { workouts: workoutList } as any }}
          disableFuture={true}
          onChange={(value) => {
            if (value) {
              const date = value.format(DATE_FORMAT_DATA)

              const uuid =
                workouts?.find((w) => w.date === date)?.uuid ||
                workoutsArchive?.find((w) => w.date === date)?.uuid
              const isArchive = Boolean(
                workoutsArchive?.some((w) => w.date === date)
              )

              if (uuid) {
                onDateClick(uuid, isArchive)
              }
            }
          }}
        />
      </Card>
    </LocalizationProvider>
  )
}

export default Calendar
