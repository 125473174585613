export interface StatsWeightData {
  [key: string]: number | string
}

export interface StatsData {
  groups?: { [group: string]: number }
  time?: number[]
  cardio?: { [key: string]: number }
  weights?: { [key: string]: StatsWeightData[] }
  completionRate?: { completed: number; all: number }
  workouts?: {
    lastCompleted?: {
      date: string
    }
    upcoming?: {
      date?: string
      groupTitles?: string[]
    }
  }
}

export interface NewExerciseData {
  title: string
  group: string
  link?: string
  id?: string
  weightStep?: number
  initialData?: SetData
}

export interface SetData {
  length?: number
  speed?: number
  reps?: number
  weight?: number
  comment?: string
}

interface Results extends SetData {
  time?: number
}

export interface Set {
  id: string
  data: SetData
  results?: Results
}

export interface Exercise {
  status?: Status
  id: string
  exercise_id: string
  type?: string
  sets: Set[]
  title?: string
  comments?: string
  startTime?: number
  endTime?: number
  superset?: string
}

export interface NormalizedWorkout {
  date: string
  createdOn?: number
  uuid: string
  groups: string[]
  status: Status
  rating?: number
  startTime?: number
}

export interface Workout {
  date: string
  status: Status
  created_on?: number
  created_by?: string
  updated_on?: number
  startTime?: number
  endTime?: number
  exercises: Exercise[]
  user_id: string
  uuid: string
  comment?: string
  rating?: number
  deleted?: boolean
}

export interface DataExercise {
  id: string
  title: string
  link?: string
  description?: string
  comments?: string
  weightStep?: number
  initialData?: SetData
}

export interface DataExerciseGroup {
  id: string
  group_name_id: string
  weightStep?: number
  initialData?: SetData
  exercises: DataExercise[]
  order?: number
}

export interface ExerciseData {
  groups: DataExerciseGroup[]
}

export interface WeightHistoryEntry {
  date: string
  value: number
}

export interface UserData {
  name: string
  picture: string
  email: string
  age?: number
  height?: number
  settings: {
    timer: number
    locale: string
    workouts: {
      delay: number
    }
    exercises: {
      autoStart: boolean
    }
    openai_secret?: string
  }
  uuid: string
  weightHistory?: WeightHistoryEntry[]
  userExercises?: {
    groups: DataExerciseGroup[]
  }
  created_on?: number
  created_by?: string
  updated_on?: number
  isNew?: boolean
  draft?: boolean
  role?: string
  roles?: string[]
  trainerId?: string
  clients?: string[]
  workouts?: {
    last: string
  }
  statsData?: StatsData
  workoutInProgress?: string
}

export enum Status {
  'new' = 0,
  'inProgress' = 1,
  'completed' = 2,
  'cancelled' = 3,
}

export interface Dictionary {
  [key: string]: string
}

export interface DictionaryData {
  [key: string]: {
    [key: string]: string
  }
}

export interface EditTranslationData {
  dictionary: string
  key: string
  values: Dictionary
}
