import firebase from './firebase'
import { DataExercise, DataExerciseGroup, ExerciseData } from '../types'

const exercisesAPI = () => {
  const db = firebase.firestore()
  const exercisesCollection = db.collection('exercises')

  const getExercises = async () => {
    console.log('API: getExercises')

    try {
      const querySnapshot = await exercisesCollection
        .orderBy('order', 'asc')
        .get()

      let result: ExerciseData = { groups: [] }

      querySnapshot.forEach((doc) => {
        if (doc?.data()) {
          if (doc.data().id) {
            result.groups.push(doc.data() as DataExerciseGroup)
          }
        }
      })

      return result
    } catch (err) {
      throw err
    }
  }

  const updateExercise = async (groupName: string, exercise: DataExercise) => {
    console.log('API: updateExercise')

    try {
      const exercisesRef = exercisesCollection.doc(groupName)
      const document = await exercisesRef.get()

      if (document.exists) {
        const data = document.data()

        if (data) {
          if (!data?.exercises) {
            data.exercises = []
          }

          const exIdx = data.exercises.findIndex(
            (ex: DataExercise) => ex.id === exercise.id
          )

          if (exIdx >= 0) {
            data.exercises[exIdx] = exercise
            await exercisesRef.update(data)
          } else {
            data.exercises.push(exercise)
            await exercisesRef.set(data)
          }

          console.log(
            `Exercise ${exercise.id} is ${exIdx >= 0 ? 'updated' : 'created'}`
          )

          return exercise.id
        }
      } else {
        console.log(`Exercise group ${groupName} is not found`)
      }
    } catch (err) {
      throw err
    }
  }

  const deleteExercise = async (groupName: string, exerciseId: string) => {
    console.log('API: deleteExercise')

    try {
      const exercisesRef = exercisesCollection.doc(groupName)
      const document = await exercisesRef.get()
      if (document.exists) {
        const data = document.data()

        if (data?.exercises) {
          data.exercises.splice(
            data.exercises.findIndex(
              (el: DataExercise) => el.id === exerciseId
            ),
            1
          )

          return await exercisesRef.set(data)
        }
      }
    } catch (err) {
      throw err
    }
  }

  // updateExercise('other', { 'new-key': { de: '111', en: '' } })
  // deleteExercise('other', 'new-key')

  return {
    getExercises,
    updateExercise,
    deleteExercise,
  }
}

export default exercisesAPI
