import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

import '../../styles/module.scss'
import { DataContext, UserContext, WorkoutsContext } from '../../store'
import ExerciseList from '../../components/Workouts/Exercises/ExerciseList'
import { Status } from '../../types'
import ExportAction from '../../components/ExportAction'
import useTranslations from '../../hooks/useTranslations'
import routes from '../../helpers/routes'
import WorkoutHeader from '../../components/WorkoutHeader'
import usePathToWorkout from '../../hooks/usePathToWorkout'
import CancelIcon from '@mui/icons-material/Cancel'
import WarningDialog from '../../components/Dialogs/WarningDialog'
import PageWrapper from '../../containers/PageWrapper'

const WorkoutView: React.FC = () => {
  const { isClient } = useContext(UserContext)
  const { isAppReady } = useContext(DataContext)
  const { setIsViewMode, updateWorkoutProgress, currentWorkout } =
    useContext(WorkoutsContext)
  const [isCancelWarning, setIsCancelWarning] = useState<boolean>(false)

  const navigate = useNavigate()
  const { workoutId } = useParams()
  const { state, pathname } = useLocation()
  const { t } = useTranslations()
  usePathToWorkout()

  const isArchive = useMemo(() => pathname.includes('/archive/'), [pathname])

  const handleWorkoutCancel = () => {
    updateWorkoutProgress && updateWorkoutProgress(Status.new)
    setIsCancelWarning(false)
  }

  useEffect(() => {
    if (
      (currentWorkout &&
        currentWorkout?.uuid === workoutId &&
        currentWorkout.status === Status.inProgress) ||
      (currentWorkout && state?.autoStart)
    ) {
      if (currentWorkout && state?.autoStart) {
        updateWorkoutProgress && updateWorkoutProgress(Status.inProgress)
        setIsViewMode && setIsViewMode(false)
      }

      if (isClient) {
        navigate(routes.activeWorkout, { replace: true })
      }
    }
  }, [currentWorkout, state])

  if (!isAppReady || !currentWorkout || workoutId !== currentWorkout.uuid) {
    return null
  }

  return (
    <PageWrapper className="page workouts-page">
      <WorkoutHeader />

      {currentWorkout.comment && (
        <Box sx={{ mt: 2, fontSize: '90%' }}>
          {t('app-workout-comment')}: <i>"{currentWorkout.comment}"</i>
        </Box>
      )}

      {currentWorkout.status === Status.new &&
        isClient && ( //&& !isArchive
          <div className="workouts-page-buttons">
            <Button
              sx={{ alignSelf: 'center' }}
              variant="contained"
              color="warning"
              fullWidth={true}
              startIcon={<PlayCircleOutlineIcon />}
              onClick={() => {
                updateWorkoutProgress &&
                  updateWorkoutProgress(Status.inProgress)
                setIsViewMode && setIsViewMode(false)
                navigate(routes.activeWorkout)
              }}
            >
              {t('app-button-start-workout')}
            </Button>
          </div>
        )}
      <ExerciseList />
      {currentWorkout.status === Status.completed && (
        <div className="workout-bottom-buttons">
          {!isArchive && (
            <Button
              onClick={() => {
                setIsCancelWarning(true)
              }}
              startIcon={<CancelIcon />}
              variant="outlined"
              color="error"
              size="small"
            >
              {t('app-button-reset-workout')}
            </Button>
          )}

          {isCancelWarning && (
            <WarningDialog
              handleClose={() => {
                setIsCancelWarning(false)
              }}
              handleConfirm={handleWorkoutCancel}
              title={t('app-reset-workout-dialog-title')}
              note={t('app-reset-workout-dialog-text')}
              isCancel={true}
            />
          )}

          <ExportAction />
        </div>
      )}
    </PageWrapper>
  )
}

export default WorkoutView
