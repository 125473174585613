import React, { useContext, useState } from 'react'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight'
import AddWeightDialog from '../Dialogs/AddWeightDialog'
import { UserContext } from '../../store'
import { UserData, WeightHistoryEntry } from '../../types'
import useTranslations from '../../hooks/useTranslations'

const AddWeight: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { updateUser, user } = useContext(UserContext)
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslations()

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const handleConfirm = (data: WeightHistoryEntry) => {
    if (user && updateUser) {
      const newUserData: UserData = {
        ...user,
        weightHistory: [...(user.weightHistory || []), data],
      }

      updateUser(newUserData)
    }
    onClose()
  }

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true)
        }}
      >
        <ListItemIcon>
          <MonitorWeightIcon />
        </ListItemIcon>
        <ListItemText>{t('add-weight')}</ListItemText>
      </MenuItem>

      {open && (
        <AddWeightDialog
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  )
}

export default AddWeight
