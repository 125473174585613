import * as React from 'react'
import { Link } from 'react-router-dom'
import { Box, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import usePathToSetTitle from '../../hooks/usePathToSetTitle'
import AppMenu from '../AppMenu'

const Breadcrumbs: React.FC = () => {
  const { path } = usePathToSetTitle()

  return (
    <Box className="flex-row">
      <AppMenu />

      <MuiBreadcrumbs sx={{ ml: 2 }} aria-label="breadcrumb">
        {path?.map((el, idx) => {
          if (idx === 0) {
            return null
          }

          if (el.link) {
            return (
              <Link
                key={idx}
                style={{ display: 'flex', alignItems: 'center' }}
                to={el.link}
              >
                {el.title}
              </Link>
            )
          }

          return (
            <Typography
              key={idx}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {el.title}
            </Typography>
          )
        })}
      </MuiBreadcrumbs>
    </Box>
  )
}

export default Breadcrumbs
