import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import React, { useEffect } from 'react'

import PageWrapper from '../../containers/PageWrapper'
import firebase from '../../api/firebase'
import { Typography } from '@mui/material'

const Login: React.FC = () => {
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth())

    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
    })
  }, [])
  return (
    <PageWrapper className="page login-page">
      <Typography sx={{ textAlign: 'center' }}>Login</Typography>
      <div id="firebaseui-auth-container"></div>
    </PageWrapper>
  )
}

export default Login
