import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../store'
import { useLocation } from 'react-router-dom'
import useTranslations from './useTranslations'
import routes from '../helpers/routes'

const usePathToSetTitle = () => {
  const { clients } = useContext(UserContext)
  const { pathname } = useLocation()
  const { t, dictionaryGeneral } = useTranslations()

  const [path, setPath] = useState<{ title: string; link: string }[]>()

  const pathData = pathname.split('/')
  pathData.shift()

  const clientId =
    pathData[0] === 'clients' && pathData.length > 2 ? pathData[1] : ''

  useEffect(() => {
    if (Object.keys(dictionaryGeneral).length > 0) {
      const path: { title: string; link: string }[] = pathname
        .split('/')
        .reduce((res, el, idx, list) => {
          if (idx === 0) {
            // @ts-ignore
            res.push({ link: '/', title: '' })
          } else if (idx === 1 && el) {
            if (list.length >= 2) {
              if (el === 'clients' && clientId) {
                // @ts-ignore
                res.push({
                  link: list.length > 2 ? routes.clientView(clientId) : '',
                  title: clients?.find((el) => el.uuid === clientId)?.name,
                })
              } else if (el === 'workout') {
                // @ts-ignore
                res.push({
                  // @ts-ignore
                  link: routes.workouts,
                  title: t('page-title-workouts'),
                })
              } else {
                // @ts-ignore
                res.push({
                  // @ts-ignore
                  link: list.length > 2 ? routes[el] : '',
                  title: t(`page-title-${el}`),
                })
              }
            }
          } else if (idx === 2 && el) {
            if (el === 'archive') {
              // @ts-ignore
              res.push({
                // @ts-ignore
                link: routes.workoutsArchive,
                title: t(`page-title-${el}`),
              })
            }
          } else if (idx === 3 && el) {
            if (clientId) {
              // @ts-ignore
              res.push({
                // @ts-ignore
                link: list.length > 4 ? routes.clientWorkouts(clientId) : '',
                title: t(`page-title-${el}`),
              })
            }
          }

          return res
        }, [])

      if (path) {
        setPath(path)
      }
    }
  }, [pathname, clients, clientId, dictionaryGeneral])

  return {
    path,
  }
}

export default usePathToSetTitle
