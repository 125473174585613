import React, { useContext, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, Card, Divider, Typography } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import Countdown from 'react-countdown'
import cn from 'classnames'
import dayjs from 'dayjs'

import '../../styles/module.scss'
import routes from '../../helpers/routes'
import { NormalizedWorkout, Status, Workout } from '../../types'
import { DataContext, UserContext, WorkoutsContext } from '../../store'
import {
  APP_LOCALE,
  DATE_FORMAT_DATA,
  DATE_FORMAT_WEEKDAYS,
} from '../../helpers/constants'
import CardMenu from '../CardMenu'
import { customIcons } from '../Rating'
import useTranslations from '../../hooks/useTranslations'
import { normalizeWorkouts } from '../../helpers'
import WeekTitle from '../WeekTitle'

interface Props {
  data: Workout[]
  isToday?: boolean
  isArchive?: boolean
  hideCompleted?: boolean
}

const WorkoutList: React.FC<Props> = ({
  data,
  isToday,
  isArchive = false,
  hideCompleted = false,
}) => {
  const { locale, activeClient } = useContext(UserContext)
  const { exercises } = useContext(DataContext)
  const { currentWorkout } = useContext(WorkoutsContext)
  const { t } = useTranslations()
  const navigate = useNavigate()

  const normalizedData = useMemo(
    () => exercises && normalizeWorkouts(data, exercises, t),
    [data, exercises]
  )

  if (!normalizedData) {
    return null
  }

  const groupWorkoutsByWeeks = () => {
    const groupedWorkouts: {
      [date: string]: NormalizedWorkout[]
    } = {}

    normalizedData.forEach((workout) => {
      const weekStartDate = dayjs(workout.date || workout.createdOn)
        .locale(APP_LOCALE)
        .startOf('week')
        .format(DATE_FORMAT_DATA)

      if (!groupedWorkouts[weekStartDate]) {
        groupedWorkouts[weekStartDate] = []
      }

      groupedWorkouts[weekStartDate].push(workout)
    })

    return groupedWorkouts
  }

  const groupedWorkouts = groupWorkoutsByWeeks()

  return (
    <>
      {Object.entries(groupedWorkouts).map(([weekStartDate, workouts], idx) => {
        return (
          <div key={weekStartDate}>
            {isArchive && (
              <Typography
                variant="subtitle2"
                sx={{ mt: idx > 0 ? 3 : 0, color: 'lightgray' }}
              >
                <WeekTitle date={dayjs(weekStartDate).locale(APP_LOCALE)} />
              </Typography>
            )}

            {
              // @ts-ignore
              workouts.map((item: NormalizedWorkout, idx: number) => {
                return (
                  <Card
                    key={item.uuid}
                    className={cn('workouts-list-item', {
                      'workouts-list-item-today':
                        dayjs(item.date).isSame(dayjs(), 'd') &&
                        item.status !== Status.completed,
                      // 'workouts-list-item-hidden':
                      //   hideCompleted && item.status === Status.completed,
                      'workouts-list-item-active':
                        activeClient && item.status === Status.inProgress,
                      'workouts-list-item-active-client':
                        !activeClient && item.status === Status.inProgress,
                    })}
                  >
                    <Link
                      className="workouts-list-item-left"
                      to={
                        isArchive
                          ? routes.archiveView(item.uuid)
                          : activeClient
                          ? routes.clientWorkoutView(activeClient, item.uuid)
                          : routes.workoutView(item.uuid)
                      }
                    >
                      <div className="workouts-list-item-title">
                        {item.status === Status.completed && (
                          <EmojiEventsIcon
                            sx={{ height: 16, width: 16 }}
                            color="warning"
                          />
                        )}
                        {!isArchive && idx + 1 + '. '}

                        {item.groups.length < 4
                          ? item.groups.join('/')
                          : 'Fullbody'}

                        <Box sx={{ ml: '6px' }} className="flex-row">
                          {item.rating && (
                            <Box
                              className="workouts-list-item-rating"
                              title={customIcons[item.rating].label}
                            >
                              {customIcons[item.rating].icon}
                            </Box>
                          )}
                        </Box>
                      </div>{' '}
                      <Box className="flex-row">
                        {item.date && (
                          <div className="workouts-list-item-date">
                            {item.date
                              ? dayjs(item.date).isSame(dayjs(), 'd')
                                ? t('app-workouts-today')
                                : dayjs(item.date)
                                    .locale(locale)
                                    .format(DATE_FORMAT_WEEKDAYS)
                                    .toUpperCase()
                              : '_._._'}
                          </div>
                        )}

                        {activeClient && item.status === Status.inProgress && (
                          <Box sx={{ ml: 'auto', fontWeight: 500 }}>
                            <Countdown
                              date={item.startTime}
                              overtime={true}
                              renderer={({ formatted }) => {
                                return (
                                  <span>
                                    {formatted.hours}:{formatted.minutes}:
                                    {formatted.seconds}
                                  </span>
                                )
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Link>
                    {isToday &&
                      item.status === Status.new &&
                      (!currentWorkout ||
                        currentWorkout?.status === Status.new) && (
                        <Button
                          className="workouts-list-item-button-start"
                          sx={{ alignSelf: 'center' }}
                          variant="contained"
                          size="small"
                          color="warning"
                          startIcon={<PlayCircleOutlineIcon />}
                          onClick={async (e) => {
                            navigate(
                              activeClient
                                ? routes.clientWorkoutView(
                                    activeClient,
                                    item.uuid
                                  )
                                : routes.workoutView(item.uuid)
                            )
                          }}
                        >
                          {t('app-button-start-short')}
                        </Button>
                      )}

                    {!isArchive && (
                      <CardMenu
                        uuid={item.uuid}
                        isArchive={isArchive}
                        isCompleted={item.status === Status.completed}
                      />
                    )}
                  </Card>
                )
              })
            }
          </div>
        )
      })}
    </>
  )
}

export default WorkoutList
