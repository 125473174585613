import firebase from './firebase'
import { ExerciseData, UserData, Workout } from '../types'
import { workoutsAPI } from './index'
import { getUserStats } from '../helpers/stats'

const userAPI = () => {
  const db = firebase.firestore()
  const usersCollection = db.collection('users')

  const createUser = async (data: { [key: string]: any }) => {
    console.log('API: createUser')

    try {
      const querySnapshot = await usersCollection
        .where('email', '==', data.email)
        .get()

      if (querySnapshot.size > 0) {
        console.error(`User with email ${data.email} already exists`)
      } else {
        const docRef = await usersCollection.add({
          ...data,
          created_on: Date.now(),
        })
        await docRef.update({ uuid: docRef.id })

        console.log(`User with id ${docRef.id} has been created`)

        return docRef.id
      }
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const updateUser = async (uuid: string, data: UserData) => {
    console.log('API: updateUser')

    try {
      const userRef = usersCollection.doc(uuid)

      await userRef.update({ ...data, updated_on: Date.now() })

      console.log(`User ${userRef.id} updated`)

      return userRef.id
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const updateUserStats = async (
    userData: UserData,
    data: {
      exercises: ExerciseData
      workouts?: Workout[]
      workoutsArchive?: Workout[]
    }
  ) => {
    const userId = userData.uuid
    console.log('API: updateUserStats')

    try {
      const { exercises } = data
      const workouts =
        data.workouts || (await workoutsAPI().getWorkouts(userId))
      const workoutsArchive =
        data.workoutsArchive || (await workoutsAPI().getWorkoutArchive(userId))

      const statsData = getUserStats({ exercises, workouts, workoutsArchive })

      await updateUser(userId, { ...userData, statsData })
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const getUser = async (email: string): Promise<UserData | undefined> => {
    try {
      console.log('API: getUser')
      const querySnapshot = await usersCollection
        .where('email', '==', email)
        .get()

      let result

      querySnapshot.forEach((doc) => {
        if (doc?.data()) {
          result = { uuid: doc.id, ...doc.data() }
        }
      })

      return result
    } catch (err) {
      throw err
    }
  }

  const getClients = async (
    trainerId: string
  ): Promise<UserData[] | undefined> => {
    console.log('API: getClients')

    try {
      const querySnapshot = await usersCollection
        .where('trainerId', '==', trainerId)
        .get()

      let result: UserData[] = []

      querySnapshot.forEach((doc) => {
        if (doc?.data()) {
          result.push(doc.data() as UserData)
        }
      })

      return result
    } catch (err) {
      throw err
    }
  }

  return {
    createUser,
    updateUser,
    updateUserStats,
    getUser,
    getClients,
  }
}

export default userAPI
